import {Color} from '../../../constants/styles';

export interface HeaderTheme {
  backgroundColor: Color;
  textColor: {
    default: Color;
    active: Color;
  };
}

export const HeaderTheme: HeaderTheme = {
  backgroundColor: Color.transparent,
  textColor: {
    default: Color.white,
    active: Color.chateauGreen,
  },
};
