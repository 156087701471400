import React, { useCallback, useState, useEffect } from "react";
import styled, { useTheme, StyledProps } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import logoPng from "../../../assets/images/logo_header.png";
import { Color, DEFAULT_HEADER_HEIGHT } from "../../../constants/styles";
import { Text } from "../../atoms/Text/Text";
import { Link, useNavigate } from "react-router-dom";
import { AccountLogoLink } from "../../atoms/AccountLogoLink/AccountLogoLink";
import { DropdownButton } from "../../molecules/DropdownButton/DropdownButton";
import { Button } from "../../atoms/Button/Button";
import { devicesMax } from "../../../constants/device";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { userSelectors } from "../../../store/modules/account/selectors";
import { ROUTES } from "../../../routes";
import BurgerIcon from "../../atoms/BurgerIcon/BurgerIcon";
import { WALLET_BASE_URL, CAPITAL_BASE_URL } from "../../../constants/api";
import { userActions } from "../../../store/modules/account/actions";
import { isSucceeded, isLoading } from "../../../utils/request-statuses";
import { usePrevious } from "../../../hooks/usePrevious";
import { clearStorage } from "../../../utils/local-storage";
import { LangSwitcher } from "../../molecules/LangSwitcher/LangSwitcher";

export interface HeaderProps { }

interface NavContainerMobileProps {
  showMenu: boolean;
}

const Container = styled.div`
  height: ${DEFAULT_HEADER_HEIGHT}px;
  max-width: 1232px;
  margin: 0 auto;
  display: flex;
  padding: 0px 100px;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: ${({ theme }: StyledProps<unknown>): Color =>
    theme.Header.backgroundColor};
  z-index: 1;
  @media ${devicesMax.laptopXL} {
    padding: 0px 30px;
  }
  @media ${devicesMax.tablet} {
    flex-wrap: wrap;
    height: fit-content;
    min-height: ${DEFAULT_HEADER_HEIGHT}px;
    padding: 0px;
    flex-direction: column;
    background-color: ${Color.codGrayOpacity97};
  }
`;

const NavContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 0px;
  margin-right: 15px;
  font-family: Lato, Nunito Sans, sans-serif;
  @media ${devicesMax.laptop} {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 10px;
    margin: 0px;
  }
  @media ${devicesMax.tablet} {
    display: none;
  }
`;

const NavItemWrapper = styled.div`
  display: flex;
  margin-left: 39px;
  @media ${devicesMax.tablet} {
    margin-left: 0px;
  }
`;

const NavContainerMobile = styled.div<NavContainerMobileProps>`
  display: none;
  @media ${devicesMax.tablet} {
    display: flex;
    flex-direction: row;
    transition: all 0.3s ease;
    gap: 26px;
    padding: 37px;
    padding-top: 38px;
    align-items: left;
    text-transform: uppercase;
    transform: translateY(-340px);
    background-color: ${({ theme }: StyledProps<unknown>): Color =>
    theme.Header.backgroundColor};
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 340px;
    top: ${({
      theme,
      showMenu,
    }: StyledProps<NavContainerMobileProps>): string =>
    showMenu ? "420px" : "0px"};
    z-index: 0;
    opacity: ${({
      theme,
      showMenu,
    }: StyledProps<NavContainerMobileProps>): string =>
    showMenu ? "1" : "0.9"};
    background-color: ${Color.codGrayOpacity97};
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 100px;
`;

const NavItem = styled(Link)`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  font-family: Lato, Nunito Sans, sans-serif;
  color: white;
  margin-right: 1px;
  text-decoration: none;
  white-space: nowrap;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 0.07em;
    background-color: white;
    transition: opacity 300ms, transform 250ms;
    opacity: 1;
    transform: scale(0);
    transform-origin: center;
  }

  &:hover {
    &::after {
      opacity: 1;
      transform: translate3d(0, 0.2em, 0);
      transform: scale(1);
    }
  }

  @media ${devicesMax.laptopXL} {
    font-size: 16px;
    line-height: 18px;
  }
  @media ${devicesMax.laptopL} {
    font-size: 14px;
    line-height: 18px;
    flex: 1;
  }
  @media ${devicesMax.tablet} {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
    font-weight: 600;
    filter: drop-shadow(2px 4px 2px rgb(0 0 0 / 0.4));
  }
`;

const NavItemMobileSmall = styled.a`
  color: ${({ theme }: StyledProps<unknown>): Color =>
    theme.Header.textColor.default};
  text-decoration: none;
  white-space: nowrap;
  position: relative;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 8px;
  font-weight: 600;
  filter: drop-shadow(2px 4px 2px rgb(0 0 0 / 0.4));
`;

const NavDivider = styled.span`
  margin: 0px 28px;
  width: 2px;
  background-color: ${({ theme }: StyledProps<unknown>): Color =>
    theme.Header.textColor.default};
  @media ${devicesMax.laptopL} {
    margin: 0px 20px;
  }
  @media ${devicesMax.laptop} {
    margin: 0 5px;
  }
  @media ${devicesMax.tablet} {
    display: none;
  }
`;

const Logo = styled.img`
  height: 46px;
  cursor: pointer;
  margin-left: -2px;
  margin-bottom: -2px;
  @media ${devicesMax.laptopL} {
    height: 35px;
  }

  @media (max-width: 1128px) {
    height: 30px;
  }
`;

const HeaderActionsContainer = styled.div`
  display: flex;
  flex: 5;
  align-items: center;
  margin-left: 15px;
  justify-content: flex-end;
  z-index: 10;
  background-color: ${({ theme }: StyledProps<unknown>): Color =>
    theme.Header.backgroundColor};
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: -23px;
    right: -11px;
    width: 70%;
    height: 0.01em;
    background-color: white;
    opacity: 0.2;
  }
  @media ${devicesMax.laptop} {
    &::after {
      content: "";
      position: absolute;
      bottom: -25px;
      right: 0;
      width: 100%;
      height: 0.01em;
      background-color: white;
      opacity: 0.2;
    }
  }
  @media ${devicesMax.tablet} {
    display: none;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0px;
    flex-direction: column;
    padding: 5px;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 10px;
  @media ${devicesMax.laptop} {
    margin-left: 10px;
  }
  @media ${devicesMax.tablet} {
    display: none;
  }
`;

const ActionsContainerMobile = styled.div`
  display: none;
  @media ${devicesMax.tablet} {
    display: flex;
    margin-top: 10px;
  }
`;

const TermsNavContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

const StyledAccountLogoLink = styled(AccountLogoLink)`
  margin: 0px 24px 0px;
  @media ${devicesMax.tablet} {
    margin: 0px 0px 10px;
    background-color: ${Color.transparent};
    border-color: ${Color.white};
  }
`;

const EmptySpace = styled.span`
  background-color: ${({ theme }: StyledProps<unknown>): Color =>
    theme.Header.backgroundColor};
  z-index: 10;
  @media ${devicesMax.tablet} {
    display: flex;
    flex-direction: row;
    align-self: stretch;
    padding: 30px 20px 15px;
    justify-content: space-between;
    align-items: center;
    background-color: ${Color.codGrayOpacity97};
  }
`;

const SignInButton = styled(Button)`
  margin-right: 30px;
  height: 36px;
  @media ${devicesMax.laptopL} {
    height: 32px;
    fontSize: 16px;
    padding: 0 14px;
  }
`;

const SignInLink = styled(Text)`
  align-self: left;
  text-transform: uppercase;
  margin-bottom: 4px;
  font-size: 12px;
  margin-bottom: 10px;
`;

const SignOutLink = styled(SignInLink)`
  align-self: center;
  text-transform: uppercase;
`;

const Mobile = styled.div`
  display: none;
  @media ${devicesMax.tablet} {
    display: flex;
  }
`;

const MobileHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const headerNavList = [
  // {
  //   label: "CryptoHarvester",
  //   href: "/",
  // },
  {
    label: "Projects",
    href: "wallet/projects",
  },
  {
    label: "Capital",
    href: "capital",
  },
  {
    label: "Wallet",
    href: "wallet",
  },
];

const headerMobileNavList = [
  {
    label: "Main",
    href: "/",
    isAuthorized: false,
  },
  {
    label: "Projects",
    href: "wallet/projects",
    isAuthorized: true,
  },
  {
    label: "Capital",
    href: "capital",
    isAuthorized: true,
  },
  {
    label: "Wallet",
    href: "wallet",
    isAuthorized: true,
  },
  {
    label: "Support",
    href: "support",
    isAuthorized: false,
  },
  {
    label: "Settings",
    href: "wallet/settings",
    isAuthorized: true,
  },
];

const termsList = [
  {
    label: "Privacy Policy",
    href: `${WALLET_BASE_URL}?path_name=privacy&appLanguage=${localStorage.getItem(
      "AppLanguage"
    )}&access_token=${localStorage.getItem(
      "access_token"
    )}&refresh_token=${localStorage.getItem("refresh_token")}`,
  },
  {
    label: "Terms of Use",
    href: `${WALLET_BASE_URL}?path_name=terms&appLanguage=${localStorage.getItem(
      "AppLanguage"
    )}&access_token=${localStorage.getItem(
      "access_token"
    )}&refresh_token=${localStorage.getItem("refresh_token")}`,
  },
];

export const Header: React.FC<HeaderProps> = ({ }: HeaderProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  // const [pathName, setPathName] = useState("/");
  const isAuthorized = useSelector(userSelectors.isAuthorized);
  const user = useSelector(userSelectors.user);
  const checkAuthRedirectRequestStatus = useSelector(
    userSelectors.checkAuthRedirectRequestStatus
  );
  const prevCheckAuthRedirectRequestStatus = usePrevious(
    checkAuthRedirectRequestStatus
  );

  const navigate = useNavigate();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const { t } = useTranslation();

  const handleSignInClick = useCallback(() => {
    navigate(ROUTES.SIGN_IN);
  }, [navigate]);

  const handleSignUpClick = useCallback(() => {
    navigate(ROUTES.SIGN_UP);
  }, [navigate]);

  const handleLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const handleBurgerClick = useCallback((isOpened) => {
    setShowMobileMenu(isOpened);
  }, []);

  const handleMenuClick = useCallback(
    (pathName = "/") => {
      console.log(pathName);
      const pathname = pathName?.split("/") || "";
      if (!pathname.includes("wallet") && !pathname?.includes("capital")) {
        navigate(pathName);
        return;
      }
      localStorage.setItem("path_name", pathName);
      const accessToken = localStorage.getItem("access_token");
      const refreshToken = localStorage.getItem("refresh_token");
      if (isAuthorized && accessToken && refreshToken) {
        dispatch(userActions.checkIfAuthRedirectAvailable.started());
        // window.open(`${WALLET_BASE_URL}?access_token=${accessToken}&refresh_token=${refreshToken}`, "_self");
      }
    },
    [dispatch, isAuthorized, navigate]
  );

  const handleLogOut = useCallback(() => {
    navigate("/");
    dispatch(userActions.logout.started({}));
  }, [dispatch, navigate]);

  useEffect(() => {
    if (
      isLoading(prevCheckAuthRedirectRequestStatus) &&
      isSucceeded(checkAuthRedirectRequestStatus)
    ) {
      const accessToken = localStorage.getItem("access_token");
      const refreshToken = localStorage.getItem("refresh_token");
      const pathName = localStorage.getItem("path_name")?.split("/") || "";
      const appLanguage = localStorage.getItem("AppLanguage");
      console.log(pathName);
      // if (!pathName.includes("wallet")) return;

      window.open(
        `${pathName?.includes("wallet") || pathName?.includes("projects")
          ? WALLET_BASE_URL
          : pathName?.includes("capital")
            ? CAPITAL_BASE_URL
            : "/"
        }?access_token=${accessToken}&refresh_token=${refreshToken}&path_name=${pathName[1] ? pathName[1] : "/"
        }& appLanguage=${appLanguage} `, '_self')
      clearStorage();
      dispatch(userActions.resetAuthorized());
      console.log('redirect to external app ????');

      // window.open(
      //   `${ CAPITAL_BASE_URL }?access_token = ${ accessToken }& refresh_token=${ refreshToken }& path_name=${ pathName }& appLanguage=${ appLanguage } `,
      //   "_self"
      // );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAuthRedirectRequestStatus, prevCheckAuthRedirectRequestStatus]);

  return (
    <Container>
      <EmptySpace>
        {/* <EmptyElement /> */}
        <Logo src={logoPng} alt="Cryptoneed" onClick={handleLogoClick} />
        <Mobile>
          <MobileHeaderWrapper>
            <BurgerIcon onChange={handleBurgerClick} />
          </MobileHeaderWrapper>
        </Mobile>
      </EmptySpace>
      <HeaderActionsContainer>
        {isAuthorized && (
          <NavContainer>
            {headerNavList.map((nav, index) => (
              <NavItemWrapper
                key={index}
                onClick={() => handleMenuClick(nav.href)}
              >
                <NavItem key={nav.href + index} to={"/"}>
                  <Trans>{nav.label}</Trans>
                </NavItem>
              </NavItemWrapper>
            ))}
          </NavContainer>
        )}

        <ActionsContainer>
          {/* {isAuthorized ? (
            <SignInButton
              borderColor={Color.white}
              labelColor={Color.white}
              label={t("Wallet")}
              onPress={() => handleMenuClick("wallet")}
              isLoading={isLoading(checkAuthRedirectRequestStatus)}
              isRounded
            />
          ) : null} */}
          {isAuthorized ? (
            <StyledAccountLogoLink
              label={user?.login}
              handleClick={(event: { value: string }) =>
                handleMenuClick(event.value)
              }
              handleLogOutClick={handleLogOut}
            />
          ) : (
            <SignInButton
              labelTypography="400-18-24"
              labelColor={Color.dustyGray}
              backgroundColor={Color.sharkBlack}
              onPress={handleSignInClick}
              label={t("Log In")}
            />
          )}
          <DropdownButton />
        </ActionsContainer>
      </HeaderActionsContainer>
      <NavContainerMobile showMenu={showMobileMenu}>
        <Column>
          {headerMobileNavList.map((nav, index) => {
            if (!isAuthorized && nav.isAuthorized) {
              return null;
            }
            return (
              <NavItemWrapper
                key={index}
                onClick={() => handleMenuClick(nav.href)}
              >
                <NavItem key={nav.href + index} to={"/"}>
                  <Trans>{nav.label}</Trans>
                </NavItem>
                {index !== headerMobileNavList.length - 1 && (
                  <NavDivider key={nav.href + "divider"} />
                )}
              </NavItemWrapper>
            );
          })}
          <TermsNavContainer>
            {termsList.map((nav, index) => (
              <NavItemMobileSmall key={nav.href + index} href={nav.href}>
                <Trans>{nav.label}</Trans>
              </NavItemMobileSmall>
            ))}
          </TermsNavContainer>
          <ActionsContainerMobile>
            {/* {isAuthorized ? (
            <SignInButtonMobile
              borderColor={Color.white}
              labelColor={Color.white}
              label={t("Wallet")}
              onPress={() => handleMenuClick("/")}
              isLoading={isLoading(checkAuthRedirectRequestStatus)}
              isRounded
            />
          ) : null} */}
            <Row>
              <LangSwitcher />
            </Row>
          </ActionsContainerMobile>
        </Column>
        <Column style={{ alignItems: "flex-end" }}>
          <Column style={{ textAlign: "left" }}>
            {!isAuthorized ? (
              <SignInLink typography="600-10-12" onClick={handleSignInClick}>
                {t("Sign In")}
              </SignInLink>
            ) : null}
            {!isAuthorized ? (
              <SignInLink typography="600-10-12" onClick={handleSignUpClick}>
                {t("Registration")}
              </SignInLink>
            ) : null}
            {isAuthorized ? (
              <StyledAccountLogoLink label={user?.login} />
            ) : null}
            {isAuthorized ? (
              <SignOutLink typography="600-10-12" onClick={handleLogOut}>
                {t("Log out")}
              </SignOutLink>
            ) : null}
          </Column>
        </Column>
      </NavContainerMobile>
    </Container>
  );
};
