import React, { useState, useCallback, useEffect, useRef } from "react";
import styled, { StyledProps } from "styled-components";
import { useTranslation } from "react-i18next";
import { useQuery } from "../../../hooks/useQuery";
import { Color } from "../../../constants/styles";
import { Text } from "../../atoms/Text/Text";
import { devicesMax } from "../../../constants/device";
import { LanguageTicker } from "../../../constants/languages";
import { setStorageItem, StorageItem } from "../../../utils/local-storage";

export interface LangSwitcherProps {
  onPress?: () => void;
}

interface SelectedProps {
  isSelected?: boolean;
}

const Container = styled.span`
  display: flex;
  flex-direction: row;
  height: 38px;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  @media ${devicesMax.laptopL} {
    height: 30px;
  }
`;

const LangItem = styled.div<SelectedProps>`
  display: flex;
  width: 31px;
  height: 19px;
  border: 1px solid ${Color.white};
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border-color: ${({ isSelected }: StyledProps<SelectedProps>): Color =>
    isSelected ? Color.chateauGreen : Color.white};
  margin-right: 12px;
`;

const StyledText = styled(Text)<SelectedProps>`
  text-transform: uppercase;
  color: ${({ isSelected }: StyledProps<SelectedProps>): Color =>
    isSelected ? Color.chateauGreen : Color.white};
`;

export const LangSwitcher: React.FC<LangSwitcherProps> = ({
  onPress,
}: LangSwitcherProps) => {
  const ref = useRef(null);
  let query = useQuery();
  const [lang, setLang] = useState<LanguageTicker>(LanguageTicker.en);
  const appLanguage = query.get("appLanguage");
  const { t, i18n } = useTranslation();

  const handleLangChange = useCallback(
    (value) => () => {
      setLang(value);
      setStorageItem(StorageItem.AppLanguage, value);
      i18n.changeLanguage(value);
    },
    [i18n]
  );

  useEffect(() => {
    if (appLanguage && appLanguage === "null") {
      setStorageItem(StorageItem.AppLanguage, LanguageTicker.en);
      setLang(LanguageTicker.en);
      i18n.changeLanguage(LanguageTicker.en);
      return;
    }
    if (!!appLanguage) {
      setStorageItem(StorageItem.AppLanguage, appLanguage);
      i18n.changeLanguage(appLanguage);
    }
  }, [appLanguage]);

  useEffect(() => {
    if (i18n.language) {
      setLang(i18n.language as LanguageTicker);
    }
  }, []);

  return (
    <>
      <Container ref={ref}>
        <LangItem
          isSelected={lang === LanguageTicker.en}
          onClick={handleLangChange(LanguageTicker.en)}
        >
          <StyledText
            isSelected={lang === LanguageTicker.en}
            typography="400-10-12"
          >
            {LanguageTicker.en}
          </StyledText>
        </LangItem>
        <LangItem
          isSelected={lang === LanguageTicker.ua}
          onClick={handleLangChange(LanguageTicker.ua)}
        >
          <StyledText
            isSelected={lang === LanguageTicker.ua}
            typography="400-10-12"
          >
            {LanguageTicker.ua}
          </StyledText>
        </LangItem>
        <LangItem
          isSelected={lang === LanguageTicker.ru}
          onClick={handleLangChange(LanguageTicker.ru)}
        >
          <StyledText
            isSelected={lang === LanguageTicker.ru}
            typography="400-10-12"
          >
            {LanguageTicker.ru}
          </StyledText>
        </LangItem>
      </Container>
    </>
  );
};
