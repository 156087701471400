// export const BASE_URL: string = 'http://3.120.255.7/';
// export const BASE_URL: string = 'https://cryptoneed.uniops.pro/';
// PROD
// export const BASE_URL: string = 'https://wallet.cryptoneed.com/';
// DEV
// export const BASE_URL: string = 'https://wallet.cryptoneed.info/';
export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;

export const URL = {
  login: "/api/accounts/oauth/token", // +
  login2FAEnabled: "/api/accounts/oauth/token",
  refreshToken: "/api/accounts/oauth/token",
  register: "/api/accounts/register", // +
  resetPassword: "/api/accounts/account/reset-password/init", // +
  logout: "/api/accounts/current/logout", // +
  account: "/api/accounts/account/account", // +
  activate: "/api/accounts/activate",
  resetPasswordFinish: "/api/accounts/account/reset-password/finish",
  enableTwoFA: "api/accounts/twofa/enable",
  disableTwoFA: "api/accounts/twofa/disable",
  checkIsTwoFA: "api/accounts/twofa/check-is",
  checkCodeTwoFA: "api/accounts/twofa/check-code",
  checkInstallTwoFA: "api/accounts/twofa/confirm",
};
// PROD
// export const WALLET_BASE_URL = 'https://wallet.cryptoneed.com/login'
// DEV
// export const WALLET_BASE_URL = 'https://wallet.cryptoneed.info/login'
// LOCAL
// export const WALLET_BASE_URL = 'http://localhost:3000/login'
// LOCAL
// export const CAPITAL_BASE_URL = 'http://localhost:3002/login'

export const WALLET_BASE_URL = process.env.REACT_APP_WALLET_BASE_URL;

export const CAPITAL_BASE_URL = process.env.REACT_APP_CAPITAL_BASE_URL;
