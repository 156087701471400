import actionCreatorFactory from "typescript-fsa";
import { ModuleNames } from "../../types";
import {
  User,
  UserLoginInput,
  RefreshTokenInput,
  UserLogin,
  UserLogoutInput,
  UserRegisterInput,
  ResetPasswordInput,
  AccountActivateInput,
  ResetPasswordFinishInput,
  EnableTwoFAResponseData,
  CheckInstallTwoFAInput,
} from "../../../models/User";

const actionCreator = actionCreatorFactory(ModuleNames.User);

const getUser = actionCreator.async<void, User | null, unknown>("GET_USER");
const checkIfAuthRedirectAvailable = actionCreator.async<
  void,
  User | null,
  unknown
>("CHECK_IF_AUTH_REDIRECT_AVAILABLE");
const auth = actionCreator.async<unknown, void, unknown>("AUTH");
const logout = actionCreator.async<UserLogoutInput, void, unknown>("LOGOUT");
const login =
  actionCreator.async<UserLoginInput, UserLogin, unknown>("USER_LOGIN");
const refreshToken =
  actionCreator.async<RefreshTokenInput, UserLogin, unknown>(
    "USER_REFRESH_TOKEN"
  );
const register =
  actionCreator.async<UserRegisterInput, void, unknown>("USER_REGISTER");
const resetPassword =
  actionCreator.async<ResetPasswordInput, void, unknown>("RESET_PASSWORD");
const resetPasswordFinish = actionCreator.async<
  ResetPasswordFinishInput,
  void,
  unknown
>("RESET_PASSWORD_FINISH");
const activateAccount =
  actionCreator.async<AccountActivateInput, void, unknown>("ACTIVATE_ACCOUNT");

const enableTwoFA =
  actionCreator.async<void, EnableTwoFAResponseData, unknown>("ENABLE_TWO_FA");
const disableTwoFA =
  actionCreator.async<void, string, unknown>("DISABLE_TWO_FA");
const checkIsTwoFA =
  actionCreator.async<void, void, unknown>("CHECK_IS_TWO_FA");
const checkCodeTwoFA = actionCreator.async<void, void, unknown>(
  "CHECK_IS_CODE_TWO_FA"
);
const checkInstallTwoFA = actionCreator.async<
  CheckInstallTwoFAInput,
  boolean,
  unknown
>("CHECK_INSTALL_TWO_FA");

const initApp = actionCreator.async<void, void, unknown>("INIT_APP");
const resetStatuses = actionCreator("RESET_STATUSES");
const loginTwoFaIsOn = actionCreator("LOGIN_TWO_FA_IS_ON");

const resetAuthorized = actionCreator("RESET_AUTHORIZED");

export const userActions = {
  logout,
  auth,
  getUser,
  login,
  refreshToken,
  initApp,
  register,
  resetPassword,
  activateAccount,
  resetPasswordFinish,
  resetStatuses,
  checkIfAuthRedirectAvailable,
  enableTwoFA,
  disableTwoFA,
  checkIsTwoFA,
  checkCodeTwoFA,
  checkInstallTwoFA,
  loginTwoFaIsOn,
  resetAuthorized,
};
