import { CustomError } from "./errors";
import { handleError } from "./logging";

export enum StorageItem {
  AccessToken = "access_token",
  RefreshToken = "refresh_token",
  AppSettings = "AppSettings",
  AppLanguage = "AppLanguage",
  AppCurrency = "AppCurrency",
  PreviousVersion = "PreviousVersion",
  XSRF = "XSRF",
}

export const whiteList = [StorageItem.PreviousVersion, StorageItem.XSRF];

export function getStorageItem(key: StorageItem): string | null {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    handleError(new CustomError(e, `getStorageItem:${key}`));
    return null;
  }
}

export function setStorageItem(key: StorageItem, value: string) {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    handleError(new CustomError(e, `setStorageItem(${key}:${value})`));
  }
}

export function removeStorageItem(key: StorageItem): void {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    handleError(new CustomError(e, `removeStorageItem:${key}`));
  }
}

export function clearStorage(): void {
  try {
    Object.values(StorageItem)
      .filter((item: StorageItem) => !whiteList.includes(item))
      .forEach((item) => {
        localStorage.removeItem(item);
      });
  } catch (e) {
    handleError(new CustomError(e, "clearStorage"));
  }
}
