import styled, { StyledProps, css } from "styled-components";
import { Color } from "../constants/styles";

export const gradientBorder = css`
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -1px; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: ${({ theme }: StyledProps<unknown>): string =>
      `linear-gradient(to right, ${theme.global.background.green}, ${theme.global.background.blue});`};
  }
`;

export const gradientBorderHorizontal = css`
  &:before {
    content: "";
    position: absolute;
    top: 1px;
    right: 0;
    height: 1px;
    left: 27px;
    z-index: -1;
    margin: -1px; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: ${({ theme }: StyledProps<unknown>): string =>
      `linear-gradient(to right, ${theme.global.background.green}, ${theme.global.background.blue});`};
  };
  &:after {
    content: "";
    position: absolute;
    bottom: 1px;
    right: 0;
    height: 1px;
    left: 27px;
    z-index: -1;
    margin: -1px; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: ${({ theme }: StyledProps<unknown>): string =>
      `linear-gradient(to right, ${theme.global.background.green}, ${theme.global.background.blue});`};
  };
`;

export const gradientBackground = css`
  background: ${({ theme }: StyledProps<unknown>): string =>
    `linear-gradient(to right, ${theme.global.background.green}, ${theme.global.background.blue});`};
`;

export const gradientReversedBackground = css`
  background: ${({ theme }: StyledProps<unknown>): string =>
    `linear-gradient(to right, ${theme.global.background.blue}, ${theme.global.background.green});`};
`;

export const sectionBackground = css`
  background: ${Color.transparent};
`;

export const headerBackground = css`
  background: ${({ theme }: StyledProps<unknown>): string =>
    `linear-gradient(90deg, ${theme.global.background.sectionbgOne} 30%, ${theme.global.background.sectionBgTwo} 50.12%, ${theme.global.background.sectionBgThree} 100%)`};
`;

export const headerMenuBackground = css`
  background: ${({ theme }: StyledProps<unknown>): string =>
    `linear-gradient(240deg, rgba(68, 75, 85, 0.99) 0%, ${theme.global.background.sectionBgTwo} 50.12%, rgba(22, 23, 31, 0.99) 90%)`};
`;
