import React, { useCallback, useState } from "react";
import styled, { useTheme, StyledProps } from "styled-components";
import { useTranslation, Trans } from "react-i18next";
import { Color } from "../../../constants/styles";
import { Text } from "../Text/Text";
import { Icon } from "../Icon/Icon";
import { devicesMax } from "../../../constants/device";
export interface InputProps {
  className?: string;
  type?: string;
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: any) => void;
  onFocus?: (e: React.FocusEvent<any>) => void;
  placeholder?: string;
  value?: string;
  error?: string | boolean;
  name?: string;
  label?: string;
}

interface ContainerProps {
  isError?: boolean;
}

const Content = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  height: 46px;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${({ theme }: StyledProps<unknown>): Color =>
    theme.global.input.background};
  filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 0.2));
  border: 1px solid
    ${({ isError }: StyledProps<ContainerProps>): Color =>
      isError ? Color.red : Color.malibuBlue};
  border-radius: 16px;
  padding-left: 10px;
  box-sizing: border-box;
  @media ${devicesMax.desktopS} {
    height: 46px;
    border-radius: 16px;
  }
  @media ${devicesMax.laptopXL} {
    height: 46px;
    border-radius: 16px;
  }
  @media ${devicesMax.laptopS} {
    height: 44px;
    border-radius: 14px;
  }
  @media ${devicesMax.laptop} {
    height: 44px;
    border-radius: 14px;
  }
`;

const InputStyled = styled.input`
  width: 100%;
  border: none;
  background: transparent;
  outline: none;
  color: white;
  font-size: 14px;
  padding-left: 12px;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${Color.gray};
  }
  :-ms-input-placeholder {
    color: white;
  }
  @media ${devicesMax.desktopS} {
    font-size: 14px;
  }
  @media ${devicesMax.laptopS} {
    font-size: 12px;
    padding-left: 10px;
  }
  @media ${devicesMax.laptopS} {
  }
`;

const Container = styled.span`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 24px;
  text-align: left;
  position: relative;
  @media ${devicesMax.desktopS} {
    margin-bottom: 20px;
  }
  @media ${devicesMax.laptopS} {
    margin-bottom: 16px;
  }
  @media ${devicesMax.tablet} {
    margin-bottom: 8px;
  }
`;

const ErrorText = styled(Text)`
  position: absolute;
  top: -18px;
  right: 24px;
  @media ${devicesMax.laptopS} {
    font-size: 10px;
  }
`;

const IconButton = styled.span`
  cursor: pointer;
`;

const IconError = styled(Icon)`
  @media ${devicesMax.laptopS} {
    transform: scale(0.9);
  }
  @media ${devicesMax.laptop} {
    transform: scale(0.8);
  }
`;

const Label = styled(Text)`
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  margin-bottom: 12px;
  margin-top: 4px;
  @media ${devicesMax.desktopS} {
    font-size: 14px;
    line-height: 16px;
  }
  @media ${devicesMax.laptopS} {
    font-size: 12px;
    line-height: 14px;
  }
  @media ${devicesMax.tablet} {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 10px;
    margin-top: 8px;
  }
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

export const Input: React.FC<InputProps> = ({
  className,
  type = "text",
  onChange,
  onFocus,
  onBlur,
  placeholder,
  value,
  error,
  name,
  label,
}: InputProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const isPasswordInput = type === "password";

  const handlePasswordShowClick = useCallback(() => {
    if (isPasswordInput) {
      setShowPassword(!showPassword);
    }
  }, [showPassword, isPasswordInput]);

  return (
    <Container>
      {label && <Label>{label}</Label>}
      <Content isError={!!error} className={className}>
        <InputStyled
          type={isPasswordInput ? (showPassword ? "text" : "password") : type}
          onChange={onChange}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          name={name}
          // autoComplete={`new-${name}`}
        />
        {isPasswordInput && (
          <IconButton onClick={handlePasswordShowClick}>
            <StyledIcon iconType="eye" withBackground={false} />
          </IconButton>
        )}
        {error && (
          <IconButton>
            <IconError iconType="error" withBackground={false} />
          </IconButton>
        )}
        {error && (
          <ErrorText typography="400-12-12" color={Color.red}>
            <Trans>{error}</Trans>
          </ErrorText>
        )}
      </Content>
    </Container>
  );
};
