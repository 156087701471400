import React, { useCallback } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import { useTranslation, Trans } from "react-i18next";
import { Color } from "../../../constants/styles";
import { Text } from "../../atoms/Text/Text";
import { Button } from "../../atoms/Button/Button";
import { devicesMax } from "../../../constants/device";
import { Input } from "../../atoms/Input/Input";
import { twoFALoginSchema } from "../../../utils/validation";
import { userActions } from "../../../store/modules/account/actions";
import { useSelector, useDispatch } from "react-redux";
import { userSelectors } from "../../../store/modules/account/selectors";
import { isLoading } from "../../../utils/request-statuses";
import { usePrevious } from "../../../hooks/usePrevious";
import { LoginFormValues } from "../../../views/SignIn/SignIn";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { AuthButton } from "../../atoms/AuthButton/AuthButton";

export interface SectionMainProps {
  loginCredentials?: LoginFormValues;
  showLogin?: () => void;
}

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 44px;
  justify-content: space-between;
  height: 100%;
  @media ${devicesMax.tablet} {
    padding: 12px;
  }
`;

const DescriptionContainer = styled.div`
  margin-top: 40px;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
`;


const ErrorText = styled(Text)`
  margin-bottom: 20px;
`;

const StyledLink = styled.a`
  color: ${Color.chateauGreen};
  text-decoration: none;
  text-transform: none;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  cursor: pointer;
`;

const ActionsBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
  @media ${devicesMax.desktopS} {
    margin-top: 20px;
  }
  @media ${devicesMax.tablet} {
    flex-direction: column-reverse;
    margin-top: 0px;
    gap: 20px;
    align-items: center;
  }
`;

const Title = styled(Text)`
  font-size: 40px;
  line-height: 48px;
  font-weight: 400;
  text-transform: uppercase;
  align-self: flex-start;
  @media ${devicesMax.desktopS} {
    font-size: 36px;
    line-height: 42px;
  }
  @media ${devicesMax.laptopXL} {
    font-size: 30px;
    line-height: 38px;
  }
  @media ${devicesMax.laptopS} {
    font-size: 24px;
    line-height: 32px;
  }
`;

interface FormValues {
  secondAuthCode: string;
}

const defaultFormValues = {
  secondAuthCode: "",
};

export const TwoFactorAuthForm: React.FC<SectionMainProps> = ({
  loginCredentials,
  showLogin,
}: SectionMainProps) => {
  const { t } = useTranslation();
  const loginRequestStatus = useSelector(userSelectors.requestStatus);
  const authError = useSelector(userSelectors.authError);
  const { isMobile } = useIsMobile();
  const isLoaderNeeded = isLoading(loginRequestStatus);
  const prevLoginRequestStatus = usePrevious(loginRequestStatus);
  const dispatch = useDispatch();

  const handleSignInPress = useCallback(
    (values: FormValues) => {
      if (loginCredentials) {
        dispatch(
          userActions.login.started({
            username: loginCredentials.login,
            password: loginCredentials.password,
            secondAuthCode: values.secondAuthCode,
          })
        );
      }
    },
    [dispatch]
  );
  console.log(authError);

  const {
    values,
    touched,
    isValid,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
  } = useFormik<FormValues>({
    initialValues: defaultFormValues,
    onSubmit: handleSignInPress,
    validationSchema: twoFALoginSchema,
    validateOnMount: true,
  });

  return (
    <Content>
      <Title>GOOGLE 2FA</Title>
      <DescriptionContainer>
        <Input
          label={isMobile ? t("Enter 2FA code") : t("Google 2FA code")}
          onBlur={handleBlur("secondAuthCode")}
          onChange={handleChange("secondAuthCode")}
          placeholder={isMobile ? t("Enter 2FA code") : t("Google 2FA code")}
          value={values.secondAuthCode}
          error={touched.secondAuthCode && errors.secondAuthCode}
        />
      </DescriptionContainer>
      {authError ? (
        <ErrorText typography="600-16-18" color={Color.red}>
          <Trans>{authError}</Trans>
        </ErrorText>
      ) : null}
      <ActionsBlock>
        <StyledLink onClick={showLogin}>
          <Trans>{"Back to Sign In"}</Trans>
        </StyledLink>
        <AuthButton
          onPress={handleSubmit}
          label={t("Login")}
          isDisabled={!isValid}
          isLoading={isLoaderNeeded}
          isBigSized
          backgroundColor={Color.chateauLightGreen}
          labelColor={Color.white}
        />
      </ActionsBlock>
    </Content>
  );
};
