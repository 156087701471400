import React, { useEffect, useMemo, useCallback, useState } from "react";
import styled, { StyledProps, useTheme } from "styled-components";
import { useTranslation, Trans } from "react-i18next";
import { Color } from "../../constants/styles";
import bgPng from "../../assets/images/bg_scheme.png";
import topBgPng from "../../assets/images/auth_green_bg.png";
import { Section } from "../../components/molecules/Section/Section";
import { Text } from "../../components/atoms/Text/Text";
import { Button } from "../../components/atoms/Button/Button";
import { devicesMax, devicesMin } from "../../constants/device";
import { BaseContainer } from "../../components/molecules/BaseContainer/BaseContainer";
import { useSelector, useDispatch } from "react-redux";
import { userSelectors } from "../../store/modules/account/selectors";
import { isSucceeded, isFailed, isLoading } from "../../utils/request-statuses";
import { userActions } from "../../store/modules/account/actions";
import { useNavigate, NavLink, useParams } from "react-router-dom";
import { ROUTES } from "../../routes";
import { resetPasswordConfirmSchema } from "../../utils/validation";
import { Input } from "../../components/atoms/Input/Input";
import { useFormik } from "formik";
import { usePrevious } from "../../hooks/usePrevious";
import { AuthButton } from "../../components/atoms/AuthButton/AuthButton";

export interface SectionMainProps {}

const Container = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
  height: 100vh;
  align-items: center;
  z-index: 1;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: -40%;
    right: -18%;
    background-image: url(${topBgPng});
    z-index: -1;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    max-height: 1349px;
    width: 100%;
    max-width: 1810px;
  }
`;

const Block = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  max-width: 50%;
  min-width: 500px;
  background: transparent;
  background-size: cover;
  width: 100%;
  background-color: ${Color.sharkBlackLighter};
  border-radius: 20px;
  @media ${devicesMin.desktopS} {
    max-width: 660px;
  }
  @media ${devicesMax.desktopS} {
    max-width: 40%;
  }
  @media ${devicesMax.laptop} {
    max-width: 45%;
  }
  @media ${devicesMax.mobileL} {
    background-color: ${Color.transparent};
    min-width: fit-content;
    max-width: initial;
    width: 100%;
    border-radius: 0px;
  }
`;

const Content = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 34px;
  @media ${devicesMax.desktopS} {
    padding: 30px;
  }
  @media ${devicesMax.laptopXL} {
    padding: 30px;
  }
  @media ${devicesMax.laptopS} {
    padding: 30px;
  }
  @media ${devicesMax.tablet} {
    padding: 20px;
  }
  @media ${devicesMax.mobileL} {
    padding: 12px;
  }
`;

const Logo = styled.img`
  width: 100%;
  max-width: 310px;
`;

const DescriptionContainer = styled.div`
  margin-top: 40px;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
  @media ${devicesMax.desktopS} {
    margin-top: 30px;
  }
  @media ${devicesMax.laptopS} {
    margin-top: 20px;
  }
`;

const StyledSection = styled(Section)`
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
`;

const ErrorText = styled(Text)`
  margin-bottom: 20px;
`;

const Link = styled(NavLink)`
  color: ${Color.chateauGreen};
  text-decoration: none;
  text-transform: none;
  font-size: 13px;
  font-weight: bold;
  pointer-events: ${({
    disabled,
  }: StyledProps<{ disabled?: boolean }>): string =>
    disabled ? "none" : "auto"};
`;

const Title = styled(Text)`
  font-size: 30px;
  line-height: 36px;
  font-weight: 400;
  text-transform: uppercase;
  align-self: flex-start;
  @media ${devicesMax.desktopS} {
    font-size: 24px;
    line-height: 32px;
  }
  @media ${devicesMax.laptopXL} {
    font-size: 24px;
    line-height: 28px;
  }
  @media ${devicesMax.laptopS} {
    font-size: 22px;
    line-height: 28px;
  }
  @media ${devicesMax.mobileL} {
    font-size: 16px;
    line-height: 18px;
  }
`;

const ActionsBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
  align-items: center;
  @media ${devicesMax.desktopS} {
    margin-top: 20px;
  }
  @media ${devicesMax.laptopS} {
    margin-top: 10px;
  }
  @media ${devicesMax.tablet} {
    flex-direction: column-reverse;
    margin-top: 0px;
    align-items: center;
    gap: 20px;
  }
`;

const SuccessText = styled(Text)`
  margin-bottom: 20px;
`;

interface FormValues {
  password: string;
  confirmPassword: string;
}

const defaultFormValues = {
  password: "",
  confirmPassword: "",
};

const RESET_PASSWORD_SUCCESS = "Your password has been successfully reset";

export const ResetPasswordFinishView: React.FC<
  SectionMainProps
> = ({}: SectionMainProps) => {
  const { t } = useTranslation();
  const { key } = useParams();
  const [isFormSubmited, setIsFormSubmitted] = useState<boolean>(false);

  const resetPasswordFinishRequestStatus = useSelector(
    userSelectors.requestStatus
  );
  const prevResetPasswordFinishRequestStatus = usePrevious(
    resetPasswordFinishRequestStatus
  );
  const resetPasswordFinishError = useSelector(
    userSelectors.resetPasswordFinishError
  );

  const isResetPasswordFailed = useMemo(
    () => isFailed(resetPasswordFinishRequestStatus),
    [resetPasswordFinishRequestStatus]
  );
  const isLoaderNeeded = isLoading(resetPasswordFinishRequestStatus);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      isLoading(prevResetPasswordFinishRequestStatus) &&
      isSucceeded(resetPasswordFinishRequestStatus)
    ) {
      setIsFormSubmitted(true);
    }
  }, [
    dispatch,
    prevResetPasswordFinishRequestStatus,
    resetPasswordFinishRequestStatus,
  ]);

  useEffect(() => {
    return () => {
      dispatch(userActions.resetStatuses());
    };
  }, []);

  const handleConfirmClick = useCallback(
    (values: FormValues) => {
      dispatch(
        userActions.resetPasswordFinish.started({
          key: key || "",
          newPassword: values.password,
        })
      );
    },
    [key]
  );

  const {
    values,
    touched,
    isValid,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
  } = useFormik<FormValues>({
    initialValues: defaultFormValues,
    onSubmit: handleConfirmClick,
    validationSchema: resetPasswordConfirmSchema,
    validateOnMount: true,
  });

  const renderResetPasswordSuccess = useCallback(() => {
    return (
      <DescriptionContainer>
        <SuccessText>{RESET_PASSWORD_SUCCESS}</SuccessText>
      </DescriptionContainer>
    );
  }, []);

  return (
    <BaseContainer withHeader={false}>
      <StyledSection bgImage={bgPng}>
        <Container>
          <Block>
            <Content>
              {!isFormSubmited ? (
                <>
                  <Title typography="600-18-24">
                    <Trans>{t("Reset Password")}</Trans>
                  </Title>
                  <DescriptionContainer>
                    <Input
                      onBlur={handleBlur("password")}
                      onChange={handleChange("password")}
                      // placeholder={t("New Password")}
                      label={t("New Password")}
                      type="password"
                      value={values.password}
                      error={touched.password && errors.password}
                    />
                    <Input
                      onBlur={handleBlur("confirmPassword")}
                      onChange={handleChange("confirmPassword")}
                      // placeholder={t("Confirm New Password")}
                      label={t("Confirm New Password")}
                      type="password"
                      value={values.confirmPassword}
                      error={touched.confirmPassword && errors.confirmPassword}
                    />
                  </DescriptionContainer>
                  {isResetPasswordFailed ? (
                    <ErrorText
                      typography="500-14-16"
                      color={Color.chateauGreen}
                    >
                      <Trans>{resetPasswordFinishError}</Trans>
                    </ErrorText>
                  ) : null}
                  <ActionsBlock>
                    <Link to={ROUTES.SIGN_IN}>
                      <Trans>{t("Back to log in")}</Trans>{" "}
                    </Link>
                    <AuthButton
                      onPress={handleSubmit}
                      label={t("Save password")}
                      isDisabled={!isValid}
                      isLoading={isLoaderNeeded}
                      isBigSized
                      backgroundColor={Color.chateauLightGreen}
                      labelColor={Color.white}
                    />
                  </ActionsBlock>
                </>
              ) : null}

              {isFormSubmited ? (
                <>
                  {renderResetPasswordSuccess()}
                  <Link to={ROUTES.SIGN_IN}>
                    <Trans>{t("Back to log in")}</Trans>{" "}
                  </Link>
                </>
              ) : null}
            </Content>
          </Block>
        </Container>
      </StyledSection>
    </BaseContainer>
  );
};
