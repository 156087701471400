import {
  applyMiddleware,
  CombinedState,
  combineReducers,
  createStore,
  Reducer,
} from 'redux';
import storage from 'redux-persist/lib/storage'
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore, createTransform } from 'redux-persist';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import createSagaMiddleware, { SagaIterator } from 'redux-saga';
import { all, AllEffect } from 'redux-saga/effects';
import { AnyAction } from 'typescript-fsa';
import { storeMigration } from '../utils/storeMigration';
import { failedAcionsMiddleware } from './middlewares/failedActionsMiddleware';
import {
  userReducer,
  initialState as userInitialState,
} from './modules/account/reducer';
import {
  notificationsReducer,
  initialState as notificationsInitialState,
} from './modules/notifications/reducers';
import { Reducers } from './types';
import {
  isUserReducer,
} from '../utils/type-guards';
import { userWatcherSaga } from './modules/account/sagas';
import {
  appSettingsReducer,
  initialState as appSettingsInitialState,
} from './modules/appSettings/reducer';

export const initialState = {
  user: userInitialState,
  appSettings: appSettingsInitialState,
  notifications: notificationsInitialState,
};

export type RootReducer = typeof initialState;

export const sagaMiddleware = createSagaMiddleware();

const reducer: Reducer<CombinedState<RootReducer>, AnyAction> = combineReducers(
  {
    user: userReducer,
    appSettings: appSettingsReducer,
    notifications: notificationsReducer,
  },
);

function* rootSaga(): Generator<AllEffect<SagaIterator<unknown>>> {
  yield all([
    userWatcherSaga(),
  ]);
}

const transformStore = createTransform(
  (inboundState: Reducers): Reducers => {
    return inboundState;
  },

  (outBoundState: Reducers): Reducers => {
    if (!outBoundState) {
      return outBoundState;
    }

    if (isUserReducer(outBoundState)) {
      return { ...outBoundState, requestStatus: userInitialState.requestStatus, activateAccountRequestStatus: userInitialState.activateAccountRequestStatus, error: null };
    }
    return outBoundState;
  },
);

const persistConfig = {
  transforms: [transformStore],
  key: 'root',
  storage: storage,
  migrate: storeMigration,
  stateReconciler: hardSet,
  blacklist: [],
};

const middlewares = [sagaMiddleware, failedAcionsMiddleware];

const persistedReducer = persistReducer<CombinedState<RootReducer>, AnyAction>(
  persistConfig,
  reducer,
);
export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middlewares)),
);
export const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
