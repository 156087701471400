export enum RequestError {
  NoInternet = "NO INTERNET CONNECTION",
  CanNotLoadData = "CAN NOT LOAD DATA",
}

export enum ValidationError {
  InvalidAddress = "Address is invalid",
  MinimumPayAmount = "Minimum pay amount should be not less then",
  MaximumPayAmount = "Maximum pay amount should not exceed",
  NotEnoughFunds = "Not enough funds",
  Spaces = "There should be no spaces",
}

export enum ValidationRequiredError {
  Username = "Please enter your username or email",
  Email = "Please enter your email address",
  Password = "Please enter a password",
  Required = "Required field",
  Number = "Field should be a number",
}

export enum ValidationRegisterError {
  InvalidUsername = "Please enter a valid user name",
  InvalidAccountName = "Please enter a valid account number",
  InvalidEmail = "Please enter a valid email address",
  InvalidPassword = "Please enter a valid password",
  EmailExist = "Email address has already been registered",
}

export enum ValidationLengthError {
  MaxLength = "No characters left",
  MinCharacters = "3 characters or more",
  TwoFALength = "The code should be 6 characters length",
}

export enum ValidationPasswordError {
  NoNumber = "One or more number",
  NoUppercase = "One or more uppercase letters",
  NoLowecase = "One or more lowercase letters",
  NoSpecial = "One or more special characters",
  MinCharacters = "8 characters or more",
}

export enum ValidationMismatchError {
  PasswordMismatch = "Passwords mismatch",
}
