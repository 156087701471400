import React from "react";
import styled, { useTheme } from "styled-components";
import { Parallax } from "react-scroll-parallax";
import imagePng from "../../../assets/images/section_mulptf_img.png";
import imageBgPng from "../../../assets/images/section_mulptf_img_bg.png";
import bgLogoLeftPng from "../../../assets/images/section_mulptf_bg_left.png";
import bgLogoRightPng from "../../../assets/images/section_mulptf_bg_right.png";
import titleBg from "../../../assets/images/section_mulptf_title_bg.png";
import { Section } from "../../../components/molecules/Section/Section";
import { Text } from "../../../components/atoms/Text/Text";
import { Button } from "../../../components/atoms/Button/Button";
import { Color } from "../../../constants/styles";
import { devicesMax } from "../../../constants/device";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../../hooks/useIsMobile";
import {
  SocialButton,
  SocialType,
} from "../../../components/atoms/SocialButton/SocialButton";
import { GOOGLE_PLAY_STORE_LINK } from "../../../constants/plays";
import { APP_STORE_LINK } from "../../../constants/apps";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes";

export interface SectionInfoProps {
  className?: string;
  children?: JSX.Element;
}

const SectionStyled = styled(Section)`
  z-index: 100;
  @media ${devicesMax.mobileL} {
    height: auto;
  }
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 600px;
  padding: 50px 40px 0px 0px;
  margin: 50px auto;
  margin-top: 0px;
  position: relative;
  flex-direction: column;
  @media ${devicesMax.laptopXL} {
  }
  @media ${devicesMax.laptopL} {
  }
  @media ${devicesMax.laptopL} {
    margin-top: 0px;
    padding-right: 0px;
  }
  @media ${devicesMax.tablet} {
    min-height: 400px;
  }
  @media ${devicesMax.mobileL} {
    min-height: 340px;
    justify-content: space-between;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 80px;
  margin-right: -40px;
  gap: 5px;
  position: relative;
  justify-content: space-between;
  @media ${devicesMax.tablet} {
    flex: 6;
  }
  @media ${devicesMax.mobileL} {
    margin-top: 0px;
    gap: 0px;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  padding-top: 200px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-end;
  @media ${devicesMax.desktopS} {
    flex: 1;
  }
  @media ${devicesMax.laptopXL} {
  }
  @media ${devicesMax.laptopL} {
  }
  @media ${devicesMax.laptopS} {
  }
  @media ${devicesMax.tablet} {
    flex: 4;
  }
  @media ${devicesMax.mobileL} {
    padding-top: 100px;
  }
`;

const MainText = styled(Text)`
  align-self: flex-start;
  text-transform: uppercase;
  font-size: 80px;
  line-height: 96px;
  text-align: left;
  font-weight: 700;
  @media ${devicesMax.desktopS} {
    font-size: 74px;
    line-height: 82px;
  }
  @media ${devicesMax.laptopXL} {
    font-size: 72px;
    line-height: 80px;
  }
  @media ${devicesMax.laptopL} {
    font-size: 68px;
    line-height: 74px;
  }
  @media ${devicesMax.laptopS} {
    font-size: 60px;
    line-height: 66px;
  }
  @media ${devicesMax.laptop} {
    font-size: 48px;
    line-height: 52px;
  }
`;

const DescriptionText = styled(Text)`
  font-size: 36px;
  line-height: 42px;
  font-weight: 400;
  display: inline;
  white-space: pre-line;
  max-width: 75%;
  @media ${devicesMax.laptopS} {
    font-size: 32px;
    line-height: 38px;
  }
  @media ${devicesMax.laptop} {
    font-size: 26px;
    line-height: 30px;
  }
  @media ${devicesMax.tablet} {
    font-size: 20px;
    line-height: 24px;
  }
  @media ${devicesMax.mobileL} {
    font-size: 16px;
    line-height: 20px;
  }
  @media ${devicesMax.mobileM} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const AppPromoImg = styled.img`
  max-width: 580px;
  max-height: 428px;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-top: -50px;
  @media ${devicesMax.laptopS} {
    max-width: 520px;
  }
  @media ${devicesMax.laptop} {
    max-width: 480px;
  }
`;

const LogoBg = styled.img`
  max-width: 1100px;
  margin-left: -75%;
  margin-top: 65%;
  @media ${devicesMax.desktopS} {
    max-width: 1000px;
    margin-left: -80%;
  }
  @media ${devicesMax.laptopS} {
    max-width: 800px;
    margin-left: -85%;
    margin-top: 70%;
  }
  @media ${devicesMax.laptop} {
    max-width: 700px;
    margin-left: -80%;
    margin-top: 75%;
  }
`;

const LogoRightBg = styled.img`
  max-width: 1600px;
  margin-left: -55%;
  margin-top: -10%;
  @media ${devicesMax.laptopXL} {
    max-width: 1500px;
    margin-left: -58%;
  }
  @media ${devicesMax.laptopS} {
    max-width: 1200px;
    margin-left: -50%;
  }
`;

const TitleLogoBg = styled.img`
  max-width: 1000px;
  max-height: 438px;
  right: 0px;
  top: 19%;
  position: absolute;
  @media ${devicesMax.laptopXL} {
    max-width: 1000px;
    right: 0px;
    top: 19%;
  }
  @media ${devicesMax.laptopL} {
    max-width: 930px;
    right: 0px;
    top: 17%;
  }
  @media ${devicesMax.laptopS} {
    max-width: 830px;
    right: 0px;
    top: 20%;
  }
  @media ${devicesMax.laptop} {
    max-width: 650px;
    top: 27%;
  }
  @media ${devicesMax.tablet} {
    width: 90%;
  }
  @media ${devicesMax.mobileL} {
    display: none;
  }
`;

const AppPromoImgBg = styled.img`
  max-width: 930px;
  max-height: 438px;
  left: -44%;
  top: 30%;
  position: absolute;
  z-index: -1;
  @media ${devicesMax.laptopS} {
    max-width: 740px;
    left: -36%;
    top: 31%;
    height: 50%;
  }
  @media ${devicesMax.laptop} {
    max-width: 700px;
    left: -40%;
    top: 30%;
    height: 53%;
  }
  @media ${devicesMax.tablet} {
    max-width: 580px;
    left: -45%;
    top: 20%;
    height: 50%;
  }
`;

const BackgroundLogo = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Laptop = styled.div`
  display: flex;
  @media ${devicesMax.mobileL} {
    display: none;
  }
`;
const Tablet = styled.div`
  display: none;
  @media ${devicesMax.laptop} {
    display: flex;
  }
`;

const Mobile = styled.div`
  display: none;
  @media ${devicesMax.mobileL} {
    display: flex;
    flex: 1;
  }
`;

const SocialsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin-bottom: 22px;
  @media ${devicesMax.laptop} {
    gap: 15px;
    margin-bottom: 15px;
  }
  @media ${devicesMax.tablet} {
    gap: 10px;
    margin-bottom: 10px;
  }
  @media ${devicesMax.mobileL} {
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  height: 50px;
  border-radius: 16px;
  @media ${devicesMax.laptopS} {
    height: 46px;
    border-radius: 14px;
  }
  @media ${devicesMax.laptop} {
    height: 40px;
    border-radius: 14px;
  }
  @media ${devicesMax.tablet} {
    height: 32px;
    border-radius: 8px;
  }
  @media ${devicesMax.mobileL} {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    box-sizing: border-box;
  }
`;

const ActionsBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 80px;
  justify-content: flex-end;
  @media ${devicesMax.laptopS} {
    margin-top: 60px;
  }
  @media ${devicesMax.laptop} {
    margin-top: 40px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  @media ${devicesMax.mobileL} {
    width: 100%;
  }
`;

const Title = styled(Text)`
  color: ${Color.chateauLightGreen};
  font-size: 64px;
  line-height: 72px;
  font-weight: 900;
  @media ${devicesMax.laptopS} {
    font-size: 52px;
    line-height: 58px;
  }
  @media ${devicesMax.laptop} {
    font-size: 38px;
    line-height: 46px;
  }
  @media ${devicesMax.tablet} {
    font-size: 30px;
    line-height: 36px;
  }
  @media ${devicesMax.mobileL} {
    font-size: 25px;
    line-height: 30px;
  }
  @media ${devicesMax.mobileM} {
    font-size: 20px;
    line-height: 24px;
  }
`;

const SubTitle = styled(Text)`
  font-size: 38px;
  line-height: 46px;
  font-weight: 900;
  @media ${devicesMax.laptopS} {
    font-size: 28px;
    line-height: 36px;
  }
  @media ${devicesMax.tablet} {
    font-size: 20px;
    line-height: 24px;
  }
`;

const SmallText = styled(Text)`
  text-align: left;
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  display: inline;
  white-space: pre-line;
  @media ${devicesMax.laptopS} {
    font-size: 22px;
    line-height: 26px;
  }
  @media ${devicesMax.laptop} {
    font-size: 18px;
    line-height: 24px;
  }
  @media ${devicesMax.tablet} {
    font-size: 15px;
    line-height: 18px;
  }
  @media ${devicesMax.mobileL} {
    font-size: 12px;
    line-height: 15px;
  }
`;

const ParallaxContent = styled.div`
  position: relative;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SectionMultiPlatform: React.FC<SectionInfoProps> = ({
  className,
  children,
}: SectionInfoProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();

  return (
    <SectionStyled overflow="clip visible">
      <Container>
        <TitleLogoBg src={titleBg} />
        <Row>
          <LeftColumn>
            <Column>
              <Title>{t("MULTI PLATFORM")}</Title>
              <SubTitle>{t("WALLET")}</SubTitle>
            </Column>
            {/* <Laptop> */}
            <BackgroundLogo>
              <LogoBg src={bgLogoLeftPng} />
            </BackgroundLogo>
            {/* </Laptop> */}
            <SmallText color={Color.gray}>
              {t("Manage your assets wherever \nit is most")}
              <SmallText color={Color.malibuBlue}>{t("convenient")}</SmallText> {t("for you")}
            </SmallText>
            <DescriptionText>
              {t("Our products are available on popular platforms: iOS, Android, and web")}
            </DescriptionText>
          </LeftColumn>
          <RightColumn>
            <BackgroundLogo>
              <LogoRightBg src={bgLogoRightPng} />
            </BackgroundLogo>
            <Parallax speed={-4}>
              <ParallaxContent>
                <AppPromoImgBg src={imageBgPng} />
                <AppPromoImg src={imagePng} />
                <ActionsBlock>
                  <Laptop>
                    <Column>
                      <SocialsContainer>
                        <SocialButton
                          label="App Store"
                          description="Available on the"
                          type={SocialType.Apple}
                          onPress={() => {
                            window.open(APP_STORE_LINK, "__blank");
                          }}
                        />
                        <SocialButton
                          label="Google Play"
                          description="Android App on"
                          type={SocialType.Google}
                          onPress={() => {
                            window.open(GOOGLE_PLAY_STORE_LINK, "__blank");
                          }}
                        />
                      </SocialsContainer>
                      <StyledButton
                        label={t("Log In")}
                        backgroundColor={Color.chateauLightGreen}
                        labelColor={Color.white}
                        onPress={() => {
                          navigate(ROUTES.SIGN_IN)
                        }}
                      />
                    </Column>
                  </Laptop>
                </ActionsBlock>
              </ParallaxContent>
            </Parallax>
          </RightColumn>
        </Row>
        <ActionsBlock>
          <Mobile>
            <Column>
              <SocialsContainer>
                <SocialButton
                  label="App Store"
                  description="Available on the"
                  type={SocialType.Apple}
                  onPress={() => {
                    window.open(APP_STORE_LINK, "__blank");
                  }}
                />
                <SocialButton
                  label="Google Play"
                  description="Android App on"
                  type={SocialType.Google}
                  onPress={() => {
                    window.open(GOOGLE_PLAY_STORE_LINK, "__blank");
                  }}
                />
              </SocialsContainer>
              <StyledButton
                label={t("Log In")}
                backgroundColor={Color.chateauLightGreen}
                labelColor={Color.white}
                onPress={() => {
                  navigate(ROUTES.SIGN_IN)
                }}
              />
            </Column>
          </Mobile>
        </ActionsBlock>
      </Container>
    </SectionStyled>
  );
};
