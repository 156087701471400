import React, { useState } from 'react';
import styled from 'styled-components';

interface BurgerIconProps {
  onChange: (value: boolean) => void
}

const MenuButton = styled.button`
  display: block;
  transform-origin: 16px 11px;
  float: left;
  outline: none;
  border: 0;
  padding: 4px;
  background: none;
  transform: scale(0.9);

  span {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  :focus {
    border: medium none rgb(111, 255, 176);
    outline: none;
  }

  :hover {
    span:nth-of-type(1) {
      width: 38px;
    }

    span:nth-of-type(2) {
      width: 40px;
    }

    span:nth-of-type(3) {
      width: 37px;
    }
  }

  &.active {
    span:nth-of-type(1) {
      transform: rotate(45deg) translate(9px, 9px);
      width: 40px;
    }

    span:nth-of-type(2) {
      opacity: 0;
      pointer-events: none;
    }

    span:nth-of-type(3) {
      transform: rotate(-45deg) translate(6px, -5px);
      width: 40px;
    }
  }
`;

const Bar = styled.span`
  display: block;
  width: 40px;
  height: 3px;
  margin-bottom: 7px;
  background-color: #fff;
`;

const BurgerIcon: React.FC<BurgerIconProps> = ({onChange}) => {
  const [isHumbergerClicked, setIsHamburgerClicked] = useState(false)

  const clickHandler = () => {
    setIsHamburgerClicked(!isHumbergerClicked)
    onChange(!isHumbergerClicked);
  };

  return (
    <MenuButton
      className={isHumbergerClicked ? 'active' : ''}
      aria-label="Открыть главное меню"
      onClick={clickHandler}
    >
      <Bar />
      <Bar />
      <Bar />
    </MenuButton>
  );
};

export default BurgerIcon;