import React, { useCallback } from "react";
import styled, { useTheme } from "styled-components";
import telegramIconPng from "../../../assets/images/telegram_icon.png";
import emailIconPng from "../../../assets/images/email_icon.png";
import { Color } from "../../../constants/styles";
import { Text } from "../Text/Text";
import { devicesMax } from "../../../constants/device";
import {
  SUPPORT_EMAIL,
  SUPPORT_TELEGRAM_CHAT,
} from "../../../constants/support";
import { useTranslation } from "react-i18next";

export enum ContactType {
  Telegram = "Telegram",
  Email = "Email",
}

const contactTypes: {
  [key in ContactType]: string;
} = {
  [ContactType.Telegram]: telegramIconPng,
  [ContactType.Email]: emailIconPng,
};

export interface ContactLinkProps {
  onPress?: () => void;
  className?: string;
  type: ContactType;
}

const Container = styled.a`
  display: flex;
  padding-left: 8px;
  position: relative;
  cursor: pointer;
`;

const Icon = styled.img`
  width: 50px;
  margin-left: 28px;
  @media ${devicesMax.desktopS} {
    width: 44px;
    margin-left: 24px;
  }
  @media ${devicesMax.laptopXL} {
    width: 38px;
  }
  @media ${devicesMax.laptopS} {
    width: 34px;
    margin-left: 16px;
  }
  @media ${devicesMax.laptop} {
    width: 30px;
    margin-left: 12px;
  }
  @media ${devicesMax.tablet} {
    width: 20px;
    margin-left: 8px;
  }
`;

const LinkText = styled(Text)`
  font-size: 23px;
  font-weight: 400;
  line-height: 27px;
  display: inline;
  @media ${devicesMax.laptopS} {
    font-size: 20px;
  }
  @media ${devicesMax.laptop} {
    font-size: 14px;
    line-height: 17px;
  }
  @media ${devicesMax.tablet} {
    font-size: 12px;
    line-height: 14px;
  }
`;

const Descripton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContactLink: React.FC<ContactLinkProps> = ({
  onPress,
  className,
  type,
}: ContactLinkProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleLinkClick = useCallback(() => {
    if (type === ContactType.Email) {
      window.open(`mailto:${SUPPORT_EMAIL}`);
    } else if (type === ContactType.Telegram) {
      window.open(SUPPORT_TELEGRAM_CHAT);
    }
  }, [type]);

  const renderLinkText = useCallback(() => {
    if (type === ContactType.Email) {
      return (
        <LinkText color={theme.global.text.default} onClick={handleLinkClick}>
          {SUPPORT_EMAIL}
        </LinkText>
      );
    } else if (type === ContactType.Telegram) {
      return (
        <LinkText color={theme.global.text.default} onClick={handleLinkClick}>
          {t("Open")} <LinkText color={Color.malibuBlue}>Telegram</LinkText>{" "}
          {t("chat")}
        </LinkText>
      );
    }
  }, [handleLinkClick, t, theme.global.text.default, type]);

  return (
    <Container onClick={onPress} className={className}>
      <Descripton>{renderLinkText()}</Descripton>
      <Icon src={contactTypes[type]} />
    </Container>
  );
};
