import React from "react";
import styled, {
  useTheme,
  StyledProps,
  ThemeProps,
  FlattenInterpolation,
  DefaultTheme,
} from "styled-components";
import cartPng from "../../../assets/icons/card.png";
import cardPng from "../../../assets/icons/card.png";
import cardInHandPng from "../../../assets/icons/card_in_hand.png";
import likePng from "../../../assets/icons/like.png";
import miningPng from "../../../assets/icons/mining.png";
import paperPng from "../../../assets/icons/paper.png";
import phoneInHandPng from "../../../assets/icons/phone_in_hand.png";
import safePng from "../../../assets/icons/safe.png";
import walletPng from "../../../assets/icons/wallet.png";
import facebookPng from "../../../assets/icons/facebook.png";
import telegramPng from "../../../assets/icons/telegram.png";
import youtubePng from "../../../assets/icons/youtube.png";
import emailPng from "../../../assets/icons/email.png";
import eyePng from "../../../assets/icons/eye.png";
import supportSvg from "../../../assets/icons/support.svg";
import errorPng from "../../../assets/icons/error.png";

import { gradientBackground } from "../../../Theme/commonStyles";

export type IconType =
  | "card"
  | "cart"
  | "mining"
  | "paper"
  | "like"
  | "phone"
  | "safe"
  | "cardInHand"
  | "wallet"
  | "facebook"
  | "telegram"
  | "youtube"
  | "email"
  | "support"
  | "eye"
  | "error";

export interface IconProps {
  className?: string;
  iconType: IconType;
  withBackground?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

interface ContainerProps {
  alignSelf?: string;
  withBackground?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const Container = styled.div`
  display: flex;
  position: relative;
  width: 53px;
  height: 53px;
  border-radius: 50%;
  align-items: center;
  align-self: ${({ alignSelf }: StyledProps<ContainerProps>): string =>
    alignSelf || "auto"};
  cursor: ${({ onClick }: StyledProps<ContainerProps>): string =>
    onClick ? "pointer" : "auto"};
  ${({
    withBackground,
  }: StyledProps<ContainerProps>):
    | FlattenInterpolation<ThemeProps<DefaultTheme>>
    | string => (withBackground ? gradientBackground : "")};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  flex: 1;
`;

const InnerLogo = styled.img`
  height: 100%px;
  widht: 100%;
  align-self: center;
  z-index: 1;
`;

type IconListProps = {
  [key in IconType]: {
    src: string;
    width: number;
    height: number;
  };
};

const iconsList: IconListProps = {
  card: { src: cardPng, width: 28, height: 20 },
  cart: { src: cartPng, width: 30, height: 28 },
  mining: { src: miningPng, width: 34, height: 30 },
  paper: { src: paperPng, width: 24, height: 33 },
  like: { src: likePng, width: 28, height: 25 },
  phone: { src: phoneInHandPng, width: 28, height: 36 },
  safe: { src: safePng, width: 28, height: 22 },
  cardInHand: { src: cardInHandPng, width: 36, height: 23 },
  wallet: { src: walletPng, width: 25, height: 32 },
  facebook: { src: facebookPng, width: 49, height: 49 },
  youtube: { src: youtubePng, width: 49, height: 49 },
  email: { src: emailPng, width: 49, height: 49 },
  telegram: { src: telegramPng, width: 49, height: 49 },
  eye: { src: eyePng, width: 22, height: 12 },
  support: { src: supportSvg, width: 49, height: 49 },
  error: { src: errorPng, width: 22, height: 22 },
};

export const Icon: React.FC<IconProps> = ({
  className,
  iconType,
  withBackground = true,
  onClick,
}: IconProps) => {
  const theme = useTheme();

  return (
    <Container
      withBackground={withBackground}
      className={className}
      onClick={onClick}
    >
      <Content>
        <InnerLogo
          src={iconsList[iconType].src}
          style={{
            width: iconsList[iconType].width,
            height: iconsList[iconType].height,
          }}
        />
      </Content>
    </Container>
  );
};
