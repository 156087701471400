import {reducerWithInitialState} from 'typescript-fsa-reducers';
import {ModuleNames} from '../../types';
import {appSettingsActions} from './actions';

export interface AppSettingsReducer {
  currency: string | null;
  module: ModuleNames;
}

export const initialState: AppSettingsReducer = {
  currency: null,
  module: ModuleNames.AppSettings,
};

export const appSettingsReducer = reducerWithInitialState(initialState).case(
  appSettingsActions.setAppCurrency,
  (state: AppSettingsReducer, {currency}): AppSettingsReducer => ({
    ...state,
    currency,
  }),
);
