import {Color} from '../constants/styles';
import { HeaderTheme } from '../components/organisms/Header/Header.theme';
import { FooterTheme } from '../components/organisms/Footer/Footer.theme';

export const Theme = {
  global: {
    text: {
      default: Color.white,
      active: Color.chateauGreen,
      disabled: Color.white,
    },
    background: {
      green: Color.chateauGreen,
      blue: Color.chambray,
      grey: Color.charade,
      sectionbgOne: Color.woodsmokeGrey,
      sectionBgTwo: Color.ebonyClayGrey,
      sectionBgThree: Color.makoGrey,
      social: Color.white,
    },
    input: {
      background: Color.charade
    }
  },
  Header: HeaderTheme,
  Footer: FooterTheme,
};
