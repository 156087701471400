import React, { useState, useRef } from "react";
import styled, { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { MenuItem, ControlledMenu, useHover } from "@szhsin/react-menu";
import { Color } from "../../../constants/styles";
import { Text } from "../Text/Text";
import { devicesMax, devicesMin } from "../../../constants/device";

export interface AccountLogoLinkProps {
  label?: string;
  className?: string;
  handleClick?: any;
  handleLogOutClick?: any;
}

const Container = styled.a`
  display: flex;
  min-width: 78px;
  height: 34px;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 12px;
  padding: 0px 13px;
  background-color: ${Color.sharkBlackLighter};
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.23);
  box-sizing: border-box;
  font-family: Lato, Nunito Sans, sans-serif;
  @media ${devicesMax.laptopL} {
    height: 32px;
  }
`;

const StyledText = styled(Text)`
  font-weight: 500;
  @media ${devicesMin.desktopM} {
    font-size: 14px;
  }
  @media ${devicesMax.desktopM} {
    font-size: 12px;
  }
  @media ${devicesMax.laptopL} {
    font-size: 12px;
    line-height: 18px;
  }
  @media ${devicesMax.tablet} {
    color: ${Color.white};
  }
`;

export const AccountLogoLink: React.FC<AccountLogoLinkProps> = ({
  label = "?",
  className,
  handleClick,
  handleLogOutClick,
}: AccountLogoLinkProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { anchorProps, hoverProps } = useHover(isOpen, setOpen);

  return (
    <>
      <Container className={className} ref={ref} {...anchorProps}>
        <StyledText typography="400-20-24" color={Color.gray}>
          {label}
        </StyledText>
      </Container>
      <ControlledMenu
        {...hoverProps}
        state={isOpen ? "open" : "closed"}
        anchorRef={ref}
        onClose={() => setOpen(false)}
        transition
        theming="dark"
      >
        <MenuItem onClick={handleClick} value={"wallet/referral_program"}>
          {t("Referral program")}
        </MenuItem>
        <MenuItem onClick={handleClick} value={"/support"}>
          {t("Support")}
        </MenuItem>
        <MenuItem onClick={handleClick} value={"wallet/settings"}>
          {t("Settings")}
        </MenuItem>
        <MenuItem onClick={handleLogOutClick} value={"logOut"}>
          {t("Log out")}
        </MenuItem>
      </ControlledMenu>
    </>
  );
};
