import { reducerWithInitialState } from "typescript-fsa-reducers";
import { RequestStatus } from "../../../utils/request-statuses";
import { ModuleNames } from "../../types";
import { userActions } from "./actions";
import {
  User,
  UserLoginInput,
  UserRegisterInput,
  ResetPasswordInput,
  ResetPasswordFinishInput,
  EnableTwoFAResponseData,
  CheckInstallTwoFAInput,
} from "../../../models/User";
import { Success, Failure } from "typescript-fsa";
import { getErrorMessage } from "../../../utils/errors";
import { AxiosError } from "axios";

export type ErrorMessage = string | null | undefined;

export interface UserReducer {
  user: User;
  twoFactor: {
    qrCode: string | null;
    emergencyCodes: string[];
    checkInstallTwoFA: boolean | null;
  };
  isTwoFa: boolean | null;
  requestStatus: RequestStatus;
  error: ErrorMessage;
  registerError: ErrorMessage;
  resetPasswordError: ErrorMessage;
  resetPasswordFinishError: ErrorMessage;
  isAuthorized: Boolean;
  module: ModuleNames;
  initAppRequestStatus: RequestStatus;
  activateAccountRequestStatus: RequestStatus;
  checkAuthRedirectRequestStatus: RequestStatus;
  checkInstallTwoFARequestStatus: RequestStatus;
}

const initialUser: User = {
  login: "",
  email: "",
  firstName: "",
  lastName: "",
  id: null,
  langKey: "en",
  createdBy: "",
  createdDate: "",
  lastModifiedBy: "",
  lastModifiedDate: "",
  authorities: [],
};

export const initialState: UserReducer = {
  user: initialUser,
  twoFactor: {
    qrCode: null,
    emergencyCodes: [],
    checkInstallTwoFA: null,
  },
  isTwoFa: false,
  requestStatus: RequestStatus.NOT_ASKED,
  isAuthorized: false,
  error: null,
  registerError: null,
  resetPasswordError: null,
  resetPasswordFinishError: null,
  module: ModuleNames.User,
  initAppRequestStatus: RequestStatus.NOT_ASKED,
  activateAccountRequestStatus: RequestStatus.NOT_ASKED,
  checkAuthRedirectRequestStatus: RequestStatus.NOT_ASKED,
  checkInstallTwoFARequestStatus: RequestStatus.NOT_ASKED,
};

export const userReducer = reducerWithInitialState(initialState)
  .case(
    userActions.initApp.started,
    (state: UserReducer): UserReducer => ({
      ...state,
      initAppRequestStatus: RequestStatus.LOADING,
    })
  )
  .case(
    userActions.initApp.done,
    (state: UserReducer): UserReducer => ({
      ...state,
      initAppRequestStatus: RequestStatus.SUCCEEDED,
    })
  )
  .case(
    userActions.initApp.failed,
    (state: UserReducer): UserReducer => ({
      ...state,
      initAppRequestStatus: RequestStatus.FAILED,
    })
  )

  .case(
    userActions.resetAuthorized,
    (state: UserReducer): UserReducer => ({
      ...state,
      isAuthorized: false,
    })
  )

  .case(
    userActions.login.started,
    (state: UserReducer): UserReducer => ({
      ...state,
      requestStatus: RequestStatus.LOADING,
      error: null,
      registerError: null,
      resetPasswordError: null,
      resetPasswordFinishError: null,
      isAuthorized: false,
    })
  )
  .case(
    userActions.login.done,
    (state: UserReducer): UserReducer => ({
      ...state,
      requestStatus: RequestStatus.SUCCEEDED,
      isAuthorized: true,
    })
  )
  .case(
    userActions.login.failed,
    (
      state: UserReducer,
      { error }: Failure<UserLoginInput, unknown>
    ): UserReducer => ({
      ...state,
      requestStatus: RequestStatus.FAILED,
      error: getErrorMessage(error as AxiosError),
      isAuthorized: false,
    })
  )
  .case(
    userActions.getUser.started,
    (state: UserReducer): UserReducer => ({
      ...state,
      error: null,
      registerError: null,
      resetPasswordError: null,
      resetPasswordFinishError: null,
      requestStatus: RequestStatus.LOADING,
    })
  )
  .case(
    userActions.getUser.done,
    (
      state: UserReducer,
      { result }: Success<void, User | null>
    ): UserReducer => ({
      ...state,
      requestStatus: RequestStatus.SUCCEEDED,
      user: result || state.user,
      isAuthorized: true,
    })
  )
  .case(
    userActions.getUser.failed,
    (state: UserReducer, { error }: Failure<void, unknown>): UserReducer => ({
      ...state,
      requestStatus: RequestStatus.FAILED,
      user: initialUser,
      error: getErrorMessage(error as AxiosError),
      // isAuthorized: false,
    })
  )
  .case(
    userActions.checkIfAuthRedirectAvailable.started,
    (state: UserReducer): UserReducer => ({
      ...state,
      checkAuthRedirectRequestStatus: RequestStatus.LOADING,
    })
  )
  .case(
    userActions.checkIfAuthRedirectAvailable.done,
    (
      state: UserReducer,
      { result }: Success<void, User | null>
    ): UserReducer => ({
      ...state,
      checkAuthRedirectRequestStatus: RequestStatus.SUCCEEDED,
      user: result || state.user,
      // isAuthorized: true,
    })
  )
  .case(
    userActions.checkIfAuthRedirectAvailable.failed,
    (state: UserReducer, { error }: Failure<void, unknown>): UserReducer => ({
      ...state,
      checkAuthRedirectRequestStatus: RequestStatus.FAILED,
      user: initialUser,
      error: getErrorMessage(error as AxiosError),
      // isAuthorized: false,
    })
  )
  .case(
    userActions.register.started,
    (state: UserReducer): UserReducer => ({
      ...state,
      registerError: null,
      error: null,
      resetPasswordError: null,
      resetPasswordFinishError: null,
      requestStatus: RequestStatus.LOADING,
    })
  )
  .case(
    userActions.register.done,
    (
      state: UserReducer,
      { result }: Success<UserRegisterInput, void>
    ): UserReducer => ({
      ...state,
      requestStatus: RequestStatus.SUCCEEDED,
    })
  )
  .case(
    userActions.register.failed,
    (
      state: UserReducer,
      { error }: Failure<UserRegisterInput, unknown>
    ): UserReducer => ({
      ...state,
      requestStatus: RequestStatus.FAILED,
      registerError: getErrorMessage(error as AxiosError),
    })
  )
  .case(
    userActions.resetPassword.started,
    (state: UserReducer): UserReducer => ({
      ...state,
      resetPasswordError: null,
      error: null,
      registerError: null,
      resetPasswordFinishError: null,
      requestStatus: RequestStatus.LOADING,
    })
  )
  .case(
    userActions.resetPassword.done,
    (
      state: UserReducer,
      { result }: Success<ResetPasswordInput, void>
    ): UserReducer => ({
      ...state,
      requestStatus: RequestStatus.SUCCEEDED,
    })
  )
  .case(
    userActions.resetPassword.failed,
    (
      state: UserReducer,
      { error }: Failure<ResetPasswordInput, unknown>
    ): UserReducer => ({
      ...state,
      requestStatus: RequestStatus.FAILED,
      resetPasswordError: getErrorMessage(error as AxiosError),
    })
  )
  .case(
    userActions.activateAccount.started,
    (state: UserReducer): UserReducer => ({
      ...state,
      resetPasswordError: null,
      error: null,
      registerError: null,
      resetPasswordFinishError: null,
      activateAccountRequestStatus: RequestStatus.LOADING,
    })
  )
  .case(
    userActions.activateAccount.done,
    (state: UserReducer): UserReducer => ({
      ...state,
      activateAccountRequestStatus: RequestStatus.SUCCEEDED,
    })
  )
  .case(
    userActions.activateAccount.failed,
    (state: UserReducer): UserReducer => ({
      ...state,
      activateAccountRequestStatus: RequestStatus.FAILED,
    })
  )
  .case(
    userActions.resetPasswordFinish.started,
    (state: UserReducer): UserReducer => ({
      ...state,
      resetPasswordError: null,
      error: null,
      registerError: null,
      resetPasswordFinishError: null,
      requestStatus: RequestStatus.LOADING,
    })
  )
  .case(
    userActions.resetPasswordFinish.done,
    (state: UserReducer): UserReducer => ({
      ...state,
      requestStatus: RequestStatus.SUCCEEDED,
    })
  )
  .case(
    userActions.resetPasswordFinish.failed,
    (
      state: UserReducer,
      { error }: Failure<ResetPasswordFinishInput, unknown>
    ): UserReducer => ({
      ...state,
      resetPasswordFinishError: getErrorMessage(error as AxiosError),
      requestStatus: RequestStatus.FAILED,
    })
  )
  .case(
    userActions.enableTwoFA.started,
    (state: UserReducer): UserReducer => ({
      ...state,
      requestStatus: RequestStatus.LOADING,
      twoFactor: initialState.twoFactor,
    })
  )
  .case(
    userActions.enableTwoFA.done,
    (
      state: UserReducer,
      { result }: Success<void, EnableTwoFAResponseData>
    ): UserReducer => ({
      ...state,
      requestStatus: RequestStatus.SUCCEEDED,
      twoFactor: {
        ...state.twoFactor,
        qrCode: result.qr_code,
        emergencyCodes: result.emergency_codes,
      },
    })
  )
  .case(
    userActions.enableTwoFA.failed,
    (state: UserReducer): UserReducer => ({
      ...state,
      requestStatus: RequestStatus.FAILED,
    })
  )
  .case(
    userActions.checkInstallTwoFA.started,
    (state: UserReducer): UserReducer => ({
      ...state,
      checkInstallTwoFARequestStatus: RequestStatus.LOADING,
      twoFactor: { ...state.twoFactor, checkInstallTwoFA: null },
    })
  )
  .case(
    userActions.checkInstallTwoFA.done,
    (
      state: UserReducer,
      { result }: Success<CheckInstallTwoFAInput, boolean>
    ): UserReducer => ({
      ...state,
      checkInstallTwoFARequestStatus: RequestStatus.SUCCEEDED,
      twoFactor: { ...state.twoFactor, checkInstallTwoFA: result },
    })
  )
  .case(
    userActions.checkInstallTwoFA.failed,
    (state: UserReducer): UserReducer => ({
      ...state,
      checkInstallTwoFARequestStatus: RequestStatus.FAILED,
    })
  )
  .case(userActions.auth.started, (state: UserReducer) => ({
    ...state,
    requestStatus: RequestStatus.LOADING,
    isAuthorized: false,
  }))
  .case(
    userActions.auth.done,
    (state: UserReducer): UserReducer => ({
      ...state,
      requestStatus: RequestStatus.SUCCEEDED,
      isAuthorized: true,
    })
  )
  .case(
    userActions.resetStatuses,
    (state: UserReducer): UserReducer => ({
      ...state,
      requestStatus: RequestStatus.NOT_ASKED,
      activateAccountRequestStatus: RequestStatus.NOT_ASKED,
      error: null,
      registerError: null,
      resetPasswordError: null,
      resetPasswordFinishError: null,
      isTwoFa: false,
    })
  )
  .case(
    userActions.loginTwoFaIsOn,
    (state: UserReducer): UserReducer => ({
      ...state,
      isTwoFa: true,
    })
  )
  .case(userActions.logout.started, (state: UserReducer) => ({
    ...state,
    requestStatus: RequestStatus.LOADING,
  }))
  .case(userActions.logout.done, () => initialState);
