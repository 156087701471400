import { useState, useEffect, useMemo } from "react";

export const useIsMobile = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = useMemo(() => width <= 480, [])
  const isTablet = useMemo(() => width <= 768, [])
  return {
    isMobile,
    isTablet
  }
};
