import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "styled-components";
import { CloseNotifierButton } from "./components/atoms/CloseNotifierButton/CloseNotifierButton";
import { Root } from "./Root";
import { persistor, store } from "./store/store";
import { Theme } from "./Theme/Theme";
import GlobalStyle from "./Theme/globalStyle";
import Notifier from "./services/Notifier";
import { PersistGate } from "redux-persist/integration/react";

function App() {
  return (
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persistor}
      >
        <ThemeProvider theme={Theme}>
          <SnackbarProvider
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            action={(snackbarId) => (
              <CloseNotifierButton snackbarId={snackbarId} />
            )}
            autoHideDuration={3000}
          >
            <Notifier />
            <GlobalStyle />
            <Router>
              <ParallaxProvider>
                <Root />
              </ParallaxProvider>
            </Router>
          </SnackbarProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
