import React from "react";
import styled, { useTheme } from "styled-components";
import { Parallax } from "react-scroll-parallax";
import imagePng from "../../../assets/images/section_referral_img.png";
import bgLogoPng from "../../../assets/images/section_referral_bg.png";
import titleBg from "../../../assets/images/section_referral_title_bg.png";
import { Section } from "../../../components/molecules/Section/Section";
import { Text } from "../../../components/atoms/Text/Text";
import { Button } from "../../../components/atoms/Button/Button";
import { Color } from "../../../constants/styles";
import { devicesMax } from "../../../constants/device";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../../hooks/useIsMobile";
import {
  SocialButton,
  SocialType,
} from "../../../components/atoms/SocialButton/SocialButton";

export interface SectionInfoProps {
  className?: string;
  children?: JSX.Element;
}

const SectionStyled = styled(Section)`
  z-index: 100;
  max-height: none;
  @media ${devicesMax.mobileL} {
    height: auto;
  }
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 600px;
  padding: 50px 40px 0px 0px;
  margin: 50px auto;
  margin-top: 20px;
  position: relative;
  flex-direction: row;
  @media ${devicesMax.laptopL} {
    margin-top: 0px;
  }
  @media ${devicesMax.laptopL} {
    margin-top: 0px;
    padding-right: 0px;
  }
  @media ${devicesMax.laptop} {
  }
  @media ${devicesMax.tablet} {
    min-height: 400px;
  }
  @media ${devicesMax.mobileL} {
    min-height: 340px;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex: 6;
  flex-direction: column;
  margin-top: 80px;
  margin-right: -10px;
  gap: 5px;
  position: relative;
  justify-content: space-between;
  padding-bottom: 50px;
  @media ${devicesMax.desktopS} {
    margin-right: -20px;
  }
  @media ${devicesMax.laptopXL} {
    margin-right: -40px;
  }
  @media ${devicesMax.mobileL} {
    margin-top: 0px;
    gap: 0px;
  }
`;

const RightColumn = styled.div`
  flex: 5;
  padding-top: 200px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-end;
  @media ${devicesMax.desktopS} {
    flex: 5;
  }
  @media ${devicesMax.laptopXL} {
  }
  @media ${devicesMax.laptopL} {
  }
  @media ${devicesMax.laptopS} {
  }
  @media ${devicesMax.mobileL} {
    padding-top: 0px;
  }
`;

const MainText = styled(Text)`
  align-self: flex-start;
  text-transform: uppercase;
  font-size: 80px;
  line-height: 96px;
  text-align: left;
  font-weight: 700;
  @media ${devicesMax.desktopS} {
    font-size: 74px;
    line-height: 82px;
  }
  @media ${devicesMax.laptopXL} {
    font-size: 72px;
    line-height: 80px;
  }
  @media ${devicesMax.laptopL} {
    font-size: 68px;
    line-height: 74px;
  }
  @media ${devicesMax.laptopS} {
    font-size: 60px;
    line-height: 66px;
  }
  @media ${devicesMax.laptop} {
    font-size: 48px;
    line-height: 52px;
  }
`;

const DescriptionText = styled(Text)`
font-size: 36px;
line-height: 42px;
  font-weight: 400;
  display: inline;
  white-space: pre-line;
  max-width: 80%;
  @media ${devicesMax.laptopS} {
    font-size: 32px;
    line-height: 38px;
  }
  @media ${devicesMax.laptop} {
    font-size: 26px;
    line-height: 30px;
  }
  @media ${devicesMax.tablet} {
    font-size: 16px;
    line-height: 20px;
  }
  @media ${devicesMax.mobileL} {
    font-size: 16px;
    line-height: 20px;
  }
  @media ${devicesMax.mobileM} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const AppPromoImg = styled.img`
max-width: 540px;
  max-height: 550px;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-top: 10px;
  @media ${devicesMax.laptopS} {
    max-width: 500px;
    margin-top: 0px;
  }
  @media ${devicesMax.laptop} {
    max-width: 460px;
  }
  @media ${devicesMax.tablet} {
    max-width: 300px;
  }
`;

const LogoBg = styled.img`
  max-width: 1700px;
  margin-left: -75%;
  margin-top: 65%;
  @media ${devicesMax.desktopS} {
    max-width: 1400px;
    margin-left: -80%;
  }
  @media ${devicesMax.laptopS} {
    max-width: 1100px;
    margin-left: -85%;
    margin-top: 70%;
  }
  @media ${devicesMax.laptop} {
    max-width: 800px;
    margin-left: -80%;
    margin-top: 75%;
  }
`;

const LogoRightBg = styled.img`
  max-width: 1800px;
  margin-left: -50%;
  margin-top: -10%;
  @media ${devicesMax.desktopS} {
    max-width: 1600px;
    margin-left: -55%;
  }
  @media ${devicesMax.laptopXL} {
    max-width: 1500px;
    margin-left: -58%;
  }
  @media ${devicesMax.laptopS} {
    max-width: 1200px;
    margin-left: -50%;
  }
`;

const TitleLogoBg = styled.img`
  max-width: 1300px;
  max-height: 360px;
  right: 0px;
  top: 11%;
  position: absolute;
  @media ${devicesMax.laptopXL} {
    max-width: 1100px;
    right: 0px;
    top: 11%;
  }
  @media ${devicesMax.laptopL} {
    max-width: 980px;
    right: 0px;
    top: 11%;
  }
  @media ${devicesMax.laptopS} {
    max-width: 890px;
    right: 0px;
    top: 11%;
  }
  @media ${devicesMax.laptop} {
    max-width: 700px;
    top: 16%;
  }
  @media ${devicesMax.tablet} {
    width: 95%;
    top: 23%;
  }
  @media ${devicesMax.mobileL} {
    display: none;
  }
`;

const AppPromoImgBg = styled.img`
  max-width: 1180px;
  max-height: 438px;
  left: -47%;
  top: 33%;
  position: absolute;
  z-index: -1;
  @media ${devicesMax.desktopS} {
    max-width: 1100px;
    top: 29%;
  }
  @media ${devicesMax.laptopXL} {
    max-width: 1000px;
  }
  @media ${devicesMax.laptopL} {
    max-width: 930px;
    left: -44%;
    top: 30%;
  }
  @media ${devicesMax.laptopS} {
    max-width: 740px;
    left: -37%;
    top: 31%;
  }
  @media ${devicesMax.laptop} {
    max-width: 700px;
    left: -45%;
    top: 28%;
  }
`;

const BackgroundLogo = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Laptop = styled.div`
  display: flex;
  @media ${devicesMax.laptop} {
    display: none;
  }
`;
const Tablet = styled.div`
  display: none;
  @media ${devicesMax.laptop} {
    display: flex;
  }
`;

const SocialsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin-bottom: 22px;
  @media ${devicesMax.laptop} {
    gap: 15px;
    margin-bottom: 15px;
  }
`;

const StyledButton = styled(Button)`
  align-self: flex-end;
  height: 52px;
  border-radius: 19px;
  padding: 0 60px;
  border: 1px solid ${Color.chateauLightGreen};
  @media ${devicesMax.laptopS} {
    height: 50px;
    border-radius: 16px;
    padding: 0 50px;
  }
  @media ${devicesMax.laptopS} {
    height: 46px;
    border-radius: 14px;
    padding: 0 40px;
  }
  @media ${devicesMax.laptop} {
    height: 40px;
    border-radius: 14px;
    padding: 0 40px;
  }
  @media ${devicesMax.tablet} {
    height: 32px;
    border-radius: 8px;
    padding: 0 24px;
  }
`;

const ActionsBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 70px;
  justify-content: flex-end;
  @media ${devicesMax.laptopXL} {
    margin-top: 60px;
  }
  @media ${devicesMax.laptopS} {
    margin-top: 50px;
  }
  @media ${devicesMax.laptop} {
    margin-top: 40px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Text)`
  color: ${Color.chateauLightGreen};
  font-size: 64px;
  line-height: 72px;
  font-weight: 900;
  text-transform: uppercase;
  margin-top: 40px;
  @media ${devicesMax.laptopS} {
    font-size: 52px;
    line-height: 58px;
    margin-top: 0px;
  }
  @media ${devicesMax.laptop} {
    font-size: 38px;
    line-height: 46px;
  }
  @media ${devicesMax.tablet} {
    font-size: 30px;
    line-height: 36px;
  }
  @media ${devicesMax.mobileL} {
    font-size: 25px;
    line-height: 30px;
  }
  @media ${devicesMax.mobileM} {
    font-size: 20px;
    line-height: 24px;
  }
`;

const SubTitle = styled(Text)`
font-size: 38px;
line-height: 46px;
  font-weight: 900;
  text-transform: uppercase;
  @media ${devicesMax.laptopS} {
    font-size: 28px;
    line-height: 36px;
  }
  @media ${devicesMax.tablet} {
    font-size: 20px;
    line-height: 24px;
  }
`;

const SmallText = styled(Text)`
  text-align: left;
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  display: inline;
  white-space: pre-line;
  @media ${devicesMax.laptopS} {
    font-size: 22px;
    line-height: 26px;
  }
  @media ${devicesMax.laptop} {
    font-size: 18px;
    line-height: 24px;
  }
  @media ${devicesMax.tablet} {
    font-size: 15px;
    line-height: 18px;
  }
`;

const ParallaxContent = styled.div`
  position: relative;
`;

export const SectionReferral: React.FC<SectionInfoProps> = ({
  className,
  children,
}: SectionInfoProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  return (
    <SectionStyled overflow="clip visible">
      <Container>
        <TitleLogoBg src={titleBg} />
        <LeftColumn>
          <Column>
            <Title>{t("Referral")}</Title>
            <SubTitle>{t("program")}</SubTitle>
          </Column>
          {/* <Laptop> */}
          <BackgroundLogo>
            <LogoBg src={bgLogoPng} />
          </BackgroundLogo>
          {/* </Laptop> */}
          <SmallText color={Color.gray}>
            {t("Earn")}
            <SmallText color={Color.malibuBlue}>
              <b> {t("generous")}</b>
            </SmallText>{" "}
            {t("rewards")}
          </SmallText>
          <DescriptionText>
            <DescriptionText color={Color.chateauLightGreen}>
              <b>{t("Double the Benefits")}</b>
            </DescriptionText>
            {t("Our referral program offers not one")}
          </DescriptionText>
        </LeftColumn>
        <RightColumn>
          <Parallax speed={-4}>
            <ParallaxContent>
              <AppPromoImg src={imagePng} />
              <ActionsBlock>
                <StyledButton
                  label={t("More about the program")}
                  backgroundColor={Color.sharkBlackLighter}
                  labelColor={Color.white}
                />
              </ActionsBlock>
            </ParallaxContent>
          </Parallax>
        </RightColumn>
      </Container>
    </SectionStyled>
  );
};
