import React from "react";
import styled, { useTheme } from "styled-components";
import { Parallax } from "react-scroll-parallax";
import imagePng from "../../../assets/images/section_invest_img.png";
import bgLogoPng from "../../../assets/images/section_invest_bg.png";
import titleBg from "../../../assets/images/section_invest_title.png";
import chartBg from "../../../assets/images/section_invest_chart.png";
import { Section } from "../../../components/molecules/Section/Section";
import { Text } from "../../../components/atoms/Text/Text";
import { Button } from "../../../components/atoms/Button/Button";
import { Color } from "../../../constants/styles";
import { devicesMax } from "../../../constants/device";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../../hooks/useIsMobile";

export interface SectionInfoProps {
  className?: string;
  children?: JSX.Element;
}

const SectionStyled = styled(Section)`
  z-index: 100;
  @media ${devicesMax.mobileL} {
    height: auto;
  }
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 600px;
  padding: 50px 40px 0px 0px;
  margin: 50px auto;
  margin-top: 0px;
  position: relative;
  flex-direction: column;
  @media ${devicesMax.laptopXL} {
  }
  @media ${devicesMax.laptopL} {
  }
  @media ${devicesMax.laptopL} {
  }
  @media ${devicesMax.laptop} {
    padding-top: 0px;
  }
  @media ${devicesMax.tablet} {
    min-height: 400px;
  }
  @media ${devicesMax.mobileL} {
    min-height: 400px;
    padding-right: 0px;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex: 4;
  flex-direction: column;
  margin-right: -40px;
  gap: 5px;
  justify-content: center;
  @media ${devicesMax.mobileL} {
    margin-right: 0px;
  }
`;

const RightColumn = styled.div`
  flex: 5;
  padding-top: 130px;
  display: flex;
  align-items: center;
  position: relative;
  @media ${devicesMax.mobileL} {
    padding-top: 0px;
  }
`;

const MainText = styled(Text)`
  align-self: flex-end;
  text-transform: uppercase;
  font-size: 68px;
    line-height: 74px;
  text-align: left;
  font-weight: 700;
  @media ${devicesMax.laptopS} {
    font-size: 60px;
    line-height: 68px;
  }
  @media ${devicesMax.laptop} {
    font-size: 48px;
    line-height: 52px;
  }
  @media ${devicesMax.tablet} {
    font-size: 30px;
    line-height: 36px;
  }
  @media ${devicesMax.mobileL} {
    font-size: 25px;
    line-height: 30px;
  }
  @media ${devicesMax.mobileM} {
    font-size: 20px;
    line-height: 24px;
  }
`;

const DescriptionText = styled(Text)`
  font-size: 36px;
  line-height: 42px;
  font-weight: 400;
  display: inline;
  white-space: pre-line;
  @media ${devicesMax.laptopS} {
    font-size: 32px;
    line-height: 38px;
  }
  @media ${devicesMax.laptop} {
    font-size: 26px;
    line-height: 30px;
  }
  @media ${devicesMax.tablet} {
    font-size: 20px;
    line-height: 24px;
  }
  @media ${devicesMax.mobileL} {
    font-size: 20px;
    line-height: 24px;
  }
  @media ${devicesMax.mobileL} {
    font-size: 16px;
    line-height: 20px;
  }
  @media ${devicesMax.mobileM} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const AppPromoImg = styled.img`
  max-width: 780px;
  max-height: 432px;
  margin: auto;
  width: 88%;
  height: 100%;
  object-fit: contain;
  @media ${devicesMax.laptopL} {
  }
  @media ${devicesMax.laptopS} {
  }
  @media ${devicesMax.laptop} {
  }
`;

const LogoBg = styled.img`
  max-width: 2100px;
  max-height: 2640px;
  margin-left: -30%;
  object-fit: cover;
  overflow: hidden;
  @media ${devicesMax.desktopS} {
    max-width: 1850px;
    margin-left: -30%;
  }
  @media ${devicesMax.laptop} {
    margin-left: -30%;
  }
`;

const TitleLogoBg = styled.img`
  max-width: 1000px;
  max-height: 438px;
  left: 60px;
  top: 9%;
  position: absolute;
  @media ${devicesMax.laptopL} {
    max-width: 930px;
    top: 9%;
  }
  @media ${devicesMax.laptopS} {
    max-width: 830px;
    top: 12%;
  }
  @media ${devicesMax.laptop} {
    max-width: 650px;
    top: 25%;
    padding-top: 0px;
  }
  @media ${devicesMax.tablet} {
    width: 100%;
    top: 10%;
    left: 0px;
  }
  @media ${devicesMax.mobileL} {
    display: none;
  }
`;

const ChartLogoBg = styled.img`
  max-width: 1263px;
  max-height: 408px;
  left: 0px;
  bottom: -5%;
  position: absolute;
  @media ${devicesMax.desktopS} {
    max-width: 1200px;
  }
  @media ${devicesMax.laptopXL} {
    max-width: 1100px;
  }
  @media ${devicesMax.laptopL} {
    max-width: 1000px;
    bottom: 2%;
  }
  @media ${devicesMax.laptopS} {
    max-width: 860px;
    bottom: 0px;
    bottom: 5%;
  }
  @media ${devicesMax.laptop} {
    max-width: 650px;
    padding-top: 0px;
    bottom: 10%;
  }
`;

const BackgroundLogo = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Laptop = styled.div`
  display: flex;
  @media ${devicesMax.mobileL} {
    display: none;
  }
`;
const Tablet = styled.div`
  display: none;
  @media ${devicesMax.laptop} {
    display: flex;
  }
`;

const Mobile = styled.div`
  display: none;
  @media ${devicesMax.mobileL} {
    display: flex;
  }
`;

const StyledButton = styled(Button)`
  align-self: flex-end;
  height: 52px;
  border-radius: 19px;
  padding: 0 60px;
  border: 1px solid ${Color.chateauLightGreen};
  @media ${devicesMax.laptopS} {
    height: 50px;
    border-radius: 16px;
    padding: 0 50px;
  }
  @media ${devicesMax.laptopS} {
    height: 46px;
    border-radius: 14px;
    padding: 0 40px;
  }
  @media ${devicesMax.laptop} {
    height: 40px;
    border-radius: 14px;
    padding: 0 40px;
  }
  @media ${devicesMax.tablet} {
    height: 32px;
    border-radius: 8px;
    padding: 0 24px;
  }
`;

const ActionsBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 60px;
  justify-content: flex-end;
  @media ${devicesMax.laptopXL} {
    margin-top: 52px;
  }
  @media ${devicesMax.laptopS} {
    margin-top: 40px;
  }
  @media ${devicesMax.laptop} {
    margin-top: 30px;
  }
  @media ${devicesMax.tablet} {
    margin-top: 20px;
  }
`;

const Description = styled.div`
  display: flex;
  margin-bottom: 60px;
  text-align: right;
  @media ${devicesMax.laptopXL} {
    margin-bottom: 52px;
  }
  @media ${devicesMax.laptopS} {
    margin-bottom: 40px;
  }
  @media ${devicesMax.laptop} {
    margin-bottom: 30px;
  }
  @media ${devicesMax.tablet} {
    margin-bottom: 10px;
  }
  @media ${devicesMax.mobileL} {
    margin-bottom: 20px;
    maring-top: 20px;
    text-align: center;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`

export const SectionInvest: React.FC<SectionInfoProps> = ({
  className,
  children,
}: SectionInfoProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  return (
    <SectionStyled overflow="clip visible">
      <Container>
        <TitleLogoBg src={titleBg} />
        <ChartLogoBg src={chartBg} />
        <Mobile>
          <Description>
            <DescriptionText>
              <DescriptionText color={Color.chateauLightGreen}>
                <b>CRYPTONEED</b>
              </DescriptionText>{" "}
              {t("it provides an opportunity to invest cryptocurrency")}
            </DescriptionText>
          </Description>
        </Mobile>
        <Row>
          <RightColumn>
            <Parallax speed={-4}>
              <AppPromoImg src={imagePng} />
            </Parallax>
          </RightColumn>
          <LeftColumn>
            {/* <Laptop> */}
            <BackgroundLogo>
              <LogoBg src={bgLogoPng} />
            </BackgroundLogo>
            {/* </Laptop> */}
            <Laptop>
              <Description>
                <DescriptionText>
                  <DescriptionText color={Color.chateauLightGreen}>
                    <b>CRYPTONEED</b>
                  </DescriptionText>{" "}
                  {t("it provides an opportunity to invest cryptocurrency")}
                </DescriptionText>
              </Description>
            </Laptop>
            <MainText>{t("BUY")}</MainText>
            <MainText color={Color.malibuBlue}>{t("INVEST")}</MainText>
            <MainText>{t("DEPOSIT")}</MainText>
            <ActionsBlock>
              <StyledButton
                label={t("More about capital")}
                backgroundColor={Color.sharkBlackLighter}
                labelColor={Color.white}
              />
            </ActionsBlock>
          </LeftColumn>
        </Row>
      </Container>
    </SectionStyled>
  );
};
