import {PersistState} from 'redux-persist';
import {RootReducer, store} from '../store/store';
import {getStorageItem, setStorageItem, StorageItem} from './local-storage';

interface PersistedState extends Partial<RootReducer> {
  _persist: PersistState;
}

export const storeMigration = async (
  state: PersistedState | undefined,
  currVersion: number,
): Promise<PersistedState | undefined> => {
  const prevVersion = Number(await getStorageItem(StorageItem.PreviousVersion));
  const initState = store.getState();

  if (!state) {
    return Promise.resolve(undefined);
  }

  if (currVersion > prevVersion) {
    // set updated version to the storage if needed and reset store

    return Promise.resolve({
      ...initState,
    });
  }

  return Promise.resolve(state);
};
