import React from "react";
import styled, { useTheme } from "styled-components";
import { Color } from "../../../constants/styles";

export interface BaseContainerProps {
  className?: string;
  children?: JSX.Element;
  withHeader?: boolean;
}

interface ContainerInterface {
  withHeader?: boolean;
}

const Container = styled.div<ContainerInterface>`
  position: relative;
  background-color: ${Color.codGray};
`;

export const BaseContainer: React.FC<BaseContainerProps> = ({
  className,
  children,
  withHeader = true,
}: BaseContainerProps) => {
  const theme = useTheme();

  return (
    <Container className={className} withHeader={withHeader}>
      {children}
    </Container>
  );
};
