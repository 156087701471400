import React from "react";
import { BaseContainer } from "../../components/molecules/BaseContainer/BaseContainer";
import styled, { useTheme } from "styled-components";
import { Color } from "../../constants/styles";
import titleBg from "../../assets/images/section_support_title_bg.svg";
import { Text } from "../../components/atoms/Text/Text";
import { devicesMax } from "../../constants/device";
import { useTranslation } from "react-i18next";
import {
  ContactLink,
  ContactType,
} from "../../components/atoms/ContactLink/ContactLink";

export interface SectionMainProps {
  className?: string;
  children?: JSX.Element;
}

const StyledBaseContainer = styled(BaseContainer)`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(
    50% 600px at 70% 4%,
    #324b9280,
    #324b9280 0%,
    #324b9280 0%,
    #181818 80%
  );
  position: relative;
  @media ${devicesMax.mobileL} {
    align-items: flex-start;
    background: transparent;
  }
`;

const GradientComponent = styled.div`
  background: radial-gradient(
    70% 530px at 20% 90%,
    rgba(50, 75, 146, 0),
    rgba(50, 75, 146, 0.5) 0%,
    rgba(50, 75, 146, 0.3) 0%,
    rgba(24, 24, 24, 0) 80%
  );
  position: absolute;
  top: 0px;
  z-index: 0;
  width: 100vw;
  left: 0px;
  height: 100vh;
  @media ${devicesMax.mobileL} {
    background: transparent;
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1440px;
  height: 100%;
  @media ${devicesMax.laptop} {
    flex-direction: column;
  }
`;

const Section = styled.div`
  display: flex;
  width: 100%;
  background-color: #191919;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 32px;
  z-index: 1;
  position: relative;
  @media ${devicesMax.laptop} {
    padding: 32px 48px;
  }
  @media ${devicesMax.mobileL} {
    background-color: #212328;
    margin-top: 260px;
  }
`;

const Column = styled.div`
  display: flex;
  flex: 3;
  justify-content: center;
  flex-direction: column;
  @media ${devicesMax.tablet} {
    padding: 0px;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 3;
  align-self: center;
  @media ${devicesMax.mobileL} {
    position: absolute;
    top: -180px;
    right: 20px;
  }
`;

const DescriptionContainer = styled.div``;

const Description = styled.div`
  display: flex;
  text-align: right;
  flex-direction: column;
  flex: 4;
  padding-left: 30px;
  @media ${devicesMax.laptopS} {
    margin-bottom: 40px;
  }
  @media ${devicesMax.laptop} {
    margin-bottom: 30px;
  }
  @media ${devicesMax.mobileL} {
    margin-top: 30px;
  }
`;

const Title = styled(Text)`
  color: ${Color.chateauLightGreen};
  font-size: 64px;
  line-height: 72px;
  font-weight: 900;
  text-align: right;
  @media ${devicesMax.laptopS} {
    font-size: 52px;
    line-height: 58px;
  }
  @media ${devicesMax.laptop} {
    font-size: 38px;
    line-height: 46px;
  }
  @media ${devicesMax.tablet} {
    font-size: 30px;
    line-height: 36px;
  }
`;

const SubTitle = styled(Text)`
  font-size: 38px;
  font-weight: 900;
  line-height: 46px;
  text-align: right;
  @media ${devicesMax.laptopL} {
    font-size: 38px;
    line-height: 46px;
  }
  @media ${devicesMax.laptopS} {
    font-size: 28px;
    line-height: 36px;
  }
  @media ${devicesMax.tablet} {
    font-size: 22px;
    line-height: 24px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const DescriptionText = styled(Text)`
  font-size: 36px;
  line-height: 42px;
  font-weight: 400;
  display: inline;
  white-space: pre-line;
  text-align: left;
  @media ${devicesMax.laptopS} {
    font-size: 32px;
    line-height: 38px;
  }
  @media ${devicesMax.laptop} {
    font-size: 26px;
    line-height: 30px;
  }
  @media ${devicesMax.tablet} {
    font-size: 20px;
    line-height: 24px;
  }
  @media ${devicesMax.mobileL} {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
`;

const ActionsBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
  margin-top: 90px;
  @media ${devicesMax.laptopL} {
    margin-top: 50px;
  }
  @media ${devicesMax.laptopS} {
    gap: 16px;
  }
  @media ${devicesMax.laptop} {
    gap: 14px;
  }
  @media ${devicesMax.mobileL} {
    align-items: center;
  }
`;

const TitleLogoBg = styled.img`
  max-width: 1380px;
  top: -230px;
  max-height: 360px;
  position: absolute;
  transform: translateX(-2%);
  @media ${devicesMax.laptopL} {
    max-width: 1180px;
    top: -200px;
  }
  @media ${devicesMax.laptopS} {
    max-width: 980px;
    top: -180px;
  }
  @media ${devicesMax.laptop} {
    max-width: 700px;
    padding-top: 0px;
    transform: translateX(0);
  }
  @media ${devicesMax.tablet} {
    width: 100%;
  }
  @media ${devicesMax.mobileL} {
    top: -120px;
  }
`;

const StyledContactLink = styled(ContactLink)`
  @media ${devicesMax.mobileL} {
    p {
      font-size: 18px !important;
    };
    img {
      width: 24px !important;
    }
  }
`;

export const SupportView: React.FC<SectionMainProps> = ({
  className,
  children,
}: SectionMainProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  // const navigate = useNavigate();

  return (
    <StyledBaseContainer>
      <>
        <Section>
          <TitleLogoBg src={titleBg} />
          <Container>
            <Row>
              <Description>
                <DescriptionText>
                  {t("If you have questions, please contact")}{" "}
                  <DescriptionText color={Color.malibuBlue}>
                    <b>{t("multiple")}</b>
                  </DescriptionText>{" "}
                  {t("contact methods available")}
                </DescriptionText>
                <ActionsBlock>
                  <StyledContactLink type={ContactType.Telegram} />
                  <StyledContactLink type={ContactType.Email} />
                </ActionsBlock>
              </Description>
              <Content style={{ justifyContent: "center" }}>
                <DescriptionContainer>
                  <Column>
                    <Title>{t("CONTACT")}</Title>
                    <SubTitle>{t("WITH US")}</SubTitle>
                  </Column>
                </DescriptionContainer>
              </Content>
            </Row>
          </Container>
        </Section>
        <GradientComponent />
      </>
    </StyledBaseContainer>
  );
};
