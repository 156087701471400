import React from "react";
import styled, { useTheme } from "styled-components";
import { Parallax } from "react-scroll-parallax";
import imagePng from "../../../assets/images/section_contact_img.png";
import bgLogoPng from "../../../assets/images/section_contact_bg.png";
import titleBg from "../../../assets/images/section_contact_title_bg.png";
import { Section } from "../../../components/molecules/Section/Section";
import { Text } from "../../../components/atoms/Text/Text";
import { Button } from "../../../components/atoms/Button/Button";
import { Color } from "../../../constants/styles";
import { devicesMax } from "../../../constants/device";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../../hooks/useIsMobile";
import {
  ContactLink,
  ContactType,
} from "../../../components/atoms/ContactLink/ContactLink";

export interface SectionInfoProps {
  className?: string;
  children?: JSX.Element;
}

const SectionStyled = styled(Section)`
  z-index: 100;
  @media ${devicesMax.mobileL} {
    height: auto;
  }
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 600px;
  padding: 50px 40px 0px 0px;
  margin: 50px auto;
  margin-top: 60px;
  position: relative;
  flex-direction: column;
  @media ${devicesMax.laptopXL} {
  }
  @media ${devicesMax.laptopL} {
  }
  @media ${devicesMax.laptopL} {
  }
  @media ${devicesMax.laptop} {
    padding-top: 0px;
    padding-right: 0px;
  }
  @media ${devicesMax.tablet} {
    min-height: 400px;
  }
  @media ${devicesMax.mobileL} {
    min-height: 340px;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex: 4;
  flex-direction: column;
  gap: 5px;
  justify-content: space-between;
  @media ${devicesMax.desktopS} {
  }
  @media ${devicesMax.laptopXL} {
  }
  @media ${devicesMax.tablet} {
    flex: 1;
  }
`;

const RightColumn = styled.div`
  flex: 5;
  padding-top: 130px;
  display: flex;
  align-items: center;
  position: relative;
  @media ${devicesMax.tablet} {
    flex: 1;
  }
  @media ${devicesMax.mobileL} {
    padding-top: 0px;
  }
`;

const MainText = styled(Text)`
  align-self: flex-end;
  text-transform: uppercase;
  font-size: 80px;
  line-height: 96px;
  text-align: left;
  font-weight: 700;
  @media ${devicesMax.desktopS} {
    font-size: 74px;
    line-height: 82px;
  }
  @media ${devicesMax.laptopXL} {
    font-size: 72px;
    line-height: 80px;
  }
  @media ${devicesMax.laptopL} {
    font-size: 68px;
    line-height: 74px;
  }
  @media ${devicesMax.laptopS} {
    font-size: 60px;
    line-height: 68px;
  }
  @media ${devicesMax.laptop} {
    font-size: 48px;
    line-height: 52px;
  }
`;

const DescriptionText = styled(Text)`
  font-size: 36px;
  line-height: 42px;
  font-weight: 400;
  display: inline;
  white-space: pre-line;
  @media ${devicesMax.laptopS} {
    font-size: 32px;
    line-height: 38px;
  }
  @media ${devicesMax.laptop} {
    font-size: 26px;
    line-height: 30px;
  }
  @media ${devicesMax.tablet} {
    font-size: 20px;
    line-height: 24px;
  }
  @media ${devicesMax.mobileL} {
    font-size: 16px;
    line-height: 20px;
  }
  @media ${devicesMax.mobileM} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const AppPromoImg = styled.img`
  max-width: 440px;
  max-height: 501px;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-left: 30px;
  margin-top: 50px;
  @media ${devicesMax.laptopS} {
    max-width: 380px;
    margin-left: 0px;
    // margin-left: -10%;
  }
  @media ${devicesMax.laptop} {
    max-width: 340px;
    // margin-left: 0px;
  }
  @media ${devicesMax.tablet} {
    max-width: 300px;
  }
`;

const LogoBg = styled.img`
  max-width: 2100px;
  max-height: 2640px;
  margin-left: -30%;
  object-fit: cover;
  overflow: hidden;
  @media ${devicesMax.desktopS} {
    max-width: 1850px;
    margin-left: -30%;
  }
  @media ${devicesMax.laptop} {
    margin-left: -30%;
  }
`;

const TitleLogoBg = styled.img`
  max-width: 1040px;
  top: 19%;
  max-height: 360px;
  left: 60px;
  position: absolute;
  @media ${devicesMax.laptopL} {
    max-width: 960px;
    top: 19%;
  }
  @media ${devicesMax.laptopS} {
    max-width: 860px;
  }
  @media ${devicesMax.laptop} {
    max-width: 680px;
    padding-top: 0px;
  }
  @media ${devicesMax.tablet} {
    width: 100%;
    left: 0px;
  }
  @media ${devicesMax.mobileL} {
    display: none;
  }
`;

const ChartLogoBg = styled.img`
  max-width: 1263px;
  max-height: 408px;
  left: 0px;
  bottom: -5%;
  position: absolute;
  @media ${devicesMax.desktopS} {
    max-width: 1200px;
  }
  @media ${devicesMax.laptopXL} {
    max-width: 1100px;
  }
  @media ${devicesMax.laptopL} {
    max-width: 1000px;
    bottom: 2%;
  }
  @media ${devicesMax.laptopS} {
    max-width: 860px;
    bottom: 0px;
    bottom: 5%;
  }
  @media ${devicesMax.laptop} {
    max-width: 650px;
    padding-top: 0px;
    bottom: 10%;
  }
`;

const BackgroundLogo = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Laptop = styled.div`
  display: flex;
  @media ${devicesMax.laptop} {
    display: none;
  }
`;
const Tablet = styled.div`
  display: none;
  @media ${devicesMax.laptop} {
    display: flex;
  }
`;

const ActionsBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
  @media ${devicesMax.laptopS} {
    gap: 16px;
  }
  @media ${devicesMax.laptop} {
    gap: 14px;
  }
`;

const Description = styled.div`
  display: flex;
  margin-bottom: 52px;
  text-align: right;
  @media ${devicesMax.laptopS} {
    margin-bottom: 40px;
  }
  @media ${devicesMax.laptop} {
    margin-bottom: 30px;
  }
  @media ${devicesMax.mobileL} {
    margin-top: 30px;
  }
`;

const HeaderTitle = styled(Text)`
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 50%;
    height: 0.01em;
    background-color: white;
    opacity: 0.2;
  }
  @media ${devicesMax.laptop} {
    &::after {
      content: "";
      position: absolute;
      bottom: -20px;
      left: 0;
      width: 100%;
      height: 0.01em;
      background-color: white;
      opacity: 0.2;
    }
  }
  @media ${devicesMax.mobileL} {
    display: none;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

const Title = styled(Text)`
  color: ${Color.chateauLightGreen};
  font-size: 64px;
  line-height: 72px;
  font-weight: 900;
  text-align: right;
  @media ${devicesMax.laptopS} {
    font-size: 52px;
    line-height: 58px;
  }
  @media ${devicesMax.laptop} {
    font-size: 38px;
    line-height: 46px;
  }
  @media ${devicesMax.tablet} {
    font-size: 30px;
    line-height: 36px;
  }
  @media ${devicesMax.mobileL} {
    font-size: 25px;
    line-height: 30px;
  }
  @media ${devicesMax.mobileM} {
    font-size: 20px;
    line-height: 24px;
  }
`;

const SubTitle = styled(Text)`
  font-size: 38px;
  font-weight: 900;
  line-height: 46px;
  text-align: right;
  @media ${devicesMax.laptopL} {
    font-size: 38px;
    line-height: 46px;
  }
  @media ${devicesMax.laptopS} {
    font-size: 28px;
    line-height: 36px;
  }
  @media ${devicesMax.tablet} {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const SectionContacts: React.FC<SectionInfoProps> = ({
  className,
  children,
}: SectionInfoProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  return (
    <SectionStyled overflow="clip visible">
      <Container>
        <HeaderTitle typography={"700-20-24"}>{t("Support")}</HeaderTitle>
        <Row>
          <TitleLogoBg src={titleBg} />
          <RightColumn>
            <Parallax speed={-4}>
              <AppPromoImg src={imagePng} />
            </Parallax>
          </RightColumn>
          <LeftColumn>
            {/* <Laptop> */}
            <BackgroundLogo>
              <LogoBg src={bgLogoPng} />
            </BackgroundLogo>
            {/* </Laptop> */}
            <Column>
              <Title>{t("CONTACT")}</Title>
              <SubTitle>{t("WITH US")}</SubTitle>
            </Column>
            <Description>
              <DescriptionText>
                {t("If you have questions, please contact")}{" "}
                <DescriptionText color={Color.malibuBlue}>
                  <b>{t("multiple")}</b>
                </DescriptionText>{" "}
                {t("contact methods available")}
              </DescriptionText>
            </Description>
            <ActionsBlock>
              <ContactLink type={ContactType.Telegram} />
              <ContactLink type={ContactType.Email} />
            </ActionsBlock>
          </LeftColumn>
        </Row>
      </Container>
    </SectionStyled>
  );
};
