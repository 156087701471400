import React, { useEffect, useMemo } from "react";
import styled, { StyledProps } from "styled-components";

import { Color } from "../../constants/styles";
import bgPng from "../../assets/images/bg_scheme.png";
import topBgPng from "../../assets/images/auth_green_bg.png";
import { Section } from "../../components/molecules/Section/Section";
import { Text } from "../../components/atoms/Text/Text";
import { devicesMax, devicesMin } from "../../constants/device";
import { BaseContainer } from "../../components/molecules/BaseContainer/BaseContainer";
import { useSelector, useDispatch } from "react-redux";
import { userSelectors } from "../../store/modules/account/selectors";
import { isSucceeded, isFailed, isLoading } from "../../utils/request-statuses";
import { userActions } from "../../store/modules/account/actions";
import { useNavigate, NavLink, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../routes";
import { usePrevious } from "../../hooks/usePrevious";

export interface SectionMainProps { }

const Container = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
  height: 100vh;
  align-items: center;
  z-index: 1;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: -40%;
    right: -18%;
    background-image: url(${topBgPng});
    z-index: -1;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    max-height: 1349px;
    width: 100%;
    max-width: 1810px;
  }
`;

const Block = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  max-width: 50%;
  min-width: 500px;
  background: transparent;
  background-size: cover;
  width: 100%;
  background-color: ${Color.sharkBlackLighter};
  border-radius: 20px;
  @media ${devicesMin.desktopS} {
    max-width: 660px;
  }
  @media ${devicesMax.desktopS} {
    max-width: 40%;
  }
  @media ${devicesMax.laptop} {
    max-width: 45%;
  }
  @media ${devicesMax.mobileL} {
    background-color: ${Color.transparent};
    min-width: fit-content;
    max-width: initial;
    width: 100%;
    border-radius: 0px;
  }
`;

const Content = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 34px;
  @media ${devicesMax.desktopS} {
    padding: 30px;
  }
  @media ${devicesMax.laptopXL} {
    padding: 30px;
  }
  @media ${devicesMax.laptopS} {
    padding: 30px;
  }
  @media ${devicesMax.tablet} {
    padding: 20px;
  }
  @media ${devicesMax.mobileL} {
    padding: 12px;
  }
`;

const Logo = styled.img`
  width: 100%;
  max-width: 310px;
`;

const DescriptionContainer = styled.div`
  margin-top: 50px;
  text-align: center;
  margin-bottom: 40px;
  width: 100%;
  @media ${devicesMax.laptop} {
    margin-top: 30px;
  }
`;

const StyledSection = styled(Section)`
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
`;

const ErrorText = styled(Text)`
  margin-bottom: 20px;
`;

const BottomContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

const Link = styled(NavLink)`
  color: ${Color.chateauGreen};
  text-decoration: none;
  text-transform: none;
  font-size: 13px;
  font-weight: bold;
  pointer-events: ${({
  disabled,
}: StyledProps<{ disabled?: boolean }>): string =>
    disabled ? "none" : "auto"};
`;

const Title = styled(Text)`
  font-size: 30px;
  line-height: 36px;
  font-weight: 400;
  text-transform: uppercase;
  align-self: center;
  @media ${devicesMax.desktopS} {
    font-size: 24px;
    line-height: 32px;
  }
  @media ${devicesMax.laptopXL} {
    font-size: 24px;
    line-height: 28px;
  }
  @media ${devicesMax.laptopS} {
    font-size: 22px;
    line-height: 28px;
  }
  @media ${devicesMax.mobileL} {
    font-size: 16px;
    line-height: 18px;
  }
`;

const DescriptionText = styled(Text)`
  align-self: center;
  text-align: center;
  margin-bottom: 20px;
  @media ${devicesMax.desktopS} {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const AccountActivateView: React.FC<
  SectionMainProps
> = ({ }: SectionMainProps) => {
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key");
  const activateAccountRequestStatus = useSelector(
    userSelectors.activateAccountRequestStatus
  );
  const prevActivateAccountRequestStatus = usePrevious(
    activateAccountRequestStatus
  );
  const isActivationFailed = useMemo(
    () => isFailed(activateAccountRequestStatus),
    [activateAccountRequestStatus]
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (key) {
      dispatch(userActions.activateAccount.started({ key }));
    }
  }, [dispatch, key]);

  useEffect(() => {
    if (
      isLoading(prevActivateAccountRequestStatus) &&
      isSucceeded(activateAccountRequestStatus)
    ) {
      navigate(ROUTES.SIGN_IN);
    }
  }, [dispatch, navigate, activateAccountRequestStatus, prevActivateAccountRequestStatus]);

  return (
    <BaseContainer withHeader={false}>
      <StyledSection bgImage={bgPng}>
        <Container>
          <Block>
            <Content>
              {isActivationFailed ? (
                <>
                  <Title> Activation error :(</Title>
                  <DescriptionContainer>
                    <DescriptionText>You link is invalid</DescriptionText>
                  </DescriptionContainer>
                  <BottomContent>
                    <Link to={ROUTES.SIGN_UP}>Register Account</Link>
                  </BottomContent>
                </>
              ) : (
                <>
                  <Title>Account activation</Title>
                  <DescriptionContainer>
                    <DescriptionText typography="600-20-24">
                      Please wait...
                    </DescriptionText>
                  </DescriptionContainer>
                </>
              )}
            </Content>
          </Block>
        </Container>
      </StyledSection>
    </BaseContainer>
  );
};
