const sizes = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "480px",
  tablet: "768px",
  laptop: "1024px",
  laptopS: "1280px",
  laptopL: "1440px",
  laptopXL: "1536px",
  desktopXS: "1680px",
  desktopS: "1728px",
  desktopM: "1920px",
  desktop: "2560px",
};

export const devicesMin = {
  mobileS: `(min-width: ${sizes.mobileS})`,
  mobileM: `(min-width: ${sizes.mobileM})`,
  mobileL: `(min-width: ${sizes.mobileL})`,
  tablet: `(min-width: ${sizes.tablet})`,
  laptop: `(min-width: ${sizes.laptop})`,
  laptopS: `(min-width: ${sizes.laptopS})`,
  laptopL: `(min-width: ${sizes.laptopL})`,
  laptopXL: `(min-width: ${sizes.laptopXL})`,
  desktopXS: `(min-width: ${sizes.desktopXS})`,
  desktopS: `(min-width: ${sizes.desktopS})`,
  desktopM: `(min-width: ${sizes.desktopM})`,
  desktop: `(min-width: ${sizes.desktop})`,
};

export const devicesMax = {
  mobileS: `(max-width: ${sizes.mobileS})`,
  mobileM: `(max-width: ${sizes.mobileM})`,
  mobileL: `(max-width: ${sizes.mobileL})`,
  tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  laptopS: `(max-width: ${sizes.laptopS})`,
  laptopL: `(max-width: ${sizes.laptopL})`,
  laptopXL: `(max-width: ${sizes.laptopXL})`,
  desktopXS: `(max-width: ${sizes.desktopXS})`,
  desktopS: `(max-width: ${sizes.desktopS})`,
  desktopM: `(max-width: ${sizes.desktopM})`,
  desktop: `(max-width: ${sizes.desktop})`,
};
