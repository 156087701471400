import React from "react";
import styled, {
  StyledProps,
  css,
  FlattenSimpleInterpolation,
} from "styled-components";
import { Color } from "../../../constants/styles";

export type Typography =
  | "400-24-20"
  | "400-18-24"
  | "400-10-12"
  | "600-10-12"
  | "400-12-12"
  | "400-15-18"
  | "500-14-16"
  | "600-16-18"
  | "600-18-24"
  | "600-20-24"
  | "600-24-26"
  | "500-43-48"
  | "500-19-20"
  | "400-19-20"
  | "400-20-24"
  | "800-24-26"
  | "700-24-28"
  | "700-20-24"
  | "400-24-28"
  | "800-43-28"
  | "400-43-28"
  | "500-17-20";

interface TextProps {
  color?: Color;
  typography?: Typography;
  uppercase?: boolean;
}

const getTypography = ({
  typography,
}: StyledProps<TextProps>): FlattenSimpleInterpolation => {
  switch (typography) {
    case "400-24-20":
      return css`
        font-weight: 400;
        font-size: 24px;
        line-height: 20px;
      `;
    case "400-10-12":
      return css`
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
      `;
    case "600-10-12":
      return css`
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
      `;
    case "400-12-12":
      return css`
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
      `;
    case "400-15-18":
      return css`
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
      `;
    case "500-14-16":
      return css`
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
      `;
    case "400-18-24":
      return css`
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
      `;
    case "400-19-20":
      return css`
        font-weight: 400;
        font-size: 19px;
        line-height: 22px;
      `;
    case "400-20-24":
      return css`
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
      `;
    case "600-16-18":
      return css`
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
      `;
    case "600-18-24":
      return css`
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      `;
    case "500-17-20":
      return css`
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
      `;
    case "500-19-20":
      return css`
        font-weight: 500;
        font-size: 19px;
        line-height: 20px;
      `;
    case "500-43-48":
      return css`
        font-weight: 500;
        font-size: 43px;
        line-height: 48px;
      `;
    case "600-20-24":
      return css`
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      `;
    case "600-24-26":
      return css`
        font-weight: 600;
        font-size: 24px;
        line-height: 26px;
      `;
    case "800-24-26":
      return css`
        font-weight: 800;
        font-size: 24px;
        line-height: 26px;
      `;
    case "700-24-28":
      return css`
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
      `;
    case "700-20-24":
      return css`
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
      `;
    case "400-24-28":
      return css`
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
      `;
    case "800-43-28":
      return css`
        font-weight: 800;
        font-size: 43px;
        line-height: 46px;
      `;
    case "400-43-28":
      return css`
        font-weight: 400;
        font-size: 43px;
        line-height: 46px;
      `;

    default:
      return css`
        font-size: 16px;
        line-height: 20px;
      `;
  }
};

export const Text = styled.p`
  ${getTypography}
  z-index: 1;
  margin: 0px;
  color: ${({ theme, color }: StyledProps<TextProps>): Color =>
    color || theme.global.text.default};
  text-transform: ${({ theme, uppercase }: StyledProps<TextProps>): string =>
    uppercase ? "uppercase" : "none"};
`;
