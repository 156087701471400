import * as React from "react";
import { Trans } from "react-i18next";
import { IconButton, Slide, Typography, Grid } from "@mui/material";
import styled, { useTheme, StyledProps } from "styled-components";
import { Color, DEFAULT_HEADER_HEIGHT } from "../../../../constants/styles";
// import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";
import CloseIcon from "../../../../assets/icons/close.svg";
import ArrowBackIcon from "../../../../assets/icons/back.svg";
import { makeStyles } from "@mui/styles";
import { Text } from "../../../atoms/Text/Text";
import { devicesMax } from "../../../../constants/device";
// import {
//   gradientBackground,
//   headerBackground,
//   headerMenuBackground,
// } from "../../../../Theme/commonStyles";

import Dialog, { DialogProps } from "@mui/material/Dialog";
import Paper, { PaperProps } from "@mui/material/Paper";
import { alpha } from "@mui/material/styles";

export interface DialogWrapperProps {
  open: boolean;
  title?: string;
  handleClose: any;
  handleBack?: any;
  children?: any;
}
const DialogStyled = styled(Dialog)<DialogProps>(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: 12,
    color: theme.global.text.default,
    backgroundColor: "#464B58",
  },
  // "& .MuiSlider-thumb": {
  //   "&:hover, &.Mui-focusVisible": {
  //     boxShadow: `0px 0px 0px 8px ${alpha(theme.palette.success.main, 0.16)}`,
  //   },
  //   "&.Mui-active": {
  //     boxShadow: `0px 0px 0px 14px ${alpha(theme.palette.success.main, 0.16)}`,
  //   },
  // },
}));
const PaperStyled = styled("div")<PaperProps>(({ theme }) => ({
  padding: 40,
  backgroundColor: "#464B58",

  // [theme.breakpoints.down("xs")]: {
  //
  //   paddingTop: theme.spacing(2),
  //   paddingBottom: theme.spacing(2),
  //   paddingLeft: theme.spacing(2),
  //   paddingRight: theme.spacing(2),
  // },
  // "& .MuiPaper-root": {
  //   color: theme.global.text.default,
  // },
}));

const DialogWrapper = ({
  handleClose,
  open,
  children,
  handleBack,
  title,
}: DialogWrapperProps) => {
  const theme = useTheme();
  return (
    <DialogStyled
      fullWidth={true}
      keepMounted
      onClose={handleClose}
      open={open}>
      <PaperStyled>
        <Grid
          container
          justifyContent='space-between'
          alignItems='flex-start'
          wrap='nowrap'
          style={{ marginBottom: 20 }}>
          <Grid item>
            {Boolean(handleBack) && (
              <IconButton size={"small"} onClick={handleBack}>
                <img src={ArrowBackIcon} alt='backIcon' />
              </IconButton>
            )}
          </Grid>
          <Grid item container justifyContent='center'>
            <Text typography={"600-20-24"}>
              <Trans>{title}</Trans>
            </Text>
          </Grid>
          <Grid item>
            <IconButton size={"small"} onClick={handleClose}>
              <img src={CloseIcon} alt='close' />
            </IconButton>
          </Grid>
        </Grid>
        <div>{children}</div>
      </PaperStyled>
    </DialogStyled>
  );
};
export default DialogWrapper;

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiPaper-root": {
      // padding: theme.spacing(5),
      // [theme.breakpoints.down("xs")]: {
      //   width: "100%",
      //   paddingTop: theme.spacing(2),
      //   paddingBottom: theme.spacing(2),
      //   paddingLeft: theme.spacing(2),
      //   paddingRight: theme.spacing(2),
      // },
    },
  },

  header: {
    height: 64,
    paddingBottom: 16,
    // [theme.breakpoints.down("xs")]: {},
  },
  title: {
    height: "100%",
    display: "flex",
    alignItems: "flex-start",
    // [theme.breakpoints.down("xs")]: {
    //   alignItems: "flex-end",
    // },
  },
  box: {
    // [theme.breakpoints.down("xs")]: {
    //   marginTop: 0,
    // },
  },
}));
