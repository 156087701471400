import React from "react";
import styled, {
  useTheme,
  StyledProps,
} from "styled-components";
import { TailSpin } from "react-loader-spinner";
import { Color } from "../../../constants/styles";
import { Text, Typography } from "../Text/Text";
import { devicesMax } from "../../../constants/device";

export interface ButtonProps {
  onPress?: () => void;
  label: string;
  labelColor?: Color;
  labelTypography?: Typography;
  className?: string;
  backgroundColor?: Color;
  borderColor?: Color;
  color?: Color;
  isRounded?: boolean;
  isBigSized?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
}

interface ContainerProps {
  backgroundColor?: Color;
  borderColor?: Color;
  color?: Color;
  isRounded?: boolean;
  isBigSized?: boolean;
  isDisabled?: boolean;
}

const Container = styled.a`
  display: flex;
  width: ${({ isBigSized }: StyledProps<ContainerProps>): string =>
    isBigSized ? "242px" : "auto"};
  min-width: 60px;
  height: ${({ isBigSized }: StyledProps<ContainerProps>): string =>
    isBigSized ? "64px" : "37px"};
  align-items: center;
  justify-content: center;
  position: relative;
  padding: ${({ isBigSized }: StyledProps<ContainerProps>): string =>
    isBigSized ? "auto" : "0 20px"};
  border-radius: ${({
    isRounded,
    isBigSized,
  }: StyledProps<ContainerProps>): string =>
    isRounded ? (isBigSized ? "32px" : "22px") : "10px"};
  background-color: ${({
    theme,
    backgroundColor,
  }: StyledProps<ContainerProps>): Color =>
    backgroundColor || theme.global.background.grey};
  cursor: ${({ isDisabled }: StyledProps<ContainerProps>): string =>
    isDisabled ? "default" : "pointer"};
  pointer-events: ${({ isDisabled }: StyledProps<ContainerProps>): string =>
    isDisabled ? "none" : "auto"};
  opacity: ${({ isDisabled }: StyledProps<ContainerProps>): string =>
    isDisabled ? "0.6" : "1"};
`;

const StyledText = styled(Text)`
  @media ${devicesMax.laptop} {
    font-size: 14px;
    line-height: 18px;
  }
  @media ${devicesMax.tablet} {
    font-size: 12px;
    line-height: 16px;
  }
`

export const Button: React.FC<ButtonProps> = ({
  onPress,
  label,
  labelColor,
  labelTypography,
  className,
  backgroundColor,
  borderColor,
  isRounded,
  isBigSized,
  isDisabled,
  isLoading,
}: ButtonProps) => {
  const theme = useTheme();

  return (
    <Container
      onClick={onPress}
      className={className}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      isRounded={isRounded}
      isBigSized={isBigSized}
      isDisabled={isDisabled}
    >
      {!isLoading ? (
        <StyledText
          typography={labelTypography}
          color={labelColor || Color.dustyGray}
        >
          {label}
        </StyledText>
      ) : (
        <TailSpin color={Color.dustyGray} height={18} width={18} />
      )}
    </Container>
  );
};
