import React, { useCallback, useState } from "react";
import styled, { StyledProps } from "styled-components";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes";
import logoPng from "../../../assets/images/logo_header.png";
import { Color, DEFAULT_FOOTER_HEIGHT } from "../../../constants/styles";
import { Text } from "../../atoms/Text/Text";
import MailIcon from "../../../assets/icons/mail.svg";
import TelegramIcon from "../../../assets/icons/telegram.svg";
import { devicesMax } from "../../../constants/device";
import { Trans, useTranslation } from "react-i18next";
import { CAPITAL_BASE_URL, WALLET_BASE_URL } from "../../../constants/api";
import {
  SUPPORT_EMAIL,
  SUPPORT_TELEGRAM_CHAT,
} from "../../../constants/support";
import { Grid, Typography } from "@mui/material";
import InfoDialog from "../../organisms/dialogs/InfoDialog/InfoDialog";

export interface FooterProps {}

const Container = styled.div`
  min-height: ${DEFAULT_FOOTER_HEIGHT}px;
  display: flex;
  padding: 80px 120px;
  margin-top: 120px;
  align-items: center;
  justify-content: space-between;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 101;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding-bottom: 20px;
  @media ${devicesMax.laptop} {
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    padding-bottom: 20px;
  }
  @media ${devicesMax.tablet} {
    flex-direction: column;
    justify-content: center;
    padding: 40px 10px;
    padding-bottom: 10px;
  }
`;

const Logo = styled.img`
  height: 54px;
  @media ${devicesMax.laptop} {
    height: 38px;
  }
`;

const LogoWrapper = styled.div`
  flex: 2;
  @media ${devicesMax.laptop} {
    flex: 1;
    margin-right: 5px;
  }
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 5;
  max-width: 1440px;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 40px;
  @media ${devicesMax.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
  @media ${devicesMax.tablet} {
    align-items: center;
    margin-top: 20px;
  }
`;

const EmptySpace = styled.span<{ flex?: number }>`
  flex: 1;
  @media ${devicesMax.laptopS} {
    flex: none;
  }
`;

const Mobile = styled.div`
  display: none;
  @media ${devicesMax.mobileL} {
    display: flex;
  }
`;

const LaptopLarge = styled.div`
  display: flex;
  @media ${devicesMax.laptopS} {
    display: none;
  }
`;

const Link = styled.a`
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  color: ${Color.gray};
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  letter-spacing: -0.3px;
  @media ${devicesMax.laptop} {
    font-size: 16px;
    line-height: 20px;
  }
`;
const LinkContainer = styled.div`
  padding-left: calc(100% / 5);
  @media ${devicesMax.mobileL} {
    padding-left: 0;
  }
`;

const LinkSupport = styled.a`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme }: StyledProps<unknown>): Color =>
    theme.Footer.textColor.active};
  text-decoration: none;
  white-space: nowrap;
  text-transform: lowercase;
  cursor: pointer;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  flex-direction: row;
`;

const SectionTitle = styled(Text)`
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.3px;
  text-transform: uppercase;
  @media ${devicesMax.laptop} {
    font-size: 18px;
    line-height: 24px;
  }
`;

const Divider = styled.div`
  width: 100%;
  padding: 0px 40px;
  height: 0.2px;
  background-color: ${Color.gray};
  margin-bottom: 60px;
`;

export const Footer: React.FC<FooterProps> = ({}: FooterProps) => {
  const navigate = useNavigate();
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const { t } = useTranslation();

  const handleMenuClick = useCallback((pathName = "/") => {
    if (true
    ) {
      return window.open(
        `${
          pathName?.includes("wallet")
            ? WALLET_BASE_URL
            : pathName?.includes("capital")
            ? CAPITAL_BASE_URL
            : "/"
        }?appLanguage=${localStorage.getItem(
          "AppLanguage"
        )}&access_token=${localStorage.getItem(
          "access_token"
        )}&refresh_token=${localStorage.getItem("refresh_token")}`
      );
    }
  }, []);

  const handleSupportClick = useCallback(
    (event) => {
      event.preventDefault();
      // setShowInfoDialog(true);
      navigate(ROUTES.SUPPORT);
      // window.open(`mailto:${SUPPORT_EMAIL}`);
    },
    [navigate]
  );

  return (
    <Container>
      <Divider />
      <FooterContent>
        <LogoWrapper>
          <Logo src={logoPng} alt="Cryptoneed" />
        </LogoWrapper>
        {/* <Laptop> */}
        <SectionContainer>
          <SectionTitle>{t("Product")}</SectionTitle>
          <Link onClick={() => handleMenuClick("wallet")} target="_blank">
            {t("Wallet")}
          </Link>
          <Link onClick={() => handleMenuClick("capital")} target="_blank">
            {t("Capital")}
          </Link>
        </SectionContainer>
        <SectionContainer>
          <SectionTitle>{t("Company")}</SectionTitle>
          <Link onClick={handleSupportClick} target="_blank">
            {t("Support")}
          </Link>
        </SectionContainer>
        <SectionContainer>
          <SectionTitle>{t("Legal")}</SectionTitle>
          <Link
            href={`${WALLET_BASE_URL}?path_name=privacy&appLanguage=${localStorage.getItem(
              "AppLanguage"
            )}&access_token=${localStorage.getItem(
              "access_token"
            )}&refresh_token=${localStorage.getItem("refresh_token")}`}
            target="_blank"
          >
            {t("Privacy Policy")}
          </Link>
          <Link
            href={`${WALLET_BASE_URL}?path_name=terms&appLanguage=${localStorage.getItem(
              "AppLanguage"
            )}&access_token=${localStorage.getItem(
              "access_token"
            )}&refresh_token=${localStorage.getItem("refresh_token")}`}
            target="_blank"
          >
            {t("Terms of Use")}
          </Link>
        </SectionContainer>
        {/* </Laptop> */}
        <EmptySpace />
      </FooterContent>
      <Text typography="400-10-12" color={Color.gray}>
        ©CRYPTONEED 2024
      </Text>
      <InfoDialog
        withNoButton
        title={"Support"}
        open={showInfoDialog}
        handleClose={() => setShowInfoDialog(false)}

        // open={showInfoDialog}
        // title={t("Support")}
        // handleClose={ () => dispatch(closeInfoDialog()) }
      >
        <Grid container direction="column">
          <Typography
            variant={"body1"}
            align={"center"}
            style={{ marginBottom: "16px" }}
          >
            <Trans>{"If you have any questions, please contact"}</Trans>
          </Typography>{" "}
          <LinkContainer>
            <Grid item container>
              <img
                src={MailIcon}
                alt={"email"}
                loading={"lazy"}
                style={{ width: 24, height: 24 }}
              />
              <LinkSupport
                // variant={"body1"}
                href={`mailto:${SUPPORT_EMAIL}`}
                style={{ marginLeft: 20 }}
              >
                {SUPPORT_EMAIL}
              </LinkSupport>
            </Grid>
            <Grid item container>
              <img
                src={TelegramIcon}
                alt={"telegram"}
                loading={"lazy"}
                style={{ width: 24, height: 24 }}
              />
              <LinkSupport
                // variant={"body1"}
                href={SUPPORT_TELEGRAM_CHAT}
                style={{ marginLeft: 20 }}
              >
                <Trans>{"open telegram chat"}</Trans>
              </LinkSupport>
            </Grid>
          </LinkContainer>
        </Grid>
      </InfoDialog>
    </Container>
  );
};
