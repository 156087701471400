import actionCreatorFactory from 'typescript-fsa';
import {ModuleNames} from '../../types';

const actionCreator = actionCreatorFactory(ModuleNames.AppSettings);

const setAppCurrency = actionCreator<{currency: string}>('SET_APP_CURRENCY');

export const appSettingsActions = {
  setAppCurrency,
};
