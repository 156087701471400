import {RequestStatus} from '../../../utils/request-statuses';
import {RootReducer} from '../../store';
import {User} from '../../../models/User';
import {ErrorMessage} from './reducer'

const isAuthorized = (state: RootReducer): Boolean => state.user.isAuthorized;
const user = (state: RootReducer): User => state.user.user;
const requestStatus = (state: RootReducer): RequestStatus =>
  state.user.requestStatus;
const authError = (state: RootReducer): ErrorMessage => state.user.error
const registerError = (state: RootReducer): ErrorMessage => state.user.registerError
const resetPasswordFinishError = (state: RootReducer): ErrorMessage => state.user.resetPasswordFinishError
const activateAccountRequestStatus = (state:RootReducer): RequestStatus => state.user.activateAccountRequestStatus
const checkAuthRedirectRequestStatus = (state:RootReducer): RequestStatus => state.user.checkAuthRedirectRequestStatus
const isTwoFa = (state:RootReducer): boolean => state.user.isTwoFa || false

export const userSelectors = {
  isAuthorized,
  user,
  requestStatus,
  authError,
  registerError,
  activateAccountRequestStatus,
  resetPasswordFinishError,
  checkAuthRedirectRequestStatus,
  isTwoFa,
};
