import { EnqueueSnackbarProps } from "./actions";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { notificationsActions } from "./actions";
import { SnackbarKey } from "notistack";

export interface NotificationsReducer {
  notifications: EnqueueSnackbarProps[];
}

export const initialState: NotificationsReducer = {
  notifications: [],
};

export const notificationsReducer = reducerWithInitialState(initialState)
  .case(
    notificationsActions.enqueueSnackbar,
    (
      state: NotificationsReducer,
      notification: EnqueueSnackbarProps
    ): NotificationsReducer => {
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: notification?.options?.key,
            ...notification,
          },
        ],
      };
    }
  )
  .case(
    notificationsActions.closeSnackbar,
    (
      state: NotificationsReducer,
      { key }: { key?: SnackbarKey }
    ): NotificationsReducer => {
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          !key || notification.key === key
            ? { ...notification, dismissed: true }
            : { ...notification }
        ),
      };
    }
  )
  .case(
    notificationsActions.removeSnackbar,
    (
      state: NotificationsReducer,
      { key }: { key?: SnackbarKey }
    ): NotificationsReducer => {
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== key
        ),
      };
    }
  );
