import React from "react";
import styled, { useTheme } from "styled-components";
import { Parallax } from "react-scroll-parallax";
import imagePng from "../../../assets/images/section_mobile_img.png";
import bgLogoPng from "../../../assets/images/section_mobile_bg.png";
import titleBg from "../../../assets/images/section_mobile_title_bg.png";
import { Section } from "../../../components/molecules/Section/Section";
import { Text } from "../../../components/atoms/Text/Text";
import { Button } from "../../../components/atoms/Button/Button";
import { Color } from "../../../constants/styles";
import { devicesMax } from "../../../constants/device";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../../hooks/useIsMobile";
import {
  SocialButton,
  SocialType,
} from "../../../components/atoms/SocialButton/SocialButton";
import { APP_STORE_LINK } from "../../../constants/apps";
import { GOOGLE_PLAY_STORE_LINK } from "../../../constants/plays";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes";

export interface SectionInfoProps {
  className?: string;
  children?: JSX.Element;
}

const SectionStyled = styled(Section)`
  z-index: 101;
  @media ${devicesMax.mobileL} {
    height: auto;
    padding: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 600px;
  padding: 50px 0px;
  margin: 50px auto;
  margin-top: 0px;
  position: relative;
  flex-direction: column;
  padding-top: 0px;
  @media ${devicesMax.laptopL} {
    padding-right: 0px;
    padding-left: 0px;
  }
  @media ${devicesMax.laptopL} {
    margin-top: 0px;
  }
  @media ${devicesMax.laptop} {
  }
  @media ${devicesMax.tablet} {
    min-height: 400px;
  }
  @media ${devicesMax.mobileL} {
    min-height: 380px;
    justify-content: space-around;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex: 5;
  flex-direction: column;
  margin-top: 80px;
  margin-right: -40px;
  gap: 5px;
  position: relative;
  justify-content: center;
  @media ${devicesMax.mobileL} {
    margin-top: 0px;
    gap: 0px;
  }
`;

const RightColumn = styled.div`
  flex: 2 1 3%;
  padding-top: 130px;
  display: flex;
  align-items: center;
  position: relative;
  @media ${devicesMax.laptopS} {
    flex: 2 1 1%;
  }
  @media ${devicesMax.laptop} {
    flex: 2 1 10%;
  }
  @media ${devicesMax.mobileL} {
    padding-top: 80px;
  }
`;

const MainText = styled(Text)`
  align-self: flex-start;
  text-transform: uppercase;
  font-size: 68px;
  line-height: 74px;
  text-align: left;
  font-weight: 700;
  @media ${devicesMax.laptopS} {
    font-size: 60px;
    line-height: 66px;
  }
  @media ${devicesMax.laptop} {
    font-size: 48px;
    line-height: 52px;
  }
  @media ${devicesMax.tablet} {
    font-size: 30px;
    line-height: 36px;
  }
  @media ${devicesMax.mobileL} {
    font-size: 25px;
    line-height: 30px;
  }
  @media ${devicesMax.mobileM} {
    font-size: 20px;
    line-height: 24px;
  }
`;

const DescriptionText = styled(Text)`
  text-align: center;
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  display: inline;
  white-space: pre-line;
  @media ${devicesMax.laptopS} {
    font-size: 20px;
    line-height: 26px;
  }
  @media ${devicesMax.laptop} {
    font-size: 16px;
    line-height: 24px;
  }
  @media ${devicesMax.tablet} {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }
  @media (max-width: 600px) {
    display: none;
  }
  @media ${devicesMax.mobileL} {
    font-size: 14px;
    line-height: 16px;
  }
  @media ${devicesMax.mobileM} {
    font-size: 10px;
    line-height: 12px;
  }
`;

const AppPromoImg = styled.img`
  max-width: 1000px;
  max-height: 770px;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-top: -80px;
  @media ${devicesMax.laptop} {
    // width: 100%;
    // margin-left: 0px;
  }
`;

const LogoBg = styled.img`
  max-width: 2000px;
  max-height: 2640px;
  margin-left: -50%;
  margin-top: 11%;
  object-fit: cover;
  overflow: hidden;
  @media ${devicesMax.desktopS} {
    max-width: 2000px;
    margin-left: -46%;
  }
  @media ${devicesMax.laptop} {
    margin-left: -46%;
  }
`;

const TitleLogoBg = styled.img`
  max-height: 438px;
  max-width: 1000px;
  right: 0px;
  top: 22%;
  position: absolute;
  @media ${devicesMax.laptopXL} {
    max-width: 960px;
    right: 0px;
    top: 19%;
  }
  @media ${devicesMax.laptopL} {
    max-width: 930px;
    right: 0px;
    top: 17%;
  }
  @media ${devicesMax.laptopS} {
    max-width: 830px;
    right: 0px;
    top: 20%;
  }
  @media ${devicesMax.laptop} {
    max-width: 650px;
    top: 27%;
  }
  @media ${devicesMax.tablet} {
    width: 80%;
    top: 30%;
  }
  @media ${devicesMax.mobileL} {
    display: none;
  }
`;

const BackgroundLogo = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Laptop = styled.div`
  display: flex;
  flex: 1;
  @media ${devicesMax.tablet} {
    flex: 1;
  }
  @media ${devicesMax.mobileL} {
    display: none;
  }
`;
const Tablet = styled.div`
  display: none;
  @media ${devicesMax.laptop} {
    display: flex;
  }
`;
const Mobile = styled.div`
  display: none;
  @media ${devicesMax.mobileL} {
    display: flex;
    flex: 1;
  }
`;

const SocialsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin-bottom: 22px;
  @media ${devicesMax.laptop} {
    gap: 15px;
    margin-bottom: 15px;
  }
  @media ${devicesMax.tablet} {
    gap: 10px;
    margin-bottom: 10px;
  }
`;

const StyledButton = styled(Button)`
  height: 50px;
  border-radius: 16px;
  @media ${devicesMax.laptopS} {
    height: 46px;
    border-radius: 14px;
  }
  @media ${devicesMax.laptop} {
    height: 40px;
    border-radius: 14px;
  }
  @media ${devicesMax.tablet} {
    height: 32px;
    border-radius: 8px;
  }
  @media ${devicesMax.mobileL} {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    box-sizing: border-box;
  }
`;

const ActionsBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 52px;
  @media ${devicesMax.laptopS} {
    margin-top: 40px;
  }
  @media ${devicesMax.laptop} {
    margin-top: 30px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  @media ${devicesMax.mobileL} {
    flex: 1;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SectionMobile: React.FC<SectionInfoProps> = ({
  className,
  children,
}: SectionInfoProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const navigate = useNavigate()

  return (
    <SectionStyled overflow="unset visible">
      <Container>
        <TitleLogoBg src={titleBg} />
        <Row>
          <LeftColumn>
            {/* <Laptop> */}
            <BackgroundLogo>
              <LogoBg src={bgLogoPng} />
            </BackgroundLogo>
            {/* </Laptop> */}
            <MainText>{t("BUY")}</MainText>
            <MainText>{t("SEND")}</MainText>
            <MainText color={Color.chateauLightGreen}>{t("DEPOSIT")}</MainText>
            <MainText>{t("EXCHANGE")}</MainText>
            <ActionsBlock>
              <Laptop>
                <Column>
                  <SocialsContainer>
                    <SocialButton
                      label="App Store"
                      description="Available on the"
                      type={SocialType.Apple}
                      onPress={() => {
                        window.open(APP_STORE_LINK, "__blank");
                      }}
                    />
                    <SocialButton
                      label="Google Play"
                      description="Android App on"
                      type={SocialType.Google}
                      onPress={() => {
                        window.open(GOOGLE_PLAY_STORE_LINK, "__blank");
                      }}
                    />
                  </SocialsContainer>
                  <StyledButton
                    label={t("Log In")}
                    backgroundColor={Color.chateauLightGreen}
                    labelColor={Color.white}
                    onPress={() => {
                      navigate(ROUTES.SIGN_IN)
                    }}
                  />
                </Column>
                <Laptop style={{ flex: 1 }}>
                  <Column style={{ flex: 1 }}>
                    <DescriptionText color={Color.gray}>
                      {t("Simple and")}{" "}
                      <DescriptionText color={Color.malibuBlue}>
                        {t("fast")}
                      </DescriptionText>{" "}
                      {t("work with coins")}
                    </DescriptionText>
                  </Column>
                </Laptop>
              </Laptop>
            </ActionsBlock>
          </LeftColumn>
          <RightColumn>
            <Parallax speed={-4}>
              <AppPromoImg src={imagePng} />
            </Parallax>
          </RightColumn>
        </Row>
        <ActionsBlock>
          <Mobile>
            <Column>
              <SocialsContainer>
                <SocialButton
                  label="App Store"
                  description="Available on the"
                  type={SocialType.Apple}
                  onPress={() => {
                    window.open(APP_STORE_LINK, "__blank");
                  }}
                />
                <SocialButton
                  label="Google Play"
                  description="Android App on"
                  type={SocialType.Google}
                  onPress={() => {
                    window.open(GOOGLE_PLAY_STORE_LINK, "__blank");
                  }}
                />
              </SocialsContainer>
              <StyledButton
                label={t("Log In")}
                backgroundColor={Color.chateauLightGreen}
                labelColor={Color.white}
                onPress={() => {
                  navigate(ROUTES.SIGN_IN)
                }}
              />
            </Column>
          </Mobile>
        </ActionsBlock>
      </Container>
    </SectionStyled>
  );
};
