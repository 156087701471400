import { Buffer } from "buffer";
import api from "../../../api";
import { URL, AUTH_TOKEN } from "../../../constants/api";
import { AxiosResponse, AxiosError } from "axios";
import {
  User,
  UserLoginInput,
  UserLogin,
  UserRegisterInput,
  ResetPasswordInput,
  AccountActivateInput,
  ResetPasswordFinishInput,
  EnableTwoFAResponseData,
  CheckInstallTwoFAInput,
} from "../../../models/User";

export type UserResponse = AxiosResponse<User>;
export type UserLoginResponse = AxiosResponse<UserLogin>;
export type EnableTwoFAResponse = AxiosResponse<EnableTwoFAResponseData>;
export type DisableTwoFAResponse = AxiosResponse<string>;
export type CheckInstallTwoFAResponse = AxiosResponse<boolean>;

interface FetchAccount {
  (): Promise<UserResponse>;
}

interface FetchInit {
  (): Promise<void>;
}

interface FetchUserLogin {
  (data: UserLoginInput): Promise<UserLoginResponse>;
}

// interface FetchRefreshToken {
//   (data: RefreshTokenInput): Promise<UserLoginResponse>;
// }

interface FetchUserRegister {
  (data: UserRegisterInput): Promise<void>;
}

interface FetchResetPassword {
  (data: ResetPasswordInput): Promise<void>;
}

interface FetchAccountActivate {
  (data: AccountActivateInput): Promise<void>;
}

interface FetchResetPasswordFinish {
  (data: ResetPasswordFinishInput): Promise<void>;
}

interface FetchEnableTwoFA {
  (): Promise<EnableTwoFAResponse>;
}

interface FetchDisableTwoFA {
  (): Promise<EnableTwoFAResponse>;
}

interface FetchCheckInstallTwoFA {
  (data: CheckInstallTwoFAInput): Promise<CheckInstallTwoFAResponse>;
}

interface FetchLogout {
  (): Promise<void>;
}

export const apiInitApp: FetchInit = async () => {
  return new Promise<void>(async (resolve) => {
    try {
      await api.get("");
      resolve();
    } catch (_e) {
      const e: AxiosError = _e as AxiosError;
      if (e?.response?.data) {
        resolve();
      }
    }
  });
};

export const apiGetAccount: FetchAccount = async () => {
  return await api.get(URL.account);
};

export const apiUserLogin: FetchUserLogin = async (data) => {
  let formData = new FormData();
  formData.append("password", data.password);
  formData.append("username", data.username);
  formData.append("grant_type", "password");
  if (data.secondAuthCode) {
    formData.append("secondAuthCode", data.secondAuthCode);
  }
  const authToken = Buffer.from(String(AUTH_TOKEN), "utf8").toString("base64");
  const headers = { Authorization: "Basic " + authToken };

  return await api.post(URL.login, formData, { headers });
};

// export const apiRefreshToken: FetchRefreshToken = async (data) => {
//   let formData = new FormData();
//   formData.append("refresh_token", data.refresh_token);
//   formData.append("grant_type", "refresh_token");
//   const authToken = Buffer.from(String(AUTH_TOKEN), "utf8").toString("base64");
//   const headers = { Authorization: "Basic " + authToken };

//   return await api.post(URL.login, formData, { headers });
// };

export const apiUserLogout: FetchLogout = async () => {
  return await api.post(URL.logout);
};

export const apiUserRegister: FetchUserRegister = async (data) => {
  return await api.post(URL.register, data);
};

export const apiResetPassword: FetchResetPassword = async (data) => {
  return await api.post(URL.resetPassword, data);
};

export const apiActivateAccount: FetchAccountActivate = async (data) => {
  return await api.get(URL.activate, { params: data });
};

export const apiResetPasswordFinish: FetchResetPasswordFinish = async (
  data
) => {
  return await api.post(URL.resetPasswordFinish, data);
};

export const apiEnableTwoFA: FetchEnableTwoFA = async () => {
  return await api.get(URL.enableTwoFA);
};

export const apiСheckInstallTwoFA: FetchCheckInstallTwoFA = async (data) => {
  return await api.post(URL.checkInstallTwoFA, data);
};

export const apiDisableTwoFA: FetchDisableTwoFA = async () => {
  return await api.get(URL.disableTwoFA);
};
