import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { ROUTES } from "./routes";
import { Route, Routes, useLocation } from "react-router-dom";
import { userActions } from "./store/modules/account/actions";
import { useDispatch, useSelector } from "react-redux";
import { HomeView } from "./views/Home/Home";
import { Header } from "./components/organisms/Header/Header";
import { Footer } from "./components/organisms/Footer/Footer";
import { SignInView } from "./views/SignIn/SignIn";
import { SignUpView } from "./views/SignUp/SignUp";
import { SupportView } from "./views/Support/Support";
import { ResetPasswordView } from "./views/ResetPassword/ResetPassword";
import { AccountActivateView } from "./views/AccountActivate/AccountActivate";
import { ResetPasswordFinishView } from "./views/ResetPasswordActivate/ResetPasswordActivate";
import { userSelectors } from "./store/modules/account/selectors";
import { store } from "./store/store";
import { useQuery } from "./hooks/useQuery";
import { setStorageItem, StorageItem } from "./utils/local-storage";

const AUTH_ROUTES = [
  ROUTES.SIGN_IN,
  ROUTES.SIGN_UP,
  ROUTES.RESET_PASSWORD,
  ROUTES.ACCOUNT_ACTIVATE,
];

const RootComponent: ForwardRefRenderFunction<unknown> = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = useQuery();
  const isAuthorized = useSelector(userSelectors.isAuthorized);
  const user = useSelector(userSelectors.user);

  useEffect(() => {
    if (query.get("logout")) {
      dispatch(userActions.logout.started({}));
      return;
    }
    if (query.get("refresh_token")) {
      setStorageItem(
        StorageItem.RefreshToken,
        query.get("refresh_token") || ""
      );
      setStorageItem(StorageItem.AccessToken, query.get("access_token") || "");
      dispatch(userActions.getUser.started());
    }

    if (!query.get("refresh_token") && isAuthorized) {
      dispatch(userActions.getUser.started());
    }
  }, [dispatch, query]);

  const showHeader = useMemo(
    () =>
      location.pathname === ROUTES.HOME || location.pathname === ROUTES.SUPPORT,
    [location.pathname]
  );

  const showFooter = useMemo(
    () => location.pathname === ROUTES.HOME,
    [location.pathname]
  );

  return (
    <>
      <>
        {showHeader && <Header />}
        <Routes>
          <Route path={ROUTES.HOME} element={<HomeView />}></Route>
          <Route path={ROUTES.SIGN_IN} element={<SignInView />}></Route>
          <Route path={ROUTES.SIGN_UP} element={<SignUpView />}></Route>
          <Route
            path={ROUTES.RESET_PASSWORD}
            element={<ResetPasswordView />}
          ></Route>
          <Route
            path={ROUTES.ACCOUNT_ACTIVATE}
            element={<AccountActivateView />}
          ></Route>
          <Route
            path={ROUTES.RESET_PASSWORD_FINISH}
            element={<ResetPasswordFinishView />}
          ></Route>
          <Route path={ROUTES.SUPPORT} element={<SupportView />}></Route>
        </Routes>
        {showFooter && <Footer />}
      </>
    </>
  );
};

export const Root = forwardRef(RootComponent);
