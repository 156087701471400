import * as yup from "yup";
import {
  ValidationRequiredError,
  ValidationLengthError,
  ValidationPasswordError,
  ValidationRegisterError,
  ValidationMismatchError,
  ValidationError,
} from "../constants/errors";

export const UPPER_REGEX = /[A-Z]/g;
export const LOWER_REGEX = /[a-z]/g;
export const ONLY_NUMBERS = /^\d+$/;
export const NUMERIC_REGEX = /[0-9]/g;
export const NOT_NUMERIC_REGEX = /\D/g;
export const SKU_REGEX = /^(\d{6}|\d{10})$/g;
export const NUMERIC_WITH_COMMA_REGEX = /\d,/;
export const COMMA_REGEX = /,/g;
export const EMAIL_PATTERN =
  /^[_A-Za-z0-9-]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$|^$/;
export const ALPHANUMERIC_PATTERN_WITH_AT_LEAST_ONE_CHARACTER =
  /(?!^[\d_ &.,'-]+$)^[\w &.,'-]+$|^$/;

const ALLOWED_PASSWORD_SPECIAL_CHARACTERS =
  " !\"#$%&'()*+,-./:;<=>?@\\[\\\\\\]^_`{|}~";
export const PASSWORD_SPECIAL_CHARACTERS_PATTERN = new RegExp(
  `[${ALLOWED_PASSWORD_SPECIAL_CHARACTERS}]`,
  "g"
);
export const REGISTER_PASSWORD = new RegExp(
  `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[${ALLOWED_PASSWORD_SPECIAL_CHARACTERS}])[A-Za-z\\d${ALLOWED_PASSWORD_SPECIAL_CHARACTERS}]{8,49}$`,
  "g"
);
export const PATTERN_NO_SPACES = /^\S*$/;

export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 40;
export const EMAIL_LIMIT = 49;
export const USERNAME_CHARACTERS_LIMIT = 40;
export const USERNAME_CHARACTERS_MIN_LIMIT = 3;
export const MAX_TWO_FA_LENGTH = 6;
export const MIN_TWO_FA_LENGTH = 6;

export const userRegisterSchema = yup.object({
  username: yup
    .string()
    .max(USERNAME_CHARACTERS_LIMIT, ValidationLengthError.MaxLength)
    .min(USERNAME_CHARACTERS_MIN_LIMIT, ValidationLengthError.MinCharacters)
    .matches(
      ALPHANUMERIC_PATTERN_WITH_AT_LEAST_ONE_CHARACTER,
      ValidationRegisterError.InvalidUsername
    )
    .matches(PATTERN_NO_SPACES, ValidationError.Spaces)
    .required(ValidationRequiredError.Username),
  email: yup
    .string()
    .max(EMAIL_LIMIT, ValidationLengthError.MaxLength)
    .matches(PATTERN_NO_SPACES, ValidationError.Spaces)
    .matches(EMAIL_PATTERN, ValidationRegisterError.InvalidEmail)
    .required(ValidationRequiredError.Email),
  password: yup
    .string()
    .max(MAX_PASSWORD_LENGTH, ValidationLengthError.MaxLength)
    .min(MIN_PASSWORD_LENGTH, ValidationPasswordError.MinCharacters)
    .matches(
      PASSWORD_SPECIAL_CHARACTERS_PATTERN,
      ValidationPasswordError.NoSpecial
    )
    .matches(UPPER_REGEX, ValidationPasswordError.NoUppercase)
    .matches(LOWER_REGEX, ValidationPasswordError.NoLowecase)
    .matches(NUMERIC_REGEX, ValidationPasswordError.NoNumber)
    .matches(PATTERN_NO_SPACES, ValidationError.Spaces)
    .matches(REGISTER_PASSWORD, ValidationRegisterError.InvalidPassword)
    .required(ValidationRequiredError.Password),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], ValidationMismatchError.PasswordMismatch)
    .required(ValidationRequiredError.Password),
  referral: yup
    .string()
    .max(USERNAME_CHARACTERS_LIMIT, ValidationLengthError.MaxLength)
    .min(USERNAME_CHARACTERS_MIN_LIMIT, ValidationLengthError.MinCharacters)
    .matches(
      ALPHANUMERIC_PATTERN_WITH_AT_LEAST_ONE_CHARACTER,
      ValidationRegisterError.InvalidAccountName
    )
    .matches(PATTERN_NO_SPACES, ValidationError.Spaces),
  isAgree: yup
    .boolean()
    // .oneOf([yup.ref('password')], ValidationMismatchError.PasswordMismatch)
    .required(ValidationRequiredError.Required),
});

export const resetPasswordSchema = yup.object({
  email: yup
    .string()
    .max(EMAIL_LIMIT, ValidationLengthError.MaxLength)
    .matches(EMAIL_PATTERN, ValidationRegisterError.InvalidEmail)
    .required(ValidationRequiredError.Email),
});

export const userLoginSchema = yup.object({
  login: yup.string().required(ValidationRequiredError.Username),
  password: yup.string().required(ValidationRequiredError.Password),
});

export const twoFALoginSchema = yup.object({
  secondAuthCode: yup
    .string()
    .required(ValidationRequiredError.Required)
    .max(MAX_TWO_FA_LENGTH, ValidationLengthError.TwoFALength)
    .min(MIN_TWO_FA_LENGTH, ValidationLengthError.TwoFALength),
});

export const resetPasswordConfirmSchema = yup.object({
  password: yup
    .string()
    .max(MAX_PASSWORD_LENGTH, ValidationLengthError.MaxLength)
    .min(MIN_PASSWORD_LENGTH, ValidationPasswordError.MinCharacters)
    .matches(
      PASSWORD_SPECIAL_CHARACTERS_PATTERN,
      ValidationPasswordError.NoSpecial
    )
    .matches(UPPER_REGEX, ValidationPasswordError.NoUppercase)
    .matches(LOWER_REGEX, ValidationPasswordError.NoLowecase)
    .matches(NUMERIC_REGEX, ValidationPasswordError.NoNumber)
    .matches(PATTERN_NO_SPACES, ValidationError.Spaces)
    .matches(REGISTER_PASSWORD, ValidationRegisterError.InvalidPassword)
    .required(ValidationRequiredError.Password),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], ValidationMismatchError.PasswordMismatch)
    .required(ValidationRequiredError.Password),
});
