enum RoutesNames {
  HOME = "HOME",
  SIGN_IN = "SIGN_IN",
  SIGN_UP = "SIGN_UP",
  RESET_PASSWORD = "RESET_PASSWORD",
  ACCOUNT_ACTIVATE = "ACCOUNT_ACTIVATE",
  RESET_PASSWORD_FINISH = "RESET_PASSWORD_FINISH",
  SUPPORT = "SUPPORT",
}

type RoutesType = {
  [key in keyof typeof RoutesNames]: string;
};

export const ROUTES: RoutesType = {
  HOME: "/",
  SIGN_IN: "/signin",
  SIGN_UP: "/signup",
  RESET_PASSWORD: "/reset-password",
  ACCOUNT_ACTIVATE: "/accounts/activate",
  RESET_PASSWORD_FINISH: "/reset-password/activate/:key",
  SUPPORT: "/support",
};
