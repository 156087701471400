import React, { useEffect, useMemo } from "react";
import styled, { useTheme, StyledProps } from "styled-components";
import { Color } from "../../../constants/styles";
import { sectionBackground } from "../../../Theme/commonStyles";
import { devicesMax } from "../../../constants/device";
import { Parallax } from "react-scroll-parallax";
import bgVid from "../../../assets/bg1.mp4";
import { useWindowResize } from "../../../hooks/useWindowResize";

export interface SectionProps {
  className?: string;
  children?: JSX.Element;
  bgImage?: string;
  bgSize?: string;
  withAnimatedBg?: boolean;
  isMainSection?: boolean;
  isSupportSection?: boolean;
  overflow?: string;
}


interface ContainerProps {
  bgImage?: string;
  bgSize?: string;
  isMainSection?: boolean;
  isSupportSection?: boolean;
  overflow?: string;
  sectionPadding?: number;
}

const Container = styled.section`
  display: flex;
  position: relative;
  max-height: ${({ isSupportSection }: StyledProps<ContainerProps>): string =>
    isSupportSection ? "calc(100vh - 80px)" : "985px"};
  height: ${({
    isMainSection,
    isSupportSection,
  }: StyledProps<ContainerProps>): string =>
    isMainSection || isSupportSection ? "calc(100vh - 80px)" : "100%"};
  min-height: ${({
    isMainSection,
    isSupportSection,
  }: StyledProps<ContainerProps>): string =>
    isMainSection || isSupportSection ? "700px" : "auto"};
  padding: 0px
    ${({
      isMainSection,
      sectionPadding,
    }: StyledProps<ContainerProps>): number =>
      isMainSection ? 75 : sectionPadding || 75}px;
  align-items: center;
  z-index: 0;
  overflow: ${({ overflow }: StyledProps<ContainerProps>): string =>
    overflow || "unset"};
  ${sectionBackground}
  ${({
    bgImage,
    bgSize,
    isMainSection,
    isSupportSection,
  }: StyledProps<ContainerProps>): string =>
    bgImage && (isMainSection || isSupportSection)
      ? `&:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 3%;
      right: 0px;
      bottom: 0px;
      background-image: url(${bgImage});
      z-index: -1;
      background-size: ${bgSize || "contain"};
      background-repeat: no-repeat;
      background-position: ${bgSize === "contain" ? "center" : "unset"};
    }`
      : ""}

  @media ${devicesMax.laptop} {
    max-height: initial;
    min-: auto;
    height: ${({
      isMainSection,
      isSupportSection,
    }: StyledProps<ContainerProps>): string =>
      isMainSection ? "fit-content" : isSupportSection ? "100vh" : "100%"};
  }
  @media ${devicesMax.tablet} {
    padding: 20px 40px;
    height: ${({
      isMainSection,
      isSupportSection,
    }: StyledProps<ContainerProps>): string =>
      isMainSection ? "fit-content" : isSupportSection ? "100vh" : "100%"};
  }
  @media ${devicesMax.mobileL} {
    height: 100vh;
    padding: 0px 20px;
    &:before {
      background-position: 43% top;
      top: 50px;
      left: 0;
      background-size: 195%;
    }
  }
`;

const AnimatedBg = styled.img``;

const BgWrapper = styled.span`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.4);
  &:after {
    content: "";
    position: absolute;
    background: green;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    z-index: 100;
  }
`;

const Video = styled.video`
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 10%,
    rgba(0, 0, 0, 0) 90%
  );
`;

const bgVidUrl =
  "https://videos.pond5.com/printed-circuit-board-futuristic-server-footage-158389800_main_xxl.mp4";
// const bgVidUrl = 'https://videos.pond5.com/running-signal-bulk-cluster-printed-footage-093692793_main_xxl.mp4'
// const bgVidUrl = 'https://videos.pond5.com/global-blockchain-network-4k-footage-072624155_main_xxl.mp4'
export const Section: React.FC<SectionProps> = ({
  className,
  children,
  bgImage,
  bgSize,
  withAnimatedBg,
  isMainSection,
  isSupportSection,
  overflow,
}: SectionProps) => {
  const theme = useTheme();
  const windowSize = useWindowResize();

  const sectionPadding = useMemo(() => {
    const calcPadding = (Number(windowSize.width) - 1440) / 2;
    const DEFAULT_PADDING = 75;
    const SECTION_PADDING =
      window.innerWidth > 1440
        ? calcPadding > DEFAULT_PADDING
          ? calcPadding
          : DEFAULT_PADDING
        : DEFAULT_PADDING;
    return SECTION_PADDING;
  }, [windowSize]);

  return (
    <Container
      overflow={overflow}
      isMainSection={isMainSection}
      isSupportSection={isSupportSection}
      bgSize={bgSize}
      bgImage={!withAnimatedBg ? bgImage : undefined}
      className={className}
      sectionPadding={sectionPadding}
    >
      {withAnimatedBg ? (
        <BgWrapper>
          {/* <Parallax translateX={[-20, 0]} translateY={[0, 0]} speed={-10}> */}
          {/* <AnimatedBg src={bgImage} /> */}
          {/* <Video width={'100%'} height={'100%'} autoPlay muted loop>
          <source src={bgVid} />
        </Video> */}
          {/* </Parallax> */}
        </BgWrapper>
      ) : null}
      {children}
    </Container>
  );
};
