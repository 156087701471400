import React from "react";
import styled, { useTheme } from "styled-components";
import { Parallax } from "react-scroll-parallax";
import arrowDownPng from "../../../assets/images/down.png";
import arrowUpPng from "../../../assets/images/up.png";
import exchangePng from "../../../assets/images/exchange.png";
import lockPng from "../../../assets/images/lock.png";
import imagePng from "../../../assets/images/main_section_img.png";
import bgLogoPng from "../../../assets/images/main_section_bg.png";
import { Section } from "../../../components/molecules/Section/Section";
import { Text } from "../../../components/atoms/Text/Text";
import { Button } from "../../../components/atoms/Button/Button";
import { gradientBackground } from "../../../Theme/commonStyles";
import { Color } from "../../../constants/styles";
import { devicesMax } from "../../../constants/device";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../../hooks/useIsMobile";

export interface SectionInfoProps {
  className?: string;
  children?: JSX.Element;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 600px;
  padding: 50px 0px;
  margin: 50px auto;
  margin-top: 0px;
  position: relative;
  flex-direction: row;
  @media ${devicesMax.laptopXL} {
    margin-top: 0px;
    padding-top: 0px;
  }
  @media ${devicesMax.laptopL} {
    padding-right: 0px;
    padding-left: 0px;
  }
  @media ${devicesMax.laptopL} {
    margin-top: 0px;
  }
  @media ${devicesMax.laptop} {
  }
  @media ${devicesMax.tablet} {
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex: 5;
  flex-direction: column;
  margin-top: 80px;
  margin-right: -10px;
  gap: 5px;
  position: relative;
  justify-content: center;
  @media ${devicesMax.desktopS} {
    margin-right: -20px;
  }
  @media ${devicesMax.laptopXL} {
    margin-right: -40px;
  }
  @media ${devicesMax.tablet} {
    margin-top: 0px;
    margin-right: 0px;
    align-self: center;
  }
`;

const RightColumn = styled.div`
  flex: 2 1 10%;
  padding-top: 130px;
  display: flex;
  align-items: center;
  position: relative;
  @media ${devicesMax.desktopS} {
    flex: 2 1 8%;
  }
  @media ${devicesMax.desktopXS} {
    flex: 2 1 5%;
  }
  @media ${devicesMax.tablet} {
    justify-content: center;
    width: 60%;
    align-self: center;
  }
`;

const MainText = styled(Text)`
  align-self: flex-end;
  text-transform: uppercase;
  font-size: 82px;
  line-height: 92px;
  text-align: right;
  font-weight: 900;
  margin-top: 60px;
  @media ${devicesMax.laptopXL} {
    font-size: 70px;
    line-height: 78px;
  }
  @media ${devicesMax.laptopL} {
    font-size: 64px;
    line-height: 72px;
  }
  @media ${devicesMax.laptopS} {
    font-size: 50px;
    line-height: 54px;
  }
  @media ${devicesMax.laptop} {
    font-size: 40px;
    line-height: 48px;
  }
  @media ${devicesMax.tablet} {
    font-size: 32px;
    line-height: 48px;
    align-self: center;
    text-align: center;
  }
`;

const DescriptionText = styled(Text)`
  font-size: 46px;
  line-height: 51px;
  font-weight: 900;
  text-align: right;
  font-weight: 900;
  @media ${devicesMax.laptopXL} {
    font-size: 42px;
    line-height: 48px;
  }
  @media ${devicesMax.laptopL} {
    font-size: 36px;
    line-height: 46px;
  }
  @media ${devicesMax.laptopS} {
    font-size: 28px;
    line-height: 36px;
  }
  @media ${devicesMax.laptop} {
    font-size: 22px;
    line-height: 30px;
  }
  @media ${devicesMax.tablet} {
    font-size: 20px;
    line-height: 26px;
    text-align: center;
  }
`;

const AppPromoImg = styled.img`
  max-width: 1000px;
  max-height: 770px;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  @media ${devicesMax.laptopL} {
    width: 100%;
    margin-left: -10%;
  }
  @media ${devicesMax.laptop} {
    width: 100%;
    margin-left: 0px;
  }
  @media ${devicesMax.tablet} {
    max-width: 460px;
    margin-left: 0px;
  }
`;

const LogoBg = styled.img`
  max-width: 2608px;
  max-height: 2640px;
  margin-left: -50%;
  margin-top: 11%;
  object-fit: cover;
  opacity: 0.8;
  overflow: visible;
  @media ${devicesMax.desktopS} {
    max-width: 2500px;
    margin-left: -46%;
  }
  @media ${devicesMax.laptop} {
    margin-left: -46%;
  }
  @media ${devicesMax.laptop} {
    margin-left: -46%;
  }
`;

const BackgroundLogo = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Laptop = styled.div`
  display: flex;
  @media ${devicesMax.laptop} {
    display: none;
  }
`;
const Tablet = styled.div`
  display: none;
  @media ${devicesMax.laptop} {
    display: flex;
  }
`;

export const SectionMain: React.FC<SectionInfoProps> = ({
  className,
  children,
}: SectionInfoProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  return (
    <Section overflow="hidden" isMainSection>
      <Container>
        <RightColumn>
          <Parallax speed={-5}>
            <AppPromoImg src={imagePng} />
          </Parallax>
        </RightColumn>
        <LeftColumn>
          {/* <Laptop> */}
          <BackgroundLogo>
            <LogoBg src={bgLogoPng} />
          </BackgroundLogo>
          {/* </Laptop> */}
          <MainText color={Color.chateauLightGreen}>
            {t("UNIVERSAL PLATFORM")}
          </MainText>
          <DescriptionText>
            {t("TO WORK WITH CRYPTOCURRENCIES")}
          </DescriptionText>
        </LeftColumn>
      </Container>
    </Section>
  );
};
