import { AxiosError } from "axios";

export const ErrorFieldsReplacer = ["message", "statusCode", "error"];

export class CustomError extends Error {
  constructor(message: unknown, suffix?: string) {
    const stringifiedMessage = JSON.stringify(message, ErrorFieldsReplacer, 2);
    super(stringifiedMessage);

    if (suffix) {
      this.name = `${this.name}[${suffix}]`;
    }
  }
}

export class ReduxError extends CustomError {
  constructor(message: unknown, action: string) {
    super(message);
    this.name = `ReduxError[${action}]`;
  }
}

export class NetworkError extends CustomError {
  constructor(message: unknown, name: string) {
    super(message);
    this.name = `NetworkError[${name}]`;
  }
}

export type CustomErrors = CustomError | ReduxError | Error | string;

export const getErrorMessage = (error: AxiosError) => {
  if (error?.response?.data?.detail) {
    return error.response.data.detail;
  } else if (error?.response?.data?.title) {
    return error.response.data.title;
  } else if (error?.response?.data?.message) {
    return error.response.data.message;
  } else if (
    error?.response?.data.error_description === "secret and code not equals"
  ) {
    return "Invalid Google 2FA code.";
  } else if (error?.response?.data === "Invalid Credentials") {
    return "Wrong login or password.";
  }
  return "";
};
