import { SnackbarMessage, OptionsObject, SnackbarKey } from "notistack";
import actionCreatorFactory from "typescript-fsa";
import { ModuleNames } from "../../types";

export interface EnqueueSnackbarProps {
  message: SnackbarMessage;
  options?: OptionsObject;
  key?: SnackbarKey;
}

const actionCreator = actionCreatorFactory(ModuleNames.Notifications);

export const enqueueSnackbar = actionCreator<EnqueueSnackbarProps>("ENQUEUE_SNACKBAR");
export const closeSnackbar = actionCreator<{ key?: SnackbarKey }>(
  "CLOSE_SNACKBAR"
);
export const removeSnackbar = actionCreator<{ key?: SnackbarKey }>(
  "REMOVE_SNACKBAR"
);

export const notificationsActions = {
  enqueueSnackbar,
  closeSnackbar,
  removeSnackbar,
};