import React, { useState, ReactText } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { closeSnackbar } from "../../../store/modules/notifications/actions";
import { Color } from "../../../constants/styles";
import { Text } from "../Text/Text";
import { SnackbarKey } from "notistack";

interface CloseNotifierButtonProps {
  snackbarId: SnackbarKey;
}

const Button = styled.div`
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.15);
  color: ${Color.white};
  cursor: pointer;
  padding: 4px 6px;
  text-transform: uppercase;
`;

export const CloseNotifierButton: React.FC<CloseNotifierButtonProps> = ({
  snackbarId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleCloseClick = () => {
    dispatch(closeSnackbar({ key: snackbarId }));
  };

  return (
    <Button onClick={handleCloseClick}>
      <Text typography='400-12-12'>{t("Close")}</Text>
    </Button>
  );
};
