import React, { useCallback, useState, useEffect } from "react";
import styled, { StyledProps } from "styled-components";
import { useFormik } from "formik";
import { useTranslation, Trans } from "react-i18next";
import { Color } from "../../constants/styles";
import bgPng from "../../assets/images/bg_scheme.png";
import topBgPng from "../../assets/images/auth_green_bg.png";
import { Section } from "../../components/molecules/Section/Section";
import { Text } from "../../components/atoms/Text/Text";
import { devicesMax, devicesMin } from "../../constants/device";
import { Input } from "../../components/atoms/Input/Input";
import { BaseContainer } from "../../components/molecules/BaseContainer/BaseContainer";
import { resetPasswordSchema } from "../../utils/validation";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../routes";
import { useSelector, useDispatch } from "react-redux";
import { userSelectors } from "../../store/modules/account/selectors";
import { usePrevious } from "../../hooks/usePrevious";
import { isLoading, isSucceeded } from "../../utils/request-statuses";
import { userActions } from "../../store/modules/account/actions";
import { AuthButton } from "../../components/atoms/AuthButton/AuthButton";

export interface SectionMainProps {
  className?: string;
  children?: JSX.Element;
}

const Container = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
  height: 100vh;
  align-items: center;
  z-index: 1;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: -40%;
    right: -18%;
    background-image: url(${topBgPng});
    z-index: -1;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    max-height: 1349px;
    width: 100%;
    max-width: 1810px;
  }
`;

const Block = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  max-width: 50%;
  min-width: 500px;
  background: transparent;
  background-size: cover;
  width: 100%;
  background-color: ${Color.sharkBlackLighter};
  border-radius: 20px;
  @media ${devicesMin.desktopS} {
    max-width: 660px;
  }
  @media ${devicesMax.desktopS} {
    max-width: 40%;
  }
  @media ${devicesMax.laptop} {
    max-width: 45%;
  }
  @media ${devicesMax.mobileL} {
    background-color: ${Color.transparent};
    min-width: fit-content;
    max-width: initial;
    width: 100%;
    border-radius: 0px;
  }
`;

const Content = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 34px;
  @media ${devicesMax.desktopS} {
    padding: 30px;
  }
  @media ${devicesMax.laptopXL} {
    padding: 30px;
  }
  @media ${devicesMax.laptopS} {
    padding: 30px;
  }
  @media ${devicesMax.tablet} {
    padding: 20px;
  }
  @media ${devicesMax.mobileL} {
    padding: 12px;
  }
`;

const Logo = styled.img`
  width: 100%;
  max-width: 310px;
`;

const DescriptionContainer = styled.div`
  margin-top: 50px;
  text-align: center;
  width: 100%;
  margin-bottom: 40px;
  @media ${devicesMax.desktopS} {
    margin-top: 40px;
    margin-bottom: 30px;
  }
  @media ${devicesMax.laptopS} {
    margin-top: 20px;
  }
`;

const StyledSection = styled(Section)`
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
`;

const Link = styled(NavLink)`
  color: ${Color.chateauGreen};
  text-decoration: none;
  text-transform: none;
  font-size: 13px;
  font-weight: bold;
  pointer-events: ${({
    disabled,
  }: StyledProps<{ disabled?: boolean }>): string =>
    disabled ? "none" : "auto"};
  align-self: center;
  @media ${devicesMax.tablet} {
    margin-top: 24px;
  }
`;

const Title = styled(Text)`
  font-size: 30px;
  line-height: 36px;
  font-weight: 400;
  text-transform: uppercase;
  align-self: flex-start;
  @media ${devicesMax.desktopS} {
    font-size: 24px;
    line-height: 32px;
  }
  @media ${devicesMax.laptopXL} {
    font-size: 24px;
    line-height: 28px;
  }
  @media ${devicesMax.laptopS} {
    font-size: 22px;
    line-height: 28px;
  }
  @media ${devicesMax.mobileL} {
    font-size: 16px;
    line-height: 18px;
  }
`;

const ActionsBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
  align-items: center;
  @media ${devicesMax.desktopS} {
    margin-top: 20px;
  }
  @media ${devicesMax.laptopS} {
    margin-top: 10px;
  }
  @media ${devicesMax.tablet} {
    flex-direction: column-reverse;
    margin-top: 0px;
    align-items: center;
    gap: 20px;
  }
`;

const RESET_PASSWORD_STARTED_SUCCESS =
  "We have sent an email with a confirmation link to your email address. In order to complete the Reset password process, please click the confirmation link.";
const SPAM_TEXT = "The email may end up in the spam folder.";

interface FormValues {
  email: string;
}

const defaultFormValues = {
  email: "",
};

export const ResetPasswordView: React.FC<SectionMainProps> = ({
  className,
  children,
}: SectionMainProps) => {
  const { t } = useTranslation();
  const [resetPassSubmited, setResetPassSubmited] = useState<boolean>(false);
  const resetPassRequestStatus = useSelector(userSelectors.requestStatus);
  const resetPassError = useSelector(userSelectors.registerError);
  const isLoaderNeeded = isLoading(resetPassRequestStatus);
  const prevResetPassRequestStatus = usePrevious(resetPassRequestStatus);
  const dispatch = useDispatch();

  const handleResetPress = useCallback(
    (values) => {
      dispatch(
        userActions.resetPassword.started({
          email: values.email,
          source: window.location.origin,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      isLoading(prevResetPassRequestStatus) &&
      isSucceeded(resetPassRequestStatus)
    ) {
      setResetPassSubmited(true);
    }
  }, [resetPassRequestStatus, prevResetPassRequestStatus]);

  const {
    values,
    touched,
    isValid,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
  } = useFormik<FormValues>({
    initialValues: defaultFormValues,
    onSubmit: handleResetPress,
    validationSchema: resetPasswordSchema,
    validateOnMount: true,
  });

  const renderResetPassSendMessage = useCallback(() => {
    return (
      <>
        <DescriptionContainer>
          <Text>
            <Trans>{RESET_PASSWORD_STARTED_SUCCESS}</Trans>
          </Text>
          <Text>
            <Trans>{SPAM_TEXT}</Trans>
          </Text>
        </DescriptionContainer>
        <Link disabled={isLoaderNeeded} to={ROUTES.SIGN_IN}>
          <Trans>{t("Back to log in")}</Trans>
        </Link>
      </>
    );
  }, [isLoaderNeeded, t]);

  return (
    <BaseContainer withHeader={false}>
      <StyledSection bgImage={bgPng}>
        <Container>
          <Block>
            <Content onSubmit={handleSubmit}>
              <Title>{t("FORGOT PASSWORD")}</Title>
              {!resetPassSubmited ? (
                <>
                  <DescriptionContainer>
                    <Input
                      onBlur={handleBlur("email")}
                      onChange={handleChange("email")}
                      placeholder={t("Enter your email")}
                      value={values.email}
                      error={touched.email && errors.email}
                      label={t("Email")}
                    />
                  </DescriptionContainer>
                  <ActionsBlock>
                    <Link disabled={isLoaderNeeded} to={ROUTES.SIGN_IN}>
                      <Trans>{t("Back to log in")}</Trans>
                    </Link>
                    <AuthButton
                      onPress={handleSubmit}
                      label={t("Reset")}
                      isDisabled={!isValid}
                      isLoading={isLoaderNeeded}
                      isBigSized
                      backgroundColor={Color.chateauLightGreen}
                      labelColor={Color.white}
                    />
                  </ActionsBlock>
                </>
              ) : null}
              {resetPassSubmited ? renderResetPassSendMessage() : null}
            </Content>
          </Block>
        </Container>
      </StyledSection>
    </BaseContainer>
  );
};
