import React from "react";
import styled, { useTheme } from "styled-components";
import applePng from "../../../assets/images/apple_logo.png";
import googlePng from "../../../assets/images/android_logo.png";
import { Color } from "../../../constants/styles";
import { Text } from "../Text/Text";
import { devicesMax } from "../../../constants/device";

export enum SocialType {
  Apple = "Apple",
  Google = "Google",
}

const socialTypes: {
  [key in SocialType]: string;
} = {
  [SocialType.Apple]: applePng,
  [SocialType.Google]: googlePng,
};

export interface SocialButtonProps {
  onPress?: () => void;
  label: string;
  description?: string;
  className?: string;
  type: SocialType;
}

const Container = styled.a`
  display: flex;
  width: 220px;
  height: 64px;
  border-radius: 18px;
  align-items: center;
  padding-left: 8px;
  position: relative;
  background-color: ${Color.sharkBlackLighter};
  cursor: pointer;
  border-radius: 20px;
  @media ${devicesMax.laptopS} {
    width: 188px;
    height: 58px;
    border-radius: 16px;
  }
  @media ${devicesMax.laptop} {
    width: 130px;
    height: 40px;
    border-radius: 12px;
  }
  @media ${devicesMax.tablet} {
    width: 96px;
    height: 32px;
    padding-left: 4px;
    border-radius: 8px;
  }
  @media ${devicesMax.mobileL} {
    width: 100%;
    height: 40px;
    padding-left: 8px;
    border-radius: 12px;
  }
`;

const Icon = styled.img`
  height: 52px;
  margin-right: 20px;
  @media ${devicesMax.laptopS} {
    height: 46px;
    margin-right: 16px;
  }
  @media ${devicesMax.laptop} {
    height: 30px;
    margin-right: 12px;
  }
  @media ${devicesMax.tablet} {
    height: 26px;
    margin-right: 4px;
  }
  @media ${devicesMax.mobileL} {
    height: 30px;
    margin-right: 12px;
  }
`;

const LabelText = styled(Text)`
  font-size: 23px;
  font-weight: 400;
  line-height: 27px;
  @media ${devicesMax.laptopS} {
    font-size: 20px;
  }
  @media ${devicesMax.laptop} {
    font-size: 14px;
    line-height: 17px;
  }
  @media ${devicesMax.tablet} {
    font-size: 10px;
    line-height: 12px;
  }
  @media ${devicesMax.mobileL} {
    font-size: 14px;
    line-height: 17px;
  }
`;

const DescriptionText = styled(Text)`
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  @media ${devicesMax.laptopS} {
    font-size: 10px;
  }
  @media ${devicesMax.laptop} {
    font-size: 8px;
  }
  @media ${devicesMax.tablet} {
    font-size: 6px;
    line-height: 8px;
  }
  @media ${devicesMax.mobileL} {
    font-size: 8px;
  }
`;

const Descripton = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SocialButton: React.FC<SocialButtonProps> = ({
  onPress,
  label,
  description,
  className,
  type,
}: SocialButtonProps) => {
  const theme = useTheme();

  return (
    <Container onClick={onPress} className={className}>
      <Icon src={socialTypes[type]} />
      <Descripton>
        <DescriptionText color={theme.global.text.default}>
          {description || "Availabel on"}
        </DescriptionText>
        <LabelText color={theme.global.text.default}>{label}</LabelText>
      </Descripton>
    </Container>
  );
};
