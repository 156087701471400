import React, { useState, useEffect } from "react";
import Up from "../../../assets/images/up.png";
import "./styles.css";

const ScrollToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", scrollSubscribe);
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener("scroll", scrollSubscribe);
    };
  }, []);

  const scrollSubscribe = () => {
    if (window.scrollY > 400) {
      setShowTopBtn(true);
    } else {
      setShowTopBtn(false);
    }
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="top-to-btm">
      {showTopBtn && (
        <div onClick={goToTop}>
          <img className="icon-position icon-style" alt="up" src={Up} />
        </div>
      )}
    </div>
  );
};
export default ScrollToTop;
