import { SagaIterator } from "redux-saga";
import { all, takeLatest, call, put } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import _ from "lodash";
import { userActions } from "./actions";
import {
  UserLoginInput,
  RefreshTokenInput,
  UserLogoutInput,
  UserRegisterInput,
  ResetPasswordInput,
  AccountActivateInput,
  ResetPasswordFinishInput,
  CheckInstallTwoFAInput,
} from "../../../models/User";
import {
  clearStorage,
  setStorageItem,
  StorageItem,
  getStorageItem,
} from "../../../utils/local-storage";
import {
  UserLoginResponse,
  apiUserRegister,
  apiResetPassword,
  UserResponse,
  apiGetAccount,
  apiUserLogin,
  // apiRefreshToken,
  apiInitApp,
  apiActivateAccount,
  apiResetPasswordFinish,
  apiUserLogout,
  EnableTwoFAResponse,
  apiEnableTwoFA,
  DisableTwoFAResponse,
  apiDisableTwoFA,
  CheckInstallTwoFAResponse,
  apiСheckInstallTwoFA,
} from "./api";
import { getErrorMessage } from "../../../utils/errors";
import { init } from "i18next";
import { enqueueSnackbar } from "../notifications/actions";
import de from "date-fns/locale/de";

function* initApp(): SagaIterator {
  try {
    yield call(apiInitApp);
    yield put(userActions.initApp.done({}));
  } catch (error) {
    yield put(userActions.initApp.failed({ error }));
  }
}

function* fetchAccountSaga(): SagaIterator {
  try {
    const response: UserResponse = yield call(apiGetAccount);
    yield put(userActions.getUser.done({ result: response.data }));
  } catch (error) {
    yield put(userActions.getUser.failed({ error }));
  }
}

function* fetchCheckAuthRedirectAvailableSaga(): SagaIterator {
  try {
    const response: UserResponse = yield call(apiGetAccount);
    yield put(
      userActions.checkIfAuthRedirectAvailable.done({ result: response.data })
    );
  } catch (error) {
    yield put(userActions.checkIfAuthRedirectAvailable.failed({ error }));
  }
}

function* fetchUserLogin({ payload }: Action<UserLoginInput>): SagaIterator {
  try {
    const response: UserLoginResponse = yield call(apiUserLogin, payload);
    const accessToken = response.data.access_token;
    const refreshToken = response.data.refresh_token;
    setStorageItem(StorageItem.AccessToken, accessToken);
    setStorageItem(StorageItem.RefreshToken, refreshToken);
    yield put(
      userActions.login.done({ result: response.data, params: payload })
    );
    yield put(userActions.getUser.started());
    // REDIRECT TO MAIN SCREEN AFTER SUCCESS LOGIN
  } catch (error) {
    if (
      _.get(error, "response.status") === 403 &&
      _.get(error, "response.data.error_description") === "2FA"
    ) {
      // 2fa is turned on -> show TWOFA form
      yield put(userActions.login.failed({ params: payload, error }));
      yield put(userActions.loginTwoFaIsOn());
    } else {
      yield put(userActions.login.failed({ params: payload, error }));
    }
  }
}

function* fetchUserRegister({
  payload,
}: Action<UserRegisterInput>): SagaIterator {
  try {
    const response: UserLoginResponse = yield call(apiUserRegister, payload);

    if (response.status === 201) {
      yield put(userActions.register.done({ params: payload }));
      // REDIRECT TO REGISTATION SUCCESS
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    yield put(userActions.register.failed({ params: payload, error }));
  }
}

function* fetchResetPassword({
  payload,
}: Action<ResetPasswordInput>): SagaIterator {
  try {
    const response: UserLoginResponse = yield call(apiResetPassword, payload);

    if (response.status === 200) {
      yield put(userActions.resetPassword.done({ params: payload }));
      // REDIRECT TO RESET PASSWORD SUCCESS SCREEN
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    yield put(userActions.resetPassword.failed({ params: payload, error }));
  }
}

function* fetchResetPasswordFinish({
  payload,
}: Action<ResetPasswordFinishInput>): SagaIterator {
  try {
    const response = yield call(apiResetPasswordFinish, payload);

    if (response.status === 200 || response.status === 201) {
      yield put(userActions.resetPasswordFinish.done({ params: payload }));
      // REDIRECT TO RESET PASSWORD SUCCESS SCREEN
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    yield put(
      userActions.resetPasswordFinish.failed({ params: payload, error })
    );
  }
}

function* fetchActivateAccount({
  payload,
}: Action<AccountActivateInput>): SagaIterator {
  try {
    const response = yield call(apiActivateAccount, payload);

    if (response.status === 200 || response.status === 201) {
      yield put(userActions.activateAccount.done({ params: payload }));
      yield put(enqueueSnackbar({
        message: "Successfully registered",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "success",
          autoHideDuration: 4000,
        },
      }));
      // REDIRECT TO RESET PASSWORD SUCCESS SCREEN
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    yield put(userActions.activateAccount.failed({ params: payload, error }));
  }
}

function* fetchEnableTwoFASaga(): SagaIterator {
  try {
    const response: EnableTwoFAResponse = yield call(apiEnableTwoFA);
    yield put(userActions.enableTwoFA.done({ result: response.data }));
  } catch (error) {
    yield put(userActions.enableTwoFA.failed({ error }));
  }
}

function* fetchDisableTwoFASaga(): SagaIterator {
  try {
    const response: DisableTwoFAResponse = yield call(apiDisableTwoFA);
    yield put(userActions.disableTwoFA.done({ result: response.data }));
    yield put(userActions.getUser.started());
  } catch (error) {
    yield put(userActions.disableTwoFA.failed({ error }));
  }
}

function* fetchCheckInstallTwoFASaga({
  payload,
}: Action<CheckInstallTwoFAInput>): SagaIterator {
  try {
    const response: CheckInstallTwoFAResponse = yield call(
      apiСheckInstallTwoFA,
      payload
    );
    yield put(
      userActions.checkInstallTwoFA.done({
        params: payload,
        result: response.data,
      })
    );
    if (response?.data) {
      yield put(userActions.getUser.started());
    }
  } catch (error) {
    yield put(userActions.checkInstallTwoFA.failed({ params: payload, error }));
  }
}

function* fetchUserLogout({ payload }: Action<UserLogoutInput>): SagaIterator {
  try {
    // LOGOUT USER / RESET STORE
    const { isForceLogout } = payload;
    if (!isForceLogout) {
      const token = yield call(getStorageItem, StorageItem.AccessToken);
      if (token) {
        const response = yield call(apiUserLogout);
      }
    }
    yield put(userActions.logout.done({ params: payload }));
    yield call(clearStorage);
    document.cookie = "";
  } catch (error) {
    yield put(userActions.logout.failed({ params: payload, error }));
  }
}

export function* userWatcherSaga(): SagaIterator {
  yield all([
    takeLatest(userActions.getUser.started, fetchAccountSaga),
    takeLatest(
      userActions.checkIfAuthRedirectAvailable.started,
      fetchCheckAuthRedirectAvailableSaga
    ),
    takeLatest(userActions.login.started, fetchUserLogin),
    takeLatest(userActions.initApp.started, initApp),
    takeLatest(userActions.logout.started, fetchUserLogout),
    takeLatest(userActions.register.started, fetchUserRegister),
    takeLatest(userActions.resetPassword.started, fetchResetPassword),
    takeLatest(
      userActions.resetPasswordFinish.started,
      fetchResetPasswordFinish
    ),
    takeLatest(userActions.activateAccount.started, fetchActivateAccount),
    takeLatest(
      userActions.checkInstallTwoFA.started,
      fetchCheckInstallTwoFASaga
    ),
  ]);
}
