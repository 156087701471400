import { Dispatch, Middleware } from "redux";
import _ from "lodash";
import { Action } from "typescript-fsa";
import { ReduxError } from "../../utils/errors";
import { handleError } from "../../utils/logging";
import { enqueueSnackbar } from "../modules/notifications/actions";
import { AxiosError } from "axios";
import i18next from "../../i18n.config";

const checkIfErrorNotificationSkipped = (error: AxiosError | unknown) => {
  if (!error) return false;

  if (
    (_.get(error, "response.status") === 403 &&
      _.get(error, "response.data.error_description") === "2FA") ||
    // (_.get(error, "response.status") === 401 &&
    //   _.get(error, "response.data['error_description']").includes(
    //     "Invalid access token"
    //   )) ||
    _.get(error, "response.data['error_description']", [''])?.includes("Invalid access token") ||
    _.get(error, "response.data['error_description']", [''])?.includes("Invalid refresh token") ||
    _.get(error, 'message') === 'Request aborted'
  ) {
    return true;
  }
  // if (
  //   _.get(error, "response.status") === 403 &&
  //   _.get(error, "response.data.error_description") !== "2FA"
  // ) {
  //   store.dispatch(logOut());
  // }

  return false;
};

export const failedAcionsMiddleware: Middleware =
  (store) =>
    (next: Dispatch<Action<unknown>>) =>
      (action: Action<unknown | AxiosError>): void => {
        if (!!action.error) {
          // handle action payload as message
          if (!checkIfErrorNotificationSkipped(_.get(action, "payload.error"))) {
            handleError(new ReduxError(action.payload, action.type));
          }

          if (
            _.has(action, "payload.error") &&
            !checkIfErrorNotificationSkipped(_.get(action, "payload.error"))
          ) {
            const responseData = _.get(action, "payload.error.response.data");
            const responseError = _.get(
              action,
              "payload.error.response.data.error"
            );
            const responseTitle = _.get(
              action,
              "payload.error.response.data.title"
            );
            const responseErrorDescription = _.get(
              action,
              "payload.error.response.data.error_description"
            );
            const responseErrorMessage = _.get(
              action,
              "error.response.data.message"
            )
            const errorMessage = _.get(
              action,
              "error.message"
            )
            store.dispatch(
              enqueueSnackbar({
                message:
                  typeof responseData === "string"
                    ? i18next.t(responseData)
                    : responseError === "invalid_token"
                      ? i18next.t("Access token expired")
                      : responseErrorDescription === "secret and code not equals"
                        ? i18next.t("Invalid Google 2FA code.")
                        //@ts-ignore
                        : i18next.t(responseTitle) || i18next.t(responseErrorMessage) || i18next.t(responseErrorDescription) || i18next.t(errorMessage) || i18next.t("Some error"),
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                  autoHideDuration: 3000,
                },
              })
            );
          }
        }

        next(action);
      };
