export enum Language {
  English = 'English',
  Ukrainian = 'Ukrainian',
  Russian = 'Russian',
}

export enum LanguageTicker {
  en = 'en',
  ua = 'ua',
  ru = 'ru',
}

export const languageLabels = {
  [LanguageTicker.en]: Language.English,
  [LanguageTicker.ua]: Language.Ukrainian,
  [LanguageTicker.ru]: Language.Russian,
};
