import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
// import {  Button} from "../../../atoms/Button";
import DialogWrapper from "../DialogWrapper/DialogWrapper";

export interface InfoWrapperProps {
  open: boolean;
  title: string;
  message?: string;
  handleClose: any;
  handleBack?: any;
  children?: any;
  closeButtonText?: string;
  withNoButton?: boolean;
}

const InfoDialog = ({
  open,
  title,
  message,
  children,
  handleClose,
  closeButtonText = "Close",
  withNoButton = false,
}: InfoWrapperProps) => {
  // const { t } = useTranslation();
  return (
    <DialogWrapper
      open={open}
      handleClose={() => handleClose({ accept: false })}
      title={title}>
      {children ? (
        children
      ) : (
        <Typography
          variant={"body1"}
          align={"left"}
          color={"textSecondary"}
          style={{ marginBottom: 24 }}>
          {message}
        </Typography>
      )}
      {withNoButton ? null : (
        <Grid container justifyContent='center'>
          <Button
            variant='contained'
            color={"primary"}
            style={{ width: "max(25%, 50px)", marginTop: 12 }}
            onClick={() => handleClose({ accept: false })}>
            {closeButtonText}
          </Button>
        </Grid>
      )}
    </DialogWrapper>
  );
};

export default InfoDialog;
