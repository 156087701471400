/*
Use the following site to find the name of the nearest color with readable color postfix
http://chir.ag/projects/name-that-color

The correct values for hex alfa:
https://stackoverflow.com/questions/15852122/hex-transparency-in-colors/17239853#17239853
*/

// https://stackoverflow.com/questions/15852122/hex-transparency-in-colors/17239853#17239853
export enum Color {
  black = "#000000",
  white = "#FFFFFF",
  charade = "#2A2E3A",
  chateauGreen = "#49AC5B",
  chateauLightGreen = "#4AAF57",
  chambray = "#30488F",
  makoGrey = "#444B55",
  paper = "#464B58",
  ebonyClayGrey = "#222730",
  woodsmokeGrey = "#16171F",
  brightGrey = "#3B4352",
  sharkGrey = "#181921",
  brightGreyDark = "#303743",
  transparent = "#00FFFF00",
  Charade = "#2A2E3A",
  red = "#FF2F51",
  fruitSaladGreen = "#4DAC5E",
  codGray = "#181818",
  codGrayOpacity97 = "rgba(24, 24, 24, 0.97)",
  dustyGray = "#9a9a9a",
  sharkBlack = '#1C1E22',
  sharkBlackLighter = "#212328",
  gray = '#878787',
  malibuBlue = "#8FA4FF",
  charadeDark = "#2B2F38",
}

export const DEFAULT_HEADER_HEIGHT = 90;
export const DEFAULT_FOOTER_HEIGHT = 100;
export const DEFAULT_FOOTER_PADDING = 24;
export const DEFAULT_ICON_SIZE = 20;
export const DEFAULT_COIN_ICON_SIZE = 48;
export const SMALL_COIN_ICON_SIZE = 24;
export const LOADER_SIZE = 80;

export enum Fonts {}
