import {Color} from '../../../constants/styles';

export interface FooterTheme {
  backgroundColor: Color;
  textColor: {
    default: Color;
    active: Color;
  };
}

export const FooterTheme: FooterTheme = {
  backgroundColor: Color.charade,
  textColor: {
    default: Color.white,
    active: Color.chateauGreen,
  },
};
