import React, { useState, useCallback } from "react";
import styled, { StyledProps } from "styled-components";
import { useTranslation } from "react-i18next";
import { useQuery } from "../../../hooks/useQuery";
import { Color } from "../../../constants/styles";
import { Text } from "../../atoms/Text/Text";
import { devicesMax } from "../../../constants/device";

export interface AuthTabSwitcherProps {
  onPress?: () => void;
  defaultActiveTab?: number;
  activeColor?: Color;
}

interface ActiveProps {
  isActive?: boolean;
  activeColor?: Color;
}

const Container = styled.span`
  display: flex;
  flex-direction: row;
  height: 56px;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  border-radius: 19px;
  padding: 5px;
  box-sizing: border-box;
  overflow: hidden;
  background-color: ${Color.codGray};
  @media ${devicesMax.desktopS} {
    height: 50px;
    border-radius: 16px;
    padding: 4px;
  }
  @media ${devicesMax.laptopXL} {
    height: 48px;
  }
  @media ${devicesMax.laptopS} {
    height: 46px;
    padding: 4px;
  }
  @media ${devicesMax.mobileL} {
    height: 50px;
  }
`;

const Tab = styled.div<ActiveProps>`
  background-color: ${({ isActive }: StyledProps<ActiveProps>): string =>
    isActive ? Color.charadeDark : Color.transparent};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ isActive }: StyledProps<ActiveProps>): string =>
    isActive ? "0 40px" : "0 20px"};
  border-radius: 18px;
  @media ${devicesMax.desktopS} {
    padding: ${({ isActive }: StyledProps<ActiveProps>): string =>
      isActive ? "0 36px" : "0 18px"};
    border-radius: 14px;
  }
  @media ${devicesMax.laptopXL} {
    padding: ${({ isActive }: StyledProps<ActiveProps>): string =>
      isActive ? "0 36px" : "0 18px"};
    border-radius: 14px;
  }
  @media ${devicesMax.laptopS} {
    padding: ${({ isActive }: StyledProps<ActiveProps>): string =>
      isActive ? "0 30px" : "0 14px"};
    border-radius: 12px;
  }
  @media ${devicesMax.mobileL} {
    padding: ${({ isActive }: StyledProps<ActiveProps>): string =>
      isActive ? "0 20px" : "0 10px"};
    border-radius: 10px;
  }
`;

const Label = styled(Text)<ActiveProps>`
  font-size: ${({ isActive }: StyledProps<ActiveProps>): string =>
    isActive ? "18px" : "14px"};
  line-height: ${({ isActive }: StyledProps<ActiveProps>): string =>
    isActive ? "22px" : "20px"};
  font-weight: ${({ isActive }: StyledProps<ActiveProps>): string =>
    isActive ? "700" : "400"};
  color: ${({ isActive, activeColor }: StyledProps<ActiveProps>): string =>
    isActive ? activeColor || Color.chateauLightGreen : Color.dustyGray};
  text-transform: uppercase;
  @media ${devicesMax.desktopS} {
    font-size: ${({ isActive }: StyledProps<ActiveProps>): string =>
      isActive ? "16px" : "14px"};
    line-height: ${({ isActive }: StyledProps<ActiveProps>): string =>
      isActive ? "20px" : "18px"};
  }
  @media ${devicesMax.laptopXL} {
    font-size: ${({ isActive }: StyledProps<ActiveProps>): string =>
      isActive ? "16px" : "14px"};
    line-height: ${({ isActive }: StyledProps<ActiveProps>): string =>
      isActive ? "20px" : "18px"};
  }
  @media ${devicesMax.laptopS} {
    font-size: ${({ isActive }: StyledProps<ActiveProps>): string =>
      isActive ? "14px" : "12px"};
    line-height: ${({ isActive }: StyledProps<ActiveProps>): string =>
      isActive ? "18px" : "16px"};
  }
  @media ${devicesMax.mobileL} {
    font-size: ${({ isActive }: StyledProps<ActiveProps>): string =>
      isActive ? "14px" : "12px"};
    line-height: ${({ isActive }: StyledProps<ActiveProps>): string =>
      isActive ? "18px" : "16px"};
  }
`;

export const AuthTabSwitcher: React.FC<AuthTabSwitcherProps> = ({
  onPress,
  defaultActiveTab,
  activeColor,
}: AuthTabSwitcherProps) => {
  let query = useQuery();
  const [activeTab, setActiveTab] = useState<number>(defaultActiveTab || 0);
  const { t, i18n } = useTranslation();

  const handleTabChange = useCallback(
    (value) => () => {
      if (value !== activeTab) {
        setActiveTab(value);
        onPress?.();
      }
    },
    [activeTab, onPress]
  );

  return (
    <>
      <Container>
        <Tab isActive={activeTab === 0} onClick={handleTabChange(0)}>
          <Label activeColor={activeColor} isActive={activeTab === 0}>
            {t("Log in")}
          </Label>
        </Tab>
        <Tab isActive={activeTab === 1} onClick={handleTabChange(1)}>
          <Label activeColor={activeColor} isActive={activeTab === 1}>
            {t("Registration")}
          </Label>
        </Tab>
      </Container>
    </>
  );
};
