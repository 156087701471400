import React, { useCallback, useEffect, useState } from "react";
import styled, { useTheme, StyledProps } from "styled-components";
import { useFormik } from "formik";
import { useTranslation, Trans } from "react-i18next";
import { Color } from "../../constants/styles";
import bgPng from "../../assets/images/bg_scheme.png";
import topBgPng from "../../assets/images/auth_blue_bg.png";
import { Section } from "../../components/molecules/Section/Section";
import { Text } from "../../components/atoms/Text/Text";
import { devicesMax, devicesMin } from "../../constants/device";
import { Input } from "../../components/atoms/Input/Input";
import { BaseContainer } from "../../components/molecules/BaseContainer/BaseContainer";
import { userRegisterSchema } from "../../utils/validation";
import { ROUTES } from "../../routes";
import { NavLink, useNavigate } from "react-router-dom";
import { userActions } from "../../store/modules/account/actions";
import { useSelector, useDispatch } from "react-redux";
import { userSelectors } from "../../store/modules/account/selectors";
import { isLoading, isSucceeded } from "../../utils/request-statuses";
import { usePrevious } from "../../hooks/usePrevious";
import { BASE_URL } from "../../constants/api";
import { AuthTabSwitcher } from "../../components/molecules/AuthTabSwitcher/AuthTabSwitcher";
import { AuthButton } from "../../components/atoms/AuthButton/AuthButton";

export interface SectionMainProps {
  className?: string;
  children?: JSX.Element;
}

const Container = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
  height: auto;
  align-items: center;
  z-index: 1;
  position: relative;
  padding: 100px 0px;
  &:before {
    content: "";
    position: absolute;
    top: -40%;
    right: -15%;
    background-image: url(${topBgPng});
    z-index: -1;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    max-height: 1349px;
    width: 100%;
    max-width: 1810px;
  }
  @media ${devicesMax.tablet} {
    padding: 30px 0px;
  }
`;

const Block = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  max-width: 50%;
  min-width: 500px;
  background: transparent;
  background-size: cover;
  width: 100%;
  background-color: ${Color.sharkBlackLighter};
  border-radius: 20px;
  @media ${devicesMin.desktopS} {
    max-width: 660px;
  }
  @media ${devicesMax.desktopS} {
    max-width: 40%;
  }
  @media ${devicesMax.laptop} {
    max-width: 45%;
  }
  @media ${devicesMax.mobileL} {
    background-color: ${Color.transparent};
    min-width: fit-content;
    max-width: initial;
    width: 100%;
    border-radius: 0px;
  }
`;

const Content = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 34px;
  @media ${devicesMax.desktopS} {
    padding: 30px;
  }
  @media ${devicesMax.laptopXL} {
    padding: 30px;
  }
  @media ${devicesMax.laptopS} {
    padding: 30px;
  }
  @media ${devicesMax.tablet} {
    padding: 20px;
  }
  @media ${devicesMax.mobileL} {
    padding: 12px;
  }
`;

const DescriptionContainer = styled.div<{ withBigPadding?: boolean }>`
  margin-top: ${({
  withBigPadding,
}: StyledProps<{ withBigPadding?: boolean }>): string =>
    withBigPadding ? "60px" : "30px"};
  text-align: center;
  margin-bottom: 30px;
  width: 100%;
  @media ${devicesMax.desktopS} {
    margin-top: ${({
      withBigPadding,
    }: StyledProps<{ withBigPadding?: boolean }>): string =>
    withBigPadding ? "40px" : "20px"};
    margin-bottom: 20px;
  }
  @media ${devicesMax.laptopS} {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;


const StyledSection = styled(Section)`
  height: 100vh;
  max-height: 100vh;
  overflow-x: hidden;
`;

const Link = styled(NavLink)`
  color: ${Color.chateauGreen};
  text-decoration: none;
  text-transform: none;
  pointer-events: ${({
  disabled,
}: StyledProps<{ disabled?: boolean }>): string =>
    disabled ? "none" : "auto"};
  @media ${devicesMax.laptopXL} {
    font-size: 16px;
    line-height: 18px;
  }
  @media ${devicesMax.laptopS} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const LinkS = styled.a`
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
  line-height: 14px;
  color: ${Color.gray};
`;

const ErrorText = styled(Text)`
  margin-bottom: 20px;
`;
const CheckboxContainer = styled.div`
  width: 60%;
  display: flex;
  @media ${devicesMax.laptopS} {
    width: 100%;
  }
`;

const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 0px;
  accent-color: ${Color.malibuBlue};
`;

const CheckboxLabel = styled(Text)`
  text-align: justify;
  padding-right: 10px;
  font-size: 12px;
  line-height: 14px;
  color: ${Color.gray};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  width: 100%;
`;

const Title = styled(Text)`
  font-size: 30px;
  line-height: 36px;
  font-weight: 400;
  text-transform: uppercase;
  @media ${devicesMax.desktopS} {
    font-size: 24px;
    line-height: 32px;
  }
  @media ${devicesMax.laptopXL} {
    font-size: 24px;
    line-height: 28px;
  }
  @media ${devicesMax.laptopS} {
    font-size: 22px;
    line-height: 28px;
  }
  @media ${devicesMax.mobileL} {
    font-size: 16px;
    line-height: 18px;
  }
`;

const ActionsBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
  align-items: center;
  @media ${devicesMax.desktopS} {
    margin-top: 20px;
  }
  @media ${devicesMax.laptopS} {
    flex-direction: column;
    margin-top: 0px;
    gap: 20px;
    align-items: center;
  }
`;

interface FormValues {
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  referral: string;
  isAgree: boolean;
}

const defaultFormValues = {
  username: "",
  email: "",
  password: "",
  confirmPassword: "",
  referral: "",
  isAgree: false,
};

const REGISTER_STARTED_SUCCESS =
  "We have sent an email with a confirmation link to your email address. In order to complete the sign-up process, please click the confirmation link.";
const SPAM_TEXT = "The email may end up in the spam folder.";

export const SignUpView: React.FC<SectionMainProps> = ({
  className,
  children,
}: SectionMainProps) => {
  const { t } = useTranslation();
  const [registerSubmited, setRegisterSubmited] = useState<boolean>(false);
  const loginRequestStatus = useSelector(userSelectors.requestStatus);
  const registerError = useSelector(userSelectors.registerError);

  const isLoaderNeeded = isLoading(loginRequestStatus);
  const prevLoginRequestStatus = usePrevious(loginRequestStatus);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignUpPress = useCallback(
    (values: FormValues) => {
      if (!values.isAgree) {
        return;
      }
      dispatch(
        userActions.register.started({
          login: values.username,
          password: values.password,
          email: values.email,
          referral: values.referral,
          source: window.location.origin,
          langKey: "en",
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (isLoading(prevLoginRequestStatus) && isSucceeded(loginRequestStatus)) {
      setRegisterSubmited(true);
    }
  }, [loginRequestStatus, prevLoginRequestStatus]);

  const {
    values,
    touched,
    isValid,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
  } = useFormik<FormValues>({
    initialValues: defaultFormValues,
    onSubmit: handleSignUpPress,
    validationSchema: userRegisterSchema,
    validateOnMount: true,
  });

  const renderRegistrationSendMessage = useCallback(() => {
    return (
      <DescriptionContainer withBigPadding>
        <Text>
          <Trans>{REGISTER_STARTED_SUCCESS}</Trans>
        </Text>
        <Text style={{marginBottom: 20}} >
          <Trans>{SPAM_TEXT}</Trans>
        </Text>
        <Link to={ROUTES.HOME}>
          <Trans>{t("Back to main")}</Trans>
        </Link>
      </DescriptionContainer>
    );
  }, []);

  const handleRegisterClick = useCallback(() => {
    navigate(ROUTES.SIGN_IN);
  }, [navigate]);

  return (
    <BaseContainer withHeader={false}>
      <StyledSection bgImage={bgPng}>
        <Container>
          <Block>
            <Content onSubmit={handleSubmit}>
              <Header>
                <Title>{t("Welcome")}</Title>
                <AuthTabSwitcher
                  activeColor={Color.malibuBlue}
                  defaultActiveTab={1}
                  onPress={handleRegisterClick}
                />
              </Header>
              {!registerSubmited ? (
                <>
                  <DescriptionContainer>
                    <Input
                      onBlur={handleBlur("username")}
                      onChange={handleChange("username")}
                      // placeholder={t("Username")}
                      label={t("Username")}
                      name="username"
                      value={values.username}
                      error={touched.username && errors.username}
                    />
                    <Input
                      onBlur={handleBlur("email")}
                      onChange={handleChange("email")}
                      // placeholder={t("Email")}
                      label={t("Email")}
                      name="email"
                      value={values.email}
                      error={touched.email && errors.email}
                    />
                    <Input
                      onBlur={handleBlur("password")}
                      onChange={handleChange("password")}
                      // placeholder={t("Password")}
                      label={t("Password")}
                      type="password"
                      name="password"
                      value={values.password}
                      error={touched.password && errors.password}
                    />
                    <Input
                      onBlur={handleBlur("confirmPassword")}
                      onChange={handleChange("confirmPassword")}
                      // placeholder={t("Confirm Password")}
                      label={t("Confirm Password")}
                      type="password"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      error={touched.confirmPassword && errors.confirmPassword}
                    />
                    <Input
                      onBlur={handleBlur("referral")}
                      onChange={handleChange("referral")}
                      // placeholder={t("Referral ID")}
                      label={t("Referral ID")}
                      name="referral"
                      value={values.referral}
                      error={touched.referral && errors.referral}
                    />
                  </DescriptionContainer>

                  {registerError ? (
                    <ErrorText typography="600-16-18" color={Color.red}>
                      <Trans>{registerError}</Trans>
                    </ErrorText>
                  ) : null}
                  <ActionsBlock>
                    {/* <Link disabled={isLoaderNeeded} to={ROUTES.SIGN_IN}>
                      <Trans>{"Back to log in"}</Trans>
                    </Link> */}
                    <CheckboxContainer>
                      <Checkbox
                        type="checkbox"
                        name="isAgree"
                        onChange={handleChange("isAgree")}
                      />
                      <CheckboxLabel typography="400-12-12">
                        {t(
                          "By clicking Register, you are indicating that you have read and agree to the Terms of Use and Privacy Policy"
                        )}
                        <LinkS
                          href={`${BASE_URL}privacy/?appLanguage=${localStorage.getItem(
                            "AppLanguage"
                          )}`}
                          target="_blank"
                        >
                          {t("Privacy Policy")}
                        </LinkS>
                        {", "}
                        <LinkS
                          href={`${BASE_URL}terms/?appLanguage=${localStorage.getItem(
                            "AppLanguage"
                          )}`}
                          target="_blank"
                        >
                          {t("Terms of Use")}
                        </LinkS>
                        {"."}
                      </CheckboxLabel>
                    </CheckboxContainer>
                    <AuthButton
                      onPress={handleSubmit}
                      label={t("Register")}
                      isDisabled={!isValid || !values.isAgree}
                      isLoading={isLoaderNeeded}
                      isBigSized
                      backgroundColor={Color.malibuBlue}
                      labelColor={Color.white}
                    />
                  </ActionsBlock>
                </>
              ) : null}

              {registerSubmited ? renderRegistrationSendMessage() : null}
            </Content>
          </Block>
        </Container>
      </StyledSection>
    </BaseContainer>
  );
};
