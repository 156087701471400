export enum RequestStatus {
  NOT_ASKED = 'NOT_ASKED',
  LOADING = 'LOADING',
  UPDATING = 'UPDATING',
  LOAD_MORE = 'LOAD_MORE',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  NOT_FOUND = 'NOT_FOUND',
}

export const isNotAsked = (status?: RequestStatus): boolean =>
  status === RequestStatus.NOT_ASKED;

export const isLoading = (status?: RequestStatus): boolean =>
  status === RequestStatus.LOADING;

export const isUpdating = (status?: RequestStatus): boolean =>
  status === RequestStatus.UPDATING;

export const isLoadMore = (status?: RequestStatus): boolean =>
  status === RequestStatus.LOAD_MORE;

export const isSucceeded = (status?: RequestStatus): boolean =>
  status === RequestStatus.SUCCEEDED;

export const isFailed = (status?: RequestStatus): boolean =>
  status === RequestStatus.FAILED;

export const isNotFound = (status: RequestStatus): boolean =>
  status === RequestStatus.NOT_FOUND;
