// https://dev.to/ramonak/react-native-internationalization-with-i18next-568n
import i18n, { LanguageDetectorAsyncModule } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, ua, ru } from './locales';

import {
  StorageItem,
  getStorageItem,
  setStorageItem,
} from './utils/local-storage';

//empty for now
const resources = {
  en: {
    translation: en,
  },
  ua: {
    translation: ua,
  },
  ru: {
    translation: ru,
  },
};

const languageDetector: LanguageDetectorAsyncModule = {
  type: 'languageDetector',
  async: true,
  init: () => { },
  detect: function(callback: (lang: string) => void) {
    try {
      //get stored language from Async storage
      const selectedLanguage = getStorageItem(StorageItem.AppLanguage)
      if (selectedLanguage) {
        return callback(selectedLanguage);
      } else {
        return callback('en');
      }
    } catch (error) {
      console.log('Error reading language', error);
    }
  },
  cacheUserLanguage: function(language: string) {
    try {
      //save a user's language choice in Async storage
      setStorageItem(StorageItem.AppLanguage, language);
    } catch (error) { }
  },
};

// list of options
// https://www.i18next.com/overview/configuration-options

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    resources,
    //language to use if translations in user language are not available
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      useSuspense: false, //in case you have any suspense related errors
    },
  });

export default i18n;
