import React, { useRef } from "react";
import { BaseContainer } from "../../components/molecules/BaseContainer/BaseContainer";
import { SectionMain } from "./components/SectionMain";
import { SectionContacts } from "./components/SectionContacts";
import { SectionInfo } from "./components/SectionInfo";
import { SectionStore } from "./components/SectionStore";
import { SectionInstall } from "./components/SectionInstall";
import { SectionBenefits } from "./components/SectionBenefits";

import ScrollToTop from "../../components/atoms/ScrollToTop/ScrollToTop";
import { SectionAbout } from "./components/SectionAbout";
import { SectionMobile } from "./components/SectionMobile";
import { SectionInvest } from "./components/SectionInvest";
import { SectionMultiPlatform } from "./components/SectionMultiPlatform";
import { SectionReferral } from "./components/SectionReferral";

export const HomeView: React.FC = () => {
  const storeSection = useRef(null);
  const benefitsSection = useRef(null);
  const scrollDown = (ref: any) => {
    window.scrollTo({
      top: ref.current.offsetTop - 170,
      behavior: "smooth",
    });
  };

  return (
    <BaseContainer>
      <>
        <SectionMain />
        <SectionAbout />
        <SectionMobile />
        <SectionInvest />
        <SectionMultiPlatform />
        <SectionContacts />
        <SectionReferral />
        {/* <SectionInstall
          handleClickStore={() => scrollDown(storeSection)}
          handleClickMore={() => scrollDown(benefitsSection)}
        />
        <SectionInfo />
        <div ref={storeSection}>
          <SectionStore />
        </div>
        <div ref={benefitsSection}>
          <SectionBenefits />
        </div> */}
        <ScrollToTop />
      </>
    </BaseContainer>
  );
};
