import React, { useState, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import { MenuItem, ControlledMenu, useHover } from "@szhsin/react-menu";
import { useTranslation } from "react-i18next";
import { useQuery } from "../../../hooks/useQuery";
import { Color } from "../../../constants/styles";
import { Text } from "../../atoms/Text/Text";
import { devicesMax, devicesMin } from "../../../constants/device";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import "@szhsin/react-menu/dist/theme-dark.css";
import { LanguageTicker, languageLabels } from "../../../constants/languages";
import { setStorageItem, StorageItem } from "../../../utils/local-storage";

export interface DropdownButtonProps {
  onPress?: () => void;
}

const Container = styled.span`
  display: flex;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  margin-right: 2px;
  margin-bottom: -2px;
  background-color: ${Color.codGray};
  opacity: 0.4;
  @media ${devicesMax.laptopL} {
    height: 38px;
    width: 38px;
  }
`;

const StyledText = styled(Text)`
  text-transform: uppercase;
  font-weight: 800;
  @media ${devicesMin.desktopM} {
    font-size: 18px;
  }
  @media ${devicesMax.desktopM} {
    font-size: 16px;
  }
  @media ${devicesMax.laptopL} {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const DropdownButton: React.FC<DropdownButtonProps> = ({
  onPress,
}: DropdownButtonProps) => {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { anchorProps, hoverProps } = useHover(isOpen, setOpen);
  let query = useQuery();
  const [lang, setLang] = useState<LanguageTicker>(LanguageTicker.en);
  const appLanguage = query.get("appLanguage");
  const { t, i18n } = useTranslation();

  const handleLangChange = useCallback(
    value => {
      setLang(value?.value);
      setStorageItem(StorageItem.AppLanguage, value?.value);
      i18n.changeLanguage(value?.value);
    },
    [i18n]
  );

  useEffect(() => {
    if (appLanguage && appLanguage === "null") {
      setStorageItem(StorageItem.AppLanguage, LanguageTicker.en);
      setLang(LanguageTicker.en);
      i18n.changeLanguage(LanguageTicker.en);
      return;
    }
    if (!!appLanguage) {
      setStorageItem(StorageItem.AppLanguage, appLanguage);
      i18n.changeLanguage(appLanguage);
    }
  }, [appLanguage]);

  useEffect(() => {
    if (i18n.language) {
      setLang(i18n.language as LanguageTicker);
    }
  }, []);

  return (
    <>
      <Container ref={ref} {...anchorProps}>
        <StyledText typography='400-20-24'>{lang}</StyledText>
      </Container>
      <ControlledMenu
        {...hoverProps}
        state={isOpen ? "open" : "closed"}
        anchorRef={ref}
        onClose={() => setOpen(false)}
        transition
        theming='dark'
        onItemClick={handleLangChange}>
        <MenuItem
          disabled={lang === LanguageTicker.ua}
          value={LanguageTicker.ua}>
          {languageLabels[LanguageTicker.ua]}
        </MenuItem>
        <MenuItem
          disabled={lang === LanguageTicker.ru}
          value={LanguageTicker.ru}>
          {languageLabels[LanguageTicker.ru]}
        </MenuItem>
        <MenuItem
          disabled={lang === LanguageTicker.en}
          value={LanguageTicker.en}>
          {languageLabels[LanguageTicker.en]}
        </MenuItem>
      </ControlledMenu>
    </>
  );
};
