import React, { useCallback, useEffect, useState } from "react";
import styled, { useTheme, StyledProps } from "styled-components";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { Color } from "../../constants/styles";
import topBgPng from "../../assets/images/auth_green_bg.png";
import { Section } from "../../components/molecules/Section/Section";
import { Text } from "../../components/atoms/Text/Text";
import { devicesMax, devicesMin } from "../../constants/device";
import { Input } from "../../components/atoms/Input/Input";
import { BaseContainer } from "../../components/molecules/BaseContainer/BaseContainer";
import { userLoginSchema } from "../../utils/validation";
import { useSelector, useDispatch } from "react-redux";
import { userSelectors } from "../../store/modules/account/selectors";
import { isLoading, isSucceeded } from "../../utils/request-statuses";
import { userActions } from "../../store/modules/account/actions";
import { useNavigate, NavLink } from "react-router-dom";
import { ROUTES } from "../../routes";
import { useQuery } from "../../hooks/useQuery";
import { usePrevious } from "../../hooks/usePrevious";
import { isWalletAuthRedirectEnabled } from "../../constants/featureSwitch";
import { TwoFactorAuthForm } from "../../components/molecules/TwoFactorAuthForm/TwoFactorAuthForm";
import { removeStorageItem, StorageItem } from "../../utils/local-storage";
import { AuthTabSwitcher } from "../../components/molecules/AuthTabSwitcher/AuthTabSwitcher";
import { AuthButton } from "../../components/atoms/AuthButton/AuthButton";

export interface SectionMainProps {
  className?: string;
  children?: JSX.Element;
}

const Container = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
  height: 100vh;
  align-items: center;
  z-index: 1;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: -40%;
    right: -18%;
    background-image: url(${topBgPng});
    z-index: -1;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    max-height: 1349px;
    width: 100%;
    max-width: 1810px;
  }
`;

const Block = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  max-width: 50%;
  min-width: 500px;
  background: transparent;
  background-size: cover;
  width: 100%;
  background-color: ${Color.sharkBlackLighter};
  border-radius: 20px;
  @media ${devicesMin.desktopS} {
    max-width: 660px;
  }
  @media ${devicesMax.desktopS} {
    max-width: 40%;
  }
  @media ${devicesMax.laptop} {
    max-width: 45%;
  }
  @media ${devicesMax.mobileL} {
    background-color: ${Color.transparent};
    min-width: fit-content;
    max-width: initial;
    width: 100%;
    border-radius: 0px;
  }
`;

const Content = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 34px;
  @media ${devicesMax.desktopS} {
    padding: 30px;
  }
  @media ${devicesMax.laptopXL} {
    padding: 30px;
  }
  @media ${devicesMax.laptopS} {
    padding: 30px;
  }
  @media ${devicesMax.tablet} {
    padding: 20px;
  }
  @media ${devicesMax.mobileL} {
    padding: 12px;
  }
`;

const Logo = styled.img`
  width: 100%;
  max-width: 310px;
`;

const DescriptionContainer = styled.div`
  margin-top: 60px;
  text-align: center;
  width: 100%;
  @media ${devicesMax.desktopS} {
    margin-top: 40px;
  }
  @media ${devicesMax.laptopS} {
    margin-top: 20px;
  }
`;

const StyledSection = styled(Section)`
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
`;

const ErrorText = styled(Text)`
  margin-bottom: 20px;
`;

const BottomContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  @media ${devicesMax.tablet} {
    margin-top: 10px;
    margin-bottom: 30px;
  }
`;

const Link = styled(NavLink)`
  color: ${Color.gray};
  text-decoration: none;
  text-transform: none;
  font-size: 15px;
  line-height: 18px;
  pointer-events: ${({
  disabled,
}: StyledProps<{ disabled?: boolean }>): string =>
    disabled ? "none" : "auto"};
  @media ${devicesMax.laptopXL} {
    font-size: 14px;
    line-height: 16px;
  }
`;

const StyledLink = styled(NavLink)`
  color: ${Color.chateauGreen};
  text-decoration: none;
  text-transform: none;
  font-size: 14px;
  font-weight: bold;
  align-self: center;
  @media ${devicesMax.tablet} {
    margin-top: 24px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  width: 100%;
`;

const Title = styled(Text)`
  font-size: 30px;
  line-height: 36px;
  font-weight: 400;
  text-transform: uppercase;
  @media ${devicesMax.desktopS} {
    font-size: 24px;
    line-height: 32px;
  }
  @media ${devicesMax.laptopXL} {
    font-size: 24px;
    line-height: 28px;
  }
  @media ${devicesMax.laptopS} {
    font-size: 22px;
    line-height: 28px;
  }
  @media ${devicesMax.mobileL} {
    font-size: 16px;
    line-height: 18px;
  }
`;

const ActionsBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
  @media ${devicesMax.desktopS} {
    margin-top: 20px;
  }
  @media ${devicesMax.tablet} {
    flex-direction: column-reverse;
    margin-top: 0px;
    align-items: center;
  }
`;

export interface LoginFormValues {
  login: string;
  password: string;
}

const defaultFormValues = {
  login: "",
  password: "",
};

export const SignInView: React.FC<SectionMainProps> = ({
  className,
  children,
}: SectionMainProps) => {
  const theme = useTheme();
  let query = useQuery();
  const { t } = useTranslation();
  const [isLoginSubmited, setIsLoginSubmited] = useState(false);

  const loginRequestStatus = useSelector(userSelectors.requestStatus);
  const authError = useSelector(userSelectors.authError);
  const isAuthorized = useSelector(userSelectors.isAuthorized);
  const isLoaderNeeded = isLoading(loginRequestStatus);
  const isTwoFa = useSelector(userSelectors.isTwoFa);

  const checkAuthRedirectRequestStatus = useSelector(
    userSelectors.checkAuthRedirectRequestStatus
  );
  const prevCheckAuthRedirectRequestStatus = usePrevious(
    checkAuthRedirectRequestStatus
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectUrl = query.get("redirectUrl");

  const handleSignInPress = useCallback(
    (values) => {
      removeStorageItem(StorageItem.AccessToken);
      removeStorageItem(StorageItem.RefreshToken);
      dispatch(
        userActions.login.started({
          username: values.login,
          password: values.password,
          rememberMe: true,
        })
      );
      setIsLoginSubmited(true);
    },
    [dispatch]
  );

  const handleShowLogin = useCallback(() => {
    dispatch(userActions.resetStatuses());
    setIsLoginSubmited(false);
  }, [dispatch]);

  useEffect(() => {
    dispatch(userActions.resetStatuses());
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    const refreshToken = localStorage.getItem("refresh_token");
    if (isAuthorized && !redirectUrl) {
      navigate(ROUTES.HOME);
    } else if (
      isWalletAuthRedirectEnabled &&
      isAuthorized &&
      redirectUrl &&
      accessToken &&
      refreshToken
    ) {
      // check if token is still active for api to redirect to another server with this token
      dispatch(userActions.checkIfAuthRedirectAvailable.started());
      // redirectUrl && accessToken && refreshToken ? window.open(`${redirectUrl}?access_token=${accessToken}&refresh_token=${refreshToken}`, "_self") :
    }
  }, [isAuthorized, navigate, redirectUrl]);

  useEffect(() => {
    if (
      isWalletAuthRedirectEnabled &&
      isLoading(prevCheckAuthRedirectRequestStatus) &&
      isSucceeded(checkAuthRedirectRequestStatus)
    ) {
      const accessToken = localStorage.getItem("access_token");
      const refreshToken = localStorage.getItem("refresh_token");
      window.open(
        `${redirectUrl}?access_token=${accessToken}&refresh_token=${refreshToken}`,
        "_self"
      );
    }
  }, [checkAuthRedirectRequestStatus, prevCheckAuthRedirectRequestStatus]);

  const {
    values,
    touched,
    isValid,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
  } = useFormik<LoginFormValues>({
    initialValues: defaultFormValues,
    onSubmit: handleSignInPress,
    validationSchema: userLoginSchema,
    validateOnMount: true,
  });

  const handleKeyDownPress = useCallback((event) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }, [handleSubmit])

  const handleRegisterClick = useCallback(() => {
    navigate(ROUTES.SIGN_UP)
  }, [navigate])

  return (
    <BaseContainer withHeader={false}>
      <StyledSection>
        <Container>
          <Block>
            {!isTwoFa && (
              <Content onKeyDown={handleKeyDownPress} onSubmit={handleSubmit}>
                <Header>
                  <Title>{t("Hello")}</Title>
                  <AuthTabSwitcher onPress={handleRegisterClick} />
                </Header>
                <DescriptionContainer>
                  <Input
                    onBlur={handleBlur("login")}
                    onChange={handleChange("login")}
                    // placeholder={t("Login or mail")}
                    value={values.login}
                    error={touched.login && errors.login}
                    label={t("Email or login")}
                  />
                  <Input
                    onBlur={handleBlur("password")}
                    onChange={handleChange("password")}
                    // placeholder={t("Password")}
                    type="password"
                    value={values.password}
                    error={touched.password && errors.password}
                    label={t("Password")}
                  />
                </DescriptionContainer>
                {authError ? (
                  <ErrorText typography="600-16-18" color={Color.red}>
                    <Trans>{authError}</Trans>
                  </ErrorText>
                ) : null}
                <BottomContent>
                  <Link disabled={isLoaderNeeded} to={ROUTES.RESET_PASSWORD}>
                    <Trans>{t("Forgot your password?")}</Trans>
                  </Link>
                  {/* <Link disabled={isLoaderNeeded} to={ROUTES.SIGN_UP}>
                    <Trans>{"Register Account"}</Trans>
                  </Link> */}
                </BottomContent>
                <ActionsBlock>
                  <StyledLink to={ROUTES.HOME}>
                    <Trans>{t("Back to main")}</Trans>
                  </StyledLink>
                  <AuthButton
                    onPress={handleSubmit}
                    label={t("Login")}
                    isDisabled={!isValid}
                    isLoading={isLoaderNeeded}
                    isBigSized
                    backgroundColor={Color.chateauLightGreen}
                    labelColor={Color.white}
                  />
                </ActionsBlock>
              </Content>
            )}
            {isLoginSubmited && isTwoFa && (
              <TwoFactorAuthForm
                loginCredentials={values}
                showLogin={handleShowLogin}
              />
            )}
          </Block>
        </Container>
      </StyledSection>
    </BaseContainer>
  );
};
