import axios from "axios";
import { Buffer } from "buffer";
import { BASE_URL, URL, AUTH_TOKEN } from "../constants/api";
import { isWalletAuthRedirectEnabled } from "../constants/featureSwitch";
import { userActions } from "../store/modules/account/actions";
import { store } from "../store/store";
import {
  getStorageItem,
  setStorageItem,
  StorageItem,
} from "../utils/local-storage";

//add your BASE_URL to Constants file
export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: isWalletAuthRedirectEnabled ? true : false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Handle request process
axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = getStorageItem(StorageItem.AccessToken);
    if (!!accessToken && config?.headers) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Handle response process
let isRefreshing = false;
let failedQueue: {
  resolve: (value: unknown) => void;
  reject: (reason?: any) => void;
}[] = [];

const processQueue = (error: null, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (
      error?.response?.status === 401 &&
      error?.response?.data?.error_description?.includes(
        "Invalid refresh token"
      )
    ) {
      store.dispatch(userActions.logout.started({}));
    }
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;
      const refreshToken = getStorageItem(StorageItem.RefreshToken);
      return new Promise(function (resolve, reject) {
        let formData = new FormData();
        formData.append("refresh_token", refreshToken || "");
        formData.append("grant_type", "refresh_token");
        const authToken = Buffer.from(String(AUTH_TOKEN), "utf8").toString(
          "base64"
        );
        const headers = { Authorization: "Basic " + authToken };

        axios
          .post(URL.refreshToken, formData, {
            headers,
          })
          .then(({ data }) => {
            const access_token = data.access_token;
            const refresh_token = data.refresh_token;
            setStorageItem(StorageItem.AccessToken, access_token);
            setStorageItem(StorageItem.RefreshToken, refresh_token);
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${access_token}`;
            originalRequest.headers["Authorization"] = `Bearer ${access_token}`;
            processQueue(null, access_token);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
            store.dispatch(userActions.logout.started({ isForceLogout: true }));
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
