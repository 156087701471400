import React from "react";
import styled from "styled-components";
import { Section } from "../../../components/molecules/Section/Section";
import { Text } from "../../../components/atoms/Text/Text";
import { Color } from "../../../constants/styles";
import { devicesMax } from "../../../constants/device";
import { useTranslation } from "react-i18next";

export interface SectionInfoProps {
  className?: string;
  children?: JSX.Element;
}

const SectionStyled = styled(Section)`
  @media ${devicesMax.mobileL} {
    height: auto;
  }
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  margin: 50px auto;
  margin-top: 0px;
  position: relative;
  flex-direction: column;
  padding-bottom: 60px;
  @media ${devicesMax.laptopXL} {
    margin-top: 0px;
    padding-top: 0px;
  }
  @media ${devicesMax.laptopL} {
    padding-right: 0px;
    padding-left: 0px;
  }
  @media ${devicesMax.laptopL} {
    margin-top: 0px;
  }
  @media ${devicesMax.laptop} {
    flex-direction: column;
    paddint-top: 0px;
  }
  @media ${devicesMax.mobileL} {
    margin-bottom: 0px;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex: 4;
  flex-direction: column;
  margin-top: 20px;
  gap: 5px;
  position: relative;
  @media ${devicesMax.mobileL} {
    margin-top: 0px;
    gap: 0px;
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex: 1;
  @media ${devicesMax.tablet} {
    display: none;
  }
`;

const DescriptionText = styled(Text)`
  text-align: right;
  font-size: 24px;
    line-height: 28px;
  font-weight: 400;
  display: inline;
  @media ${devicesMax.laptopS} {
    font-size: 22px;
    line-height: 26px;
  }
  @media ${devicesMax.laptop} {
    font-size: 18px;
    line-height: 24px;
  }
  @media ${devicesMax.tablet} {
    font-size: 15px;
    line-height: 18px;
    text-align: center;
  }
`;

const ColoredText = styled(DescriptionText)`
  font-weight: 900;
`;

const EmptySpace = styled.span`
  height: 20px;
`;

const Title = styled(Text)`
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 50%;
    height: 0.01em;
    background-color: white;
    opacity: 0.2;
  }
  @media ${devicesMax.laptop} {
    &::after {
      content: "";
      position: absolute;
      bottom: -20px;
      left: 0;
      width: 100%;
      height: 0.01em;
      background-color: white;
      opacity: 0.2;
    }
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SectionAbout: React.FC<SectionInfoProps> = ({
  className,
  children,
}: SectionInfoProps) => {
  const { t } = useTranslation();

  return (
    <SectionStyled overflow="hidden">
      <Container>
        <Title typography={"700-20-24"}>{t("About project")}</Title>
        <Row>
          <RightColumn></RightColumn>
          <LeftColumn>
            <DescriptionText typography="500-17-20" color={Color.gray}>
              {t(
                "There are many apps on the market that allow you to work with crypto."
              )}
            </DescriptionText>
            <DescriptionText typography="500-17-20" color={Color.gray}>
              {t("Most of them have limited functionality.")}
            </DescriptionText>
            <EmptySpace />
            <DescriptionText typography="500-17-20">
              <ColoredText color={Color.chateauLightGreen}>
                Cryptoneed
              </ColoredText>{" "}
              {t(
                "has everything you need to work with cryptocurrencies within one platform."
              )}
              <ColoredText color={Color.malibuBlue}>
                {" "}
                {t("You can perform any action with your crypto almost instantly with just a few clicks using a convenient and intuitive interface.")}
              </ColoredText>{" "}
              {t("Cryptoneed will save you money and time")}
            </DescriptionText>
          </LeftColumn>
        </Row>
      </Container>
    </SectionStyled>
  );
};
